import React, { useContext } from "react";
import styled from "styled-components/macro";
import InformationalToolTip from "../InformationalToolTip";
import { AppSettingsContext } from "../../AppSettingsContext";

const StyledTopicContentTextImageContainer = styled.div`
    background-color: ${({ theme: { colors } }) => colors.White};
    width: auto;
    height: auto;
    padding: 50px 60px 25px 60px;
    display: flex;
    justify-content: center;
    flex-direction: ${({ $imageLocationRight }) => {
        if ($imageLocationRight) {
            return `row`;
        } else {
            return `row-reverse`;
        }
    }};
    align-items: center;
    position: relative;

    @media (max-width: 480px) {
        flex-direction: column;
        padding: 10px 15px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        flex-direction: column;
        padding: 10px 20px;
    }

    @media (min-width: 769px) and (max-width: 975px) {
        align-items: flex-start;
    }

    @media (min-width: 976px) and (max-width: 1200px) {
        align-items: flex-start;
    }
`;

const StyledTopicContentTextContainer = styled.div`
    width: ${({ $isTextOnly }) => {
        if ($isTextOnly) {
            return `100%;`;
        } else {
            return `55%`;
        }
    }};
    height: auto;
    padding: ${({ $imageLocationRight, $isTextOnly }) => {
        if ($imageLocationRight) {
            if ($isTextOnly) {
                return `0;`;
            } else {
                return `0 20px 0 0;`;
            }
        } else {
            if ($isTextOnly) {
                return `0;`;
            } else {
                return `0 0 0 20px;`;
            }
        }
    }};
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    @media (max-width: 480px) {
        width: 100%;
        padding: 10px 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
        padding: 20px 0;
    }
`;

const StyledTopicHeadingText = styled.h3`
    color: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.medium.lineHeight};
    margin: 0 0 20px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.display.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.display.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.display.small.lineHeight};
    }
`;

const StyledSubTopicHeadingText = styled.h4`
    color: ${({ theme: { colors } }) => colors.Grey80};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.subHeading.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.headline.subHeading.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.subHeading.lineHeight};
    margin: 0 0 20px 0;
`;

const StyledTopicText = styled.p`
    color: ${({ theme: { colors } }) => colors.Grey80};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    margin: 8px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    }

    strong
    {
        font-weight: 700;
    }
`;

const StyledTopicContentImageContainer = styled.div`
    width: 45%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-wrap: ${({ $isGallery }) => {
        if ($isGallery) {
            return `wrap`;
        } else {
            return 'no-wrap';
        }
    }};
    justify-content: center;
    position: relative;
    align-items: flex-end;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
`;

const StyledImageContainer = styled.div`
    width: auto;
    height: auto;
    display: block;
    margin: 5px;
    position: relative;
    max-height: 750px;
`;

const StyledGalleryImage = styled.img`
    border-radius: 10px;
    overflow: hidden;
    width: ${({ $isGallery }) => {
        if ($isGallery) {
            return `274px`;
        } else {
            return 'auto';
        }
    }};
    height: ${({ $isGallery }) => {
        if ($isGallery) {
            return `274px`;
        } else {
            return 'auto';
        }
    }};
    margin: 0;

    @media (max-width: 768px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `180px`;
            } else {
                return '100%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `180px`;
            } else {
                return 'auto';
            }
        }};
    }

    @media (min-width: 769px) and (max-width: 975px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `170px`;
            } else {
                return '90%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `170px`;
            } else {
                return 'auto';
            }
        }};
    }

    @media (min-width: 976px) and (max-width: 1268px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `180px`;
            } else {
                return '90%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `180px`;
            } else {
                return 'auto';
            }
        }};
    }

    @media (min-width: 1269px) and (max-width: 1524px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `210px`;
            } else {
                return '90%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `210px`;
            } else {
                return 'auto';
            }
        }};
    }

    
`;

const StyledImage = styled.img`
    border-radius: 10px;
    overflow: hidden;
    width: ${({ $isGallery }) => {
        if ($isGallery) {
            return `200px`;
        } else {
            return 'auto';
        }
    }};
    height: ${({ $isGallery }) => {
        if ($isGallery) {
            return `200px`;
        } else {
            return 'auto';
        }
    }};
    margin: 0;

    @media (max-width: 768px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `120px`;
            } else {
                return '100%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `120px`;
            } else {
                return 'auto';
            }
        }};
    }

    @media (min-width: 769px) and (max-width: 975px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `120px`;
            } else {
                return '90%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `120px`;
            } else {
                return 'auto';
            }
        }};
    }

    @media (min-width: 976px) and (max-width: 1268px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `150px`;
            } else {
                return '90%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `150px`;
            } else {
                return 'auto';
            }
        }};
    }

    @media (min-width: 1269px) {
        width: ${({ $isGallery }) => {
            if ($isGallery) {
                return `220px`;
            } else {
                return '90%';
            }
        }};
        height: ${({ $isGallery }) => {
            if ($isGallery) {
                return `220px`;
            } else {
                return 'auto';
            }
        }};
    }
`;

const StyledVideoContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-height: 700px;
    height: auto;
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    display: flex;
    justify-content: center;

    video {
        width: 100%;
        max-height: 700px;
    }
`;

const StyledVideoPoster = styled.div`
    background-image: ${({ assetServer }) => `url("${assetServer}/images/play-icon.png")`};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-size: contain;
    background-position: center center;
    cursor: pointer;
    background-repeat: no-repeat;
`;

const StyledLessonObjectivesContainer = styled.div`
    width: 100%;
    height: auto;
    margin: 15px 0;

    ul {
        padding: 0 0 0 15px;
    }
`;

const StyledLessonObjectiveTitleText = styled.h5`
    color: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.medium.lineHeight};
    margin: 0 0 10px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
    }
`;

/**
 * @param {string} componentName - determines if the component shows the topic heading or sub topic heading - required
 * @param {string} imageLocationRight - determines if the image or video is right or left "right"/"left" - required
 * @param {boolean} isGallery - true/false if the images are in a gallery format - required
 * @param {string} imageUrl - string of image url or location if needed - optional
 * @param {string} imageAlt - string of image alt text if needed - optional
 * @param {string} heading - string of the topic heading if needed - optional
 * @param {string} textContent - string of the topic content if needed - optional
 * @param {string} videoUrl - string of video url or location - optional
 * @param {string} videoWidth - string of the video width - required if video
 * @param {string} videoHeight - string of the video height - required if video
 * @param {Array} galleryImages - array of gallery objects - contains (imageUrl, imageAlt) - required for gallery
 * @param {boolean} isTextOnly - is to show the component with text only - optional
 * @param {Array} lessonObjectives - array of lesson objecives
 */
function TopicContent(props) {
    const {
        componentName,
        imageLocationRight,
        isGallery,
        imageUrl,
        imageAlt,
        imageCredit,
        imageCreditStyle,
        heading,
        textContent,
        videoUrl,
        videoWidth,
        videoHeight,
        galleryImages,
        isTextOnly,
        lessonObjectives
    } = props;
    const $imageLocationRight = imageLocationRight;
    const $isGallery = isGallery;
    const $isTextOnly = isTextOnly;

    var textContentArray = textContent.split('<br/>');

    const { assetServer } = useContext(AppSettingsContext);

    const onClickVideo = () => {
        document.getElementById("video").play();
        document.getElementById("video-preview").style.display = "none";
    }
    
    return (
        <StyledTopicContentTextImageContainer $imageLocationRight={$imageLocationRight} >
            <StyledTopicContentTextContainer $imageLocationRight={$imageLocationRight} $isTextOnly={$isTextOnly} >
                {componentName === "TopicContent" && 
                    <StyledTopicHeadingText>{heading}</StyledTopicHeadingText>
                }
                {componentName === "subTopicHeading" && 
                    <StyledSubTopicHeadingText>{heading}</StyledSubTopicHeadingText>
                }
                {textContentArray.map(comment => <StyledTopicText key={comment}>{comment}<br/></StyledTopicText>)}

                {lessonObjectives.length > 0 &&
                    <StyledLessonObjectivesContainer>
                        <StyledLessonObjectiveTitleText>Lesson Objectives</StyledLessonObjectiveTitleText>
                        <ul>
                            {lessonObjectives.map((objective, index) => {
                                return <li key={objective} ><StyledTopicText>{objective}</StyledTopicText></li>
                            })}
                        </ul>
                    </StyledLessonObjectivesContainer>
                }
            </StyledTopicContentTextContainer>
            <>
            {!isTextOnly && 
                <StyledTopicContentImageContainer $isGallery={$isGallery} >
                    {isGallery && (
                        galleryImages.map((image, index) => {
                            
                            let imageWidth;
                            if (galleryImages.length <= 4) {
                                imageWidth = 43;
                            } else {
                                imageWidth = 27;
                            }
                            return <StyledImageContainer key={image.imageUrl}>
                                    <StyledGalleryImage  src={`${assetServer}/${image.imageUrl}`} alt={image.imageAlt} title={image.imageAlt} $isGallery={$isGallery} imageWidth={imageWidth}></StyledGalleryImage>
                                    {image.imageCredit !== "" && 
                                        <InformationalToolTip toolTipStyle={image.imageCreditStyle} toolTipTitle={""} toolTipContent={image.imageCredit} toolTipAltText={image.imageAlt} />
                                    }
                                </StyledImageContainer>
                        })
                    )}
                    {!isGallery && 
                        <>
                            {imageUrl && 
                                <>
                                    <StyledImage src={`${assetServer}/${imageUrl}`} alt={imageAlt} title={imageAlt} $isGallery={$isGallery}></StyledImage>
                                    {imageCredit !== "" && 
                                        <InformationalToolTip toolTipStyle={imageCreditStyle} toolTipTitle={""} toolTipContent={imageCredit} toolTipAltText={imageAlt} />
                                    }
                                </>
                            }
                            {videoUrl && 
                                <StyledVideoContainer>
                                    <StyledVideoPoster id="video-preview" assetServer={assetServer} onClick={onClickVideo} ></StyledVideoPoster>
                                    <video id="video" controls="controls" loop="loop" width={videoWidth} height={videoHeight} src={`${assetServer}/${videoUrl}`} type="video/mp4" ></video>
                                </StyledVideoContainer>
                            }
                        </>
                    }
                </StyledTopicContentImageContainer>
            }
            </>
        </StyledTopicContentTextImageContainer>
    );
}

export default TopicContent;