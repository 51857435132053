import React from "react";
import styled from "styled-components/macro";
import {HabitatTreeSVG, HabitatWaterSVG, HabitatPlantSVG, WaterShedSVG, WaterShedLessonSVG, WaterShedDamSVG, WaterShedHandsWorldSVG, LivingInTheEvergladesSVG, PlantsOfTheEvergladesSVG, AnimalsOfTheEvergladesSVG, AlligatorSVG, FoodChainSVG, FoodChainWebSVG, FishSVG, FishSkeletonSVG, BearSVG} from "../../assets/index";  

const StyledProgressMeterContainer = styled.div`
    align-items: center;
    background-color: ${({ completed, theme: { colors } }) => completed ? colors.ExtraDarkBlue : colors.EGLightSeaGreen};
    border: ${({ theme: { colors } }) => `solid ${colors.White} 5px`};
    border-radius: 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.25);
    display: ${({ isMobileVersion }) => isMobileVersion ? 'none' : 'flex' };
    flex-direction: row;
    height: 46px;
    margin: 25px 20px;
    padding: 0;
    position: relative;
    width: auto;

    @media (max-width: 480px) {
        display: ${({ isMobileVersion }) => isMobileVersion ? 'flex' : 'none'};
        height: 24px;
        
    }

    @media (min-width: 481px) and (max-width: 1000px) {
        display: ${({ isMobileVersion }) => isMobileVersion ? 'flex' : 'none'};
        height: 40px;
    }
`;

const StyledIconContainer = styled.span`
    align-items: center;
    background-color: ${({ theme: { colors } }) => colors.LightGrey};
    border: ${({ theme: { colors } }) => `solid ${colors.EGDarkSlatteGrey} 3px`};
    border-radius: 50%;
    display: flex;
    height: 75px;
    justify-content: center;
    min-height: 75px;
    min-width: 75px;
    width: 75px;
    z-index: 100;
    margin-left: -10px;

    svg {
        height: 45px;
        width: 45px;
    }

    @media (max-width: 480px) {
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;

        svg {
            height: 22px;
            width: 22px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 62px;
        width: 62px;
        min-height: 62px;
        min-width: 62px;

        svg {
            height: 45px;
            width: 45px;
        }
    }
`;

const StyledProgressIndicatorSpan = styled.span`
    align-items: center;
    background-color: ${({ completed, theme: { colors } }) => completed ? colors.EGLightSeaGreen : colors.ExtraDarkBlue};
    display: flex;
    height: 100%;
    justify-content: flex-end;
    overflow: hidden;
    padding-right: 20px;
    position: relative;
    width: ${(props) => `${props.topicProgressWidth}%`};

    &:last-child {
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;
    }

    @media (min-width: 481px) and (max-width: 1000px) {
        padding-right: 12px;
    }
`;

const StyledProgressCircle = styled.span`
    background-color: ${({ theme: { colors } }) => colors.White};
    border: ${({ theme: { colors } }) => `solid ${colors.EGDarkSlatteGrey} 1px`};
    border-radius: 50%;
    display: inline-block;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
    z-index: 100;

    @media (max-width: 480px) {
        height: 18px;
        width: 18px;
        min-height: 18px;
        min-width: 18px;
    }

    @media (min-width: 481px) and (max-width: 1000px) {
        height: 25px;
        width: 25px;
        min-height: 25px;
        min-width: 25px;
    }
`;

const getIconSvg = (svgString) => {
    switch (svgString) {
        case 'HabitatTreeSVG':
            return HabitatTreeSVG
        case 'HabitatWaterSVG':
            return HabitatWaterSVG
        case 'HabitatPlantSVG':
            return HabitatPlantSVG
        case 'WaterShedSVG':
            return WaterShedSVG
        case 'WaterShedLessonSVG':
            return WaterShedLessonSVG
        case 'WaterShedDamSVG':
            return WaterShedDamSVG
        case 'WaterShedHandsWorldSVG':
            return WaterShedHandsWorldSVG
        case 'LivingInTheEvergladesSVG':
            return LivingInTheEvergladesSVG
        case 'PlantsOfTheEvergladesSVG':
            return PlantsOfTheEvergladesSVG
        case 'AnimalsOfTheEvergladesSVG':
            return AnimalsOfTheEvergladesSVG
        case 'AlligatorSVG':
            return AlligatorSVG
        case 'FoodChainSVG':
            return FoodChainSVG
        case 'FoodChainWebSVG':
            return FoodChainWebSVG
        case 'FishSVG':
            return FishSVG
        case 'FishSkeletonSVG':
            return FishSkeletonSVG
        case 'BearSVG':
            return BearSVG
    }
}

function LessonProgressIndicator(props) {
    const { lessonIconSVG, topics, currentTopic, isMobileVersion } = props;
    const topicProgressWidth = 100/topics.length;
    const IconSvg = getIconSvg(lessonIconSVG);
  return (
    <StyledProgressMeterContainer isMobileVersion={isMobileVersion} completed={currentTopic < 1}>
        <StyledIconContainer>
            <IconSvg />
        </StyledIconContainer>
        {topics.map((topic) => {
            return (
                <StyledProgressIndicatorSpan key={topic} completed={currentTopic >= topic} topicProgressWidth={topicProgressWidth} >
                    <StyledProgressCircle />
                </StyledProgressIndicatorSpan>
            )
        })}
    </StyledProgressMeterContainer> 
  );
}

export default LessonProgressIndicator;