import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { DndContext } from '@dnd-kit/core';
import { pickBy } from 'ramda';
import DroppableAnswerSlot from "./DroppableAnswerSlot";
import DraggableAnswerTextBlock from "./DraggableAnswerTextBlock";
import DroppableTextAnswerSlot from "./DroppableTextAnswerSlot";

const LeftColumn = styled.div`
    display: flex;
    flex: 1 1 auto;
    padding-right: 20px;
    float: left;
    width: 50%;
    box-sizing: border-box;
    @media (max-width: 480px) {
        width: 100%;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
    }
`

const RightColumn = styled.div`
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: 200px 200px;
    padding: 20px;
    float: left;
    width: 50%;
    box-sizing: border-box;
    font-family: Poppins;
    place-items: center;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    justify-content: center;
    text-align: center;
    align-items: center;
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    gap: 28px 68px;

    @media (max-width: 480px) {
        width: 100%;
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
        letter: ${({ theme: { typography } }) => typography.body.medium.letter};
        padding-left: 0;
        padding: 20px;
        grid-template-columns: 125px 125px;
        grid-auto-rows: 42px;
        gap: 10px 5%;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
        letter: ${({ theme: { typography } }) => typography.body.medium.letter};
        padding-left: 0;
        padding: 20px;
        grid-template-columns: 125px 125px;
        grid-auto-rows: 42px;
        gap: 20px 5%;
    }

    @media (min-width: 769px) and (max-width: 1230px) {
        display: flex;
        flex-direction: column;
    }

`

const PlaceholderNode = styled.div`
    width: 173px;
    height: 50px;
    border-radius: 8.381px;
    border: 1.397px dashed;
    box-sizing: border-box;
    margin: 20px 10px;
    
    @media (max-width: 480px) {
        width: 125px;
        height: 36px;
        margin: 15px 5px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 125px;
        height: 36px;
    }
`

const StyledUnorderedList = styled.ul`
    padding: 0;
    margin: 0;

    li {
        font-family: Poppins;
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
        letter: ${({ theme: { typography } }) => typography.body.medium.letter};
        color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
        list-style: none;
        margin: 20px 0;

        @media (min-width: 769px) {
            width: 100%;
            font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
            font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
            line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
            letter: ${({ theme: { typography } }) => typography.body.large.letter};
            padding-left: 0;
            padding: 10px 10px;
        }
    }
`;

const QuizzFillInBlankMatchup = forwardRef((
    {
        onChange,
        isError,
        createCodeButtonText,
        enterCodeButtonText,
        isDisabled,
        questionId,
        questionConfig,
        onReadyToSubmitChange,
        onQuestionStateChange,
        ...props 
    },
    ref
) => {
    const [dropParent, setDropParent] = useState({});

      function handleDragEnd(event) {
        if (event.over && event.over.id) {
            const isCorrectAnswer = questionConfig.solution[event.active.id] == event.over.id
            if(!isCorrectAnswer)
            {
                return;
            }
            setDropParent((state) => {
                var stateWithDropParentRemoved = pickBy((k,v) => {
                    return k!==event.active.id
                },state);
                return {...stateWithDropParentRemoved, [`${event.over.id}`]: event.active.id}
            })
        }
      }

    useImperativeHandle(ref, () => ({
        validateQuestion: () => {
            var answerResponse = Object.entries(questionConfig.solution).map(([k,v]) =>{
                const dropParentInQuestion = Object.entries(dropParent).find(([dpk,dpv]) => dpv === k)
                const [dpk, dpv] = dropParentInQuestion;
                return {slotId: dpk, answerId: k, isCorrect: v == dpk};
            })

            if (answerResponse.every(r => r.isCorrect)) {
                onQuestionStateChange("Done");
            } else {
                onQuestionStateChange("Failed");
            }
          }
    }));

      const dropParentValues = useMemo(() => Object.values(dropParent), [dropParent])

      const answerMarkeup = Object.entries(questionConfig.answers).reduce(
        (accumulator, [answerKey, answerValue]) => {
            accumulator[answerKey] = (
                <DraggableAnswerTextBlock key={questionId+answerKey+answerValue.text} isDropped={dropParentValues.includes(answerKey)} id={answerKey}>{answerValue.text}</DraggableAnswerTextBlock>
            )
            return accumulator;
        },
        {}
      )

    const dropSlots = Object.keys(questionConfig.solution).map((slotId) =>
    <DroppableAnswerSlot id={slotId} key={questionId+slotId}>
        {dropParent[slotId] ? answerMarkeup[dropParent[slotId]] : null}
    </DroppableAnswerSlot>
    )

    const submitEnabled = Object.keys(dropParent).length >= dropSlots.length
    useEffect(() => {
        if(onReadyToSubmitChange)
        {
          onReadyToSubmitChange(submitEnabled);
        }
    }, [submitEnabled])

    const multipleLineRegex = /\<br\/>/g
    const questions = questionConfig.question.split(multipleLineRegex);
    const finalMarkup = questions.map(question => {
        const tempalteRegex = /\{\d+\}/g
        const questionParts = question.split(tempalteRegex);
    
        const templateIds = [];
        let itemFound;
        while ((itemFound = tempalteRegex.exec(question)) !== null) {
            templateIds.push(itemFound[0].replace(/{|}/g, ""))
        }
    
        const questionMarkup = questionParts.reduce((accumulator, currentValue, currentIndex) => {
            accumulator.push(currentValue)
            if(templateIds[currentIndex] != null)
            {
                accumulator.push(
                    <DroppableTextAnswerSlot id={templateIds[currentIndex]} key={questionId+templateIds[currentIndex]}>
                        {dropParent[templateIds[currentIndex]] ? answerMarkeup[dropParent[templateIds[currentIndex]]] : null}
                    </DroppableTextAnswerSlot>
                )
            }
            return accumulator
        }, [])
        return questionMarkup
    })

    return (
        <DndContext onDragEnd={handleDragEnd} >
            <LeftColumn>
                <StyledUnorderedList>
                    {finalMarkup.map(question => (
                        <li key={question}>{question}</li>
                    ))}
                </StyledUnorderedList>
            </LeftColumn>
            <RightColumn>
                {Object.entries(answerMarkeup).map(([key, value]) => 
                    !Object.values(dropParent).includes(key) ?  value : <PlaceholderNode key={"placeholder"+key} />
                )}
            </RightColumn>
        </DndContext> 
    );
})

export default QuizzFillInBlankMatchup;