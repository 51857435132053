import { React, Fragment, useState } from 'react';
import { styled as gc } from '@mui/material/styles';
import {default as sc} from 'styled-components/macro';
import { StyledMultipleChoiceToolTipContainer, StyledToolTipTitle, StyledToolTipContent } from './styles';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { LightbulbSVG } from '../../assets';

const StyledLightbulbSVG = sc(LightbulbSVG)`
    animation: pulse 1s ease alternate infinite;

    @keyframes pulse {
        0% {
            transform: scale(.8);
        }
        100% {
            transform: scale(1.6);
        }
    }

`

const StyledLightbulbSVGContainer = sc.div`
    display: flex;
    svg
    {
        width: 24px;
        height: 24px;
    }
`

const LightTooltip = gc(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
        fontSize: 30,
        "&::before": {
            backgroundColor: '#FFFFFF',
          }
      },
      [`&& button`]: {
        padding: 0
      },[`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      color: '#515151',
      boxShadow: theme.shadows[3],
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Poppins',
      maxWidth: 300,
      userSelect: 'none'
    },
  }));

function MultipleChoiceToolTip(props) {
    const {toolTipTitle, toolTipContent, setTouched} = props;
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(s => !s);
        setTouched();
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <StyledMultipleChoiceToolTipContainer onClick={handleTooltipClose}>
                <LightTooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    open={open}
                    title={
                        <Fragment>
                            {toolTipTitle !== "" && 
                                <StyledToolTipTitle>{toolTipTitle}</StyledToolTipTitle>
                            }
                            <StyledToolTipContent>{toolTipContent}</StyledToolTipContent>
                        </Fragment>
                    }
                    arrow
                >
                    <StyledLightbulbSVGContainer onClick={handleTooltipOpen}>
                        <StyledLightbulbSVG />
                    </StyledLightbulbSVGContainer>
                </LightTooltip>
            </StyledMultipleChoiceToolTipContainer>
        </ClickAwayListener>

    );
}

export default MultipleChoiceToolTip;