import { useCallback, useState } from "react";
import styled from "styled-components";
import { VirtualizedPage } from "./VirtualizedPage";
import useStore from "../../shared/Store";
import { icons } from "../../assets/AvatarIcons";
import ButtonWithText from "../ButtonWithText";
import { useRef } from "react";
import { useEffect } from "react";

const CaroselContainer = styled.div`
    max-width: 600px;
    width: 100%;
    margin: 0 50px;
    box-sizing: border-box;
    @media (max-width: 480px) {
        padding: 0 14px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 0 14px;
    }
`

const CaroselSliderDots = styled.div`
 display: flex;
 justify-content: center;
 cursor: pointer;
`
const CaroselSliderDot = styled.div`
 width: 19px;
 height: 19px;
 background: ${({ isActive, theme: { colors } }) => isActive ? colors.EGLightSeaGreen : colors.Grey10};
 border-radius: 100px;
 margin: 10px;
`

const CaroselSliderContainer = styled.div`
 margin: 0 0 10px 0;
`

const AvatarContainer = styled.div`
 display: flex;
 flex-flow: row wrap;
 justify-content: center;
`

const AvatarIconContainer = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 margin: 12px;
 padding: 4px;
 width: 64px;
 height: 64px;
 border-radius: 100px;
 background: ${({ isActive, theme: { colors } }) => isActive ? colors.Grey10 : "none"};

    @media (max-width: 480px) {
        margin: 0px;
        padding: 14px;
        width: 40px;
        height: 40px;
        svg {
            width: 40px;
            margin: 0px;
            padding: 0px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 0px;
        padding: 16px;
        width: 64px;
        height: 64px;
        svg {
            width: 68px;
            margin: 0px;
            padding: 0px;
        }
    }
 ${({ isDisabled }) => isDisabled ? `
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1);
    cursor: auto;
 ` : ""}
`

const SelectAvatarButtonWithText = styled(ButtonWithText)
`

@media (max-width: 480px) {
    margin: 0;
    width: 100%;
    height: 52px;
}

@media (min-width: 481px) and (max-width: 768px) {
    margin: 0;
    height: 52px;
    width: 100%;
}
`

const ButtonContainer = styled.div`
    margin: 40px 40px 0 40px;
`

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

function AvatarSelectionCarousel({avatarSelectionLocked, onAvatarChanged}) {
    const [pageIndex, setPageIndex] = useState(null)
    const [height, setHeight] = useState(null)
    const [selectedAvatar, setSelectedAvatar] = useState(null)
    const containerRef = useRef(null)
    

    const div = useCallback(node => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height)
            containerRef.current = node
        }
    }, [])

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setHeight(containerRef.current.getBoundingClientRect().height)
        }, 300)
    
        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)
        }
    })
    
    const setUserData = useStore().setUserData;

    const iconSize = 60
    
    const iconComponenets = icons.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/18);
      
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }
      
        resultArray[chunkIndex].push(item);
      
        return resultArray;
    }, [])
    .map((IconSvgArray, index) => (
            <AvatarContainer ref={index === 0 ? div : null}>
                {IconSvgArray.map(({svg: IconSvg, label}) => {
                    const handleAvatarClick = (e) => avatarSelectionLocked ? null : setSelectedAvatar(label)

                    return (
                        <AvatarIconContainer isDisabled={avatarSelectionLocked} onClick={handleAvatarClick} isActive={selectedAvatar === label} key={label}>
                            <IconSvg sx={{ fontSize: iconSize }} />
                        </AvatarIconContainer>
                    )
                }
                )}
            </AvatarContainer>
        )
    )

    const handleIndexChange = useCallback((index) => {
        const modulo = index % iconComponenets.length
        const pageIndex = modulo < 0 ? iconComponenets.length + modulo : modulo
        setPageIndex(pageIndex)
    },[setPageIndex, iconComponenets, pageIndex])

    // const onCaroselSliderDotClick = (dotIndex) => () => {
    //     if(!avatarSelectionLocked)
    //     {
    //         setPageIndex(dotIndex)
    //     }
    // }

    const handleOnChangeAvatarClick = () => {
        setUserData({avatarSvg: selectedAvatar})
        onAvatarChanged()
    }

    return (
        <CaroselContainer>
            <CaroselSliderContainer style={{height}}>
                <VirtualizedPage index={pageIndex} setIndex={setPageIndex} isDisabled={true} onIndexChange={handleIndexChange}>
                {({ index }) => {
                    const modulo = index % iconComponenets.length;
                    const imageIndex = modulo < 0 ? iconComponenets.length + modulo : modulo
                    return (
                        <div>
                            {iconComponenets[imageIndex]}
                        </div>
                    );
                }}
                </VirtualizedPage>
            </CaroselSliderContainer>

            {/* <CaroselSliderDots>
                {iconComponenets.map((_, index) => (
                    <CaroselSliderDot onClick={onCaroselSliderDotClick(index)} isActive={!avatarSelectionLocked && pageIndex === index} key={index} />
                ))}
            </CaroselSliderDots> */}
            {!avatarSelectionLocked && 
            <ButtonContainer>
                <SelectAvatarButtonWithText
                    handleOnClick={handleOnChangeAvatarClick}
                    stretchToFitHorizontally
                    buttonState={selectedAvatar == null ? "disabled" : "primary"}
                    buttonText={"Apply Avatar"} />
            </ButtonContainer>
            }
        </CaroselContainer>
    );
}

export default AvatarSelectionCarousel