import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { StyledInteractiveProgessBarContainer, StyledInteractiveProgessBarTextContainer, StyledProgresText, StyledWaterDropletSVG } from "./styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginRight: -10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#787B7B',
  },
}));

function InteractiveProgressBar(props) {
    const {complete, total} = props;
    const percentageComplete = complete * 10;

  return (
    <StyledInteractiveProgessBarContainer>
        <StyledInteractiveProgessBarTextContainer>
            <StyledProgresText>{complete} of {total}</StyledProgresText>
        </StyledInteractiveProgessBarTextContainer>
        <Box sx={{ flexGrow: 1 }}>
        <BorderLinearProgress variant="determinate" value={percentageComplete} />
        </Box>
        <StyledWaterDropletSVG />
    </StyledInteractiveProgessBarContainer>
  );
}

export default InteractiveProgressBar;