const createUserSlice = (set) => ({
  userId: null,
  userLoggedIn: false,
  userInitialized: false,
  userInvalid: false,
  setUserInvalid: (userInvalid) => set({ userInvalid }),
  userHasSeenSplashPage: true,
  setUserHasSeenSplashPage: (userHasSeenSplashPage) => set({ userHasSeenSplashPage }),
  userData: {
    loggedIn: null,
    loading: true,
    avatarSvg: null,
    studentCode: null,
    completedQuizes: [],
    partiallyCompletedQuizes: []
  },
  addOrUpdatePartiallyCompletedQuiz: (quizzObject) => set((state) => {
    let found = false;
    const updatedQuizes = state.userData.partiallyCompletedQuizes.map(pcc => {
      if (pcc.quizId === quizzObject.quizId) {
        found = true;
        return quizzObject;
      }
      return pcc;
    });
  
    if (!found) {
      updatedQuizes.push(quizzObject);
    }
  
    return { 
      userData: {
        ...state.userData, 
        partiallyCompletedQuizes: updatedQuizes
      }
    }
  }),
  addCompletedQuiz: (quizId) => set((state) => {
    return { userData: {
      ...state.userData,
      partiallyCompletedQuizes: state.userData.partiallyCompletedQuizes.filter(pcc => pcc.quizId !== quizId),
      completedQuizes: [...state.userData.completedQuizes.filter(v => v !== quizId), quizId]
    }}
  }),
  setUserLoggedIn: (userLoggedIn) => set({userLoggedIn}),
  setUserInitialized: (userInitialized) => set({userInitialized}),
  setUserId: (userId) => set({ userId }),
  setUserData: (userData) => set((stateData) => {
    return {
      userData: {
        ...stateData.userData,
        ...userData
      }
    }
  }),
  resetUserData: () => set({ userData: null }),
});

export default createUserSlice;