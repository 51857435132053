import React, { useCallback, useEffect, useRef, useState } from "react";
import QuizModal from "../QuizModal";
import {
    QuizCardMatchupTwoGroup,
    QuizzFillInBlankMatchup
} from '../QuizCardMatchup'
import QuizCardMatchup from "../QuizCardMatchup/QuizCardMatchupTwoGroup";
import QuizCardMatchupC from "../QuizCardMatchup/QuizCardMatchupC";
import QuizCardMatchupA from "../QuizCardMatchup/QuizCardMatchupA";
import QuizMultipleChoice from "../QuizMultipleChoice";
import QuizImageMatchup from "../QuizCardMatchup/QuizImageMatchup";
import ModalPopUp from "../ModalPopUp";
import SuccessPage from "../SuccessPage";
import {TrophySVG, WaterBottleSVG} from "../../assets/index";
import styled from "styled-components/macro";
import useStore from "../../shared/Store";
import { getbadgeSVG } from "../../assets/BadgesIcons";

const SubmitButtonTip = styled.div`
  padding: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const SubmitButtonTipHeader = styled.div`
    color: var(--eg-dark-slatte-grey, #312E2B);
    text-align: center;
    /* EG Title Small */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`

const SubmitButtonTipContent = styled.div`
    color: var(--m-3-sys-light-on-surface-variant, #49454F);
    text-align: center;
    /* M3/body/medium */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
`


const NailedItBox = ({header, content}) => {
    return (
        <SubmitButtonTip>
            <SubmitButtonTipHeader>{header}</SubmitButtonTipHeader>
            <SubmitButtonTipContent>{content}</SubmitButtonTipContent>
        </SubmitButtonTip>
    )
}

function getQuizComponenet(questionType)
{
    switch (questionType) 
    {
        case "FillInTheBlank":
            return QuizzFillInBlankMatchup
        case "TwoGroupMatchup":
            return QuizCardMatchupTwoGroup
        case "MultipleChoice":
            return QuizMultipleChoice
        case "ImageMatchup":
            return QuizImageMatchup
        case "QuizCardMatchup":
            return QuizCardMatchup;
        case "GroupMatchup":
            return QuizCardMatchupC;
        case "GroupMatchupA":
            return QuizCardMatchupA;
    }
}

function QuizViewer({ 
    quizConfig,
    currentLessonIcon,
    currentLessonBadgeData,
    topics,
    currentTopic,
    handleContinue,
    handleRetakeQuiz,
    tag,
    currentCourse,
    currentLessonPageData,
    setUserQuizStatus,
    ...props 
}) {
    
    const [currentQuizItemIndex, setCurrentQuizItemIndex] = useState(0)
    const [quizComplete, setQuizComplete] = useState(false)
    const [buttonBubble, setButtonBubble] = useState(null)
    const currentQuizComponentConfig = quizConfig[currentQuizItemIndex]
    const CurrentQuizComponenet = getQuizComponenet(currentQuizComponentConfig.questionType)
    const questionConfig = currentQuizComponentConfig.questionConfig
    const [readyToSubmit, setReadyToSubmit] = useState(false)
    const [nextQuestionEnabled, setNextQuestionEnabled] = useState(false)
    const [retryEnabled, setRetryEnabled] = useState(false)
    const [waterDroplets, setWaterDroplets] = useState(0)
    const [isFirstTry, setIsFirstTry] = useState(true);
    const quizComponentRef = useRef();
    const isMultiSelect = Array.isArray(questionConfig.solution)
    const isLastQuestion = currentQuizItemIndex === quizConfig.length - 1
    const {
        addDroplets,
        addCompletedQuiz
     } = useStore()

    const IconSvg= getbadgeSVG(currentLessonBadgeData.badgeIcon)
    const handleSubmit = () => {
        if(retryEnabled)
        {
            if (quizComponentRef.current && quizComponentRef.current.reset) {
                quizComponentRef.current.reset();
                setRetryEnabled(false);
                return;
            }
        }
        if(nextQuestionEnabled)
        {
            if(isLastQuestion)
            {
                setQuizComplete(true)
                addDroplets(waterDroplets)
                addCompletedQuiz(currentLessonPageData.pageId)
                return
            }
            setCurrentQuizItemIndex(i => i + 1)
            setIsFirstTry(true)
            return
        }
        if (quizComponentRef.current) {
            quizComponentRef.current.validateQuestion();
        }
        
    }

    const addDroplet = () => setWaterDroplets(s => s+1)

    const qustionStateChangeHandler = (questionState, isAlmostThereMultiSelect) => {
        if (questionState === "Failed") {
            setNextQuestionEnabled(false)
            setRetryEnabled(false)
            setReadyToSubmit(false)
            setIsFirstTry(false)
            if (isMultiSelect) {
                if (isAlmostThereMultiSelect) {
                    setButtonBubble("AlmostThereMultiSelect")
                } else {
                    setButtonBubble("RightTrackMultiSelect")
                }
            } else {
                setButtonBubble("AlmostThereMultiChoice")
            }
            
        }
        if (questionState === "Done") {
            if(isFirstTry)
            {
                addDroplet()
                setButtonBubble("NailedIt")
            }
            setNextQuestionEnabled(true)
        }
        if (questionState === "ReadyForRetry") {
            setRetryEnabled(true)
        }
    }

    useEffect(() => {
        setNextQuestionEnabled(false)
        setButtonBubble(null)
    }, [currentQuizItemIndex])

    const handleOnCloseBubbleClick = useCallback(() => {
        setButtonBubble(null)
    })

    const {header, subhead} = questionConfig

    const {userData} = useStore();

    useEffect(() => {
        if (userData.partiallyCompletedQuizes.find((data) => data.quizId === currentLessonPageData.pageId)) {
            userData.partiallyCompletedQuizes.forEach((quiz) => {
                if (currentLessonPageData.pageId === quiz.quizId) {
                    setCurrentQuizItemIndex(quiz.resumeQuestionIndex - 1)
                    setWaterDroplets(quiz.dropletCount || 0)
                }
            });
        }    
    }, [])


    if(quizComplete)
    {
        return (
            <ModalPopUp doIntroAnimation={false} hideCloseButton= {true} tag={"SuccessPage"}>
                <SuccessPage
                    waterDropCount = {waterDroplets}
                    badgeLabel = {`You earned ${waterDroplets} water droplets`}
                    currentLessonIcon = {currentLessonIcon}
                    topics = {topics}
                    currentTopic = {currentTopic}
                    handleContinue={handleContinue}
                    handleRetakeQuiz={handleRetakeQuiz}
                    rewardType = {'Canteen'}
                />
            </ModalPopUp>
        )
    }

    return (
        <QuizModal 
            currentQuestionIndex={currentQuizItemIndex+1}
            totalQuestions={quizConfig.length}
            submitEnabled={readyToSubmit}
            nextQuestionEnabled={nextQuestionEnabled}
            retryEnabled={retryEnabled}
            onSubmit={handleSubmit}
            header={header}
            subhead={subhead}
            credits={currentQuizComponentConfig.credits}
            dropletCount={waterDroplets}
            tag={tag}
            onCloseBubbleClick={handleOnCloseBubbleClick}
            currentCourse={currentCourse}
            currentLessonPageData={currentLessonPageData}
            setUserQuizStatus={setUserQuizStatus}
            submitButtonTip={
                buttonBubble === "NailedIt" ?
                    <NailedItBox header={<><TrophySVG /> NAILED IT</>} content={<>Your answer is spot on. Keep up the good work!</>}/> :
                buttonBubble === "RightTrackMultiSelect" ?
                    <NailedItBox header={<>On the Right Track!</>} content={<>You're close, but not quite there. There are still more correct answers.</>}/> :
                buttonBubble === "AlmostThereMultiChoice" ?
                    <NailedItBox header={<>Almost There!</>} content={<>Good try, but not quite. Give it another shot!</>}/> :
                buttonBubble === "AlmostThereMultiSelect" ?
                    <NailedItBox header={<>Almost There!</>} content={<>Some of your selections are correct, but others are not. Reread the question and try to find only the correct answers.</>}/> :
                null
            }
        >
            <CurrentQuizComponenet
                ref={quizComponentRef}
                key={currentQuizComponentConfig.questionId}
                questionId={currentQuizComponentConfig.questionId}
                onReadyToSubmitChange={setReadyToSubmit}
                onQuestionStateChange={qustionStateChangeHandler}
                questionConfig={questionConfig} />
        </QuizModal>
  );
}

export default QuizViewer