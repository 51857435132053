import { React, useState } from "react";
import styled from "styled-components/macro";
import ButtonWithText from '../ButtonWithText';
import { DataBaseConnectionErrorSVG, NoInternetErrorErrorSVG } from "../../assets";

const StyledErrorPageContainer = styled.div`
    width: 100%;
    padding: 192px 125px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: 480px) {
        padding: 75px 15px 35px 22px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 75px 15px 35px 22px;
    }
`;

const StyledSVGContainer = styled.div`
    width: 170px;
    height: 170px;

    svg {
        height: 100%;
        width: 100%;
    }

    @media (max-width: 480px) {
        width: 130px;
        height: 130px;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 150px;
        height: 150px;

        svg {
            height: 100%;
            width: 100%;
        }
    }
`;

const StyledErrorTitle = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.headline.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.large.lineHeight};
    color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    text-align: center;

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.medium.lineHeight};
    }
`;

const StyledErrorText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
    color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    text-align: center;
    padding-bottom: 40px;

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    }
`;

const StyledInternetAlertText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
    color: ${({ theme: { colors } }) => colors.OhNoRed};
    text-align: center;
    padding-bottom: 15px;

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    }
`;

const StyledButton = styled(ButtonWithText)`
    width: 330px;
    height: 40px;

    @media (max-width: 480px) {
        width: 280px;
        height: 36px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 300px;
        height: 38px;
    }
`;

function ErrorPageComponent(props) {
    const {isOffline} = props;
    const [isInternetConnection, setIsInternetConnection] = useState();
    const [isInternetConnectionChecked, setIsInternetConnectionChecked] = useState(false);

    const handleOnClick = () => {
        var ifConnected = window.navigator.onLine;
        if (ifConnected) {
            setIsInternetConnection(true);
            window.location.reload();
        } else {
            setIsInternetConnection(false);
            setIsInternetConnectionChecked(true);
        }
    }

    return (
        <StyledErrorPageContainer>
            {isOffline ? (
                <>
                    <StyledSVGContainer>
                        <NoInternetErrorErrorSVG />
                    </StyledSVGContainer>
                    <StyledErrorTitle>No Internet Connection</StyledErrorTitle>
                    <StyledErrorText>You don't seem to have an active internet connection. Please check your connection and try again.</StyledErrorText>
                </>
            ) : (
                <>
                    <StyledSVGContainer>
                        <DataBaseConnectionErrorSVG />
                    </StyledSVGContainer>
                    <StyledErrorTitle>Connection Error</StyledErrorTitle>
                    <StyledErrorText>Our servers are having trouble connecting right now. Don't worry, your work is safe. Try refreshing the page, or come back a little later when things are fixed. Thanks for being patient!</StyledErrorText>
                </>
            )}
            {!isInternetConnection && isInternetConnectionChecked &&
                <StyledInternetAlertText>Sorry, your connection is still offline. Please check your connection and try again.</StyledInternetAlertText>
            }
            <StyledButton buttonText="Refresh" handleOnClick={handleOnClick} buttonState="primary"/>
        </StyledErrorPageContainer>
    );
}

export default ErrorPageComponent;