import { React, useState } from "react";
import styled from "styled-components/macro";
import PageContainer from "../../components/PageContainer";
import HeaderNavBar from "../../components/HeaderNavBar";
import ErrorPageComponent from "../../components/ErrorPageComponent";

const ErrorPage = (props) => {
    const {isOffline} = props;

    return (
        <PageContainer>
          <HeaderNavBar />
          <ErrorPageComponent isOffline={isOffline} />
        </PageContainer>
    )
  }

  export default ErrorPage;