import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import styled from "styled-components/macro";
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { pickBy } from "ramda";
import DroppableAnswerSlot from "./DroppableAnswerSlot";
import DraggableAnswerBlock from "./DraggableAnswerBlock";
import { AppSettingsContext } from "../../AppSettingsContext";

const LeftColumn = styled.div`
  display: block;
  padding: 0 20px 0 0;
  width: 50%;
  box-sizing: border-box;

  @media (max-width: 480px) {
      width: 100%;
      padding: 0;
  }

  @media (min-width: 481px) and (max-width: 768px) {
      width: 100%;
      padding: 0;
  }
`;

const RightColumn = styled.div`
  display: grid;
  justify-content: center;
  padding: 20px;
  width: 50%;
  box-sizing: border-box;
  max-height: 350px;
  height: auto;
  grid-template-columns: 140px 140px;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.EGLightestGrey};

  @media (max-width: 480px) {
      width: 100%;
      padding: 15px 20px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
      width: 100%;
      padding: 15px 20px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: 100px 100px;
  }
`;

const PlaceholderNode = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 17.458px;
  margin: 20px;

  @media (max-width: 480px) {
    width: 60px;  
    height: 60px;
    margin: 5px;
    font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.small.letter};
  }

  @media (min-width: 481px) and (max-width: 768px) {
    width: 60px;  
    height: 60px;
    margin: 5px;
    font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.small.letter};
  }
`;

const LeftDroppableColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
`;
const RightDroppableColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
`;

const DroppableColumnSeperator = styled.div`
  width: 6px;
  border-radius: 100px;
  background: black;
  flex: 0 0 auto;
`;

const DroppableColumnHeader = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
  font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
  line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
  letter: ${({ theme: { typography } }) => typography.body.large.letter};
  color: ${({ theme: { colors } }) => colors.Black};
  width: 100%;
  text-align: center;

  @media (max-width: 480px) {
    font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.medium.letter};
  }

  @media (min-width: 481px) and (max-width: 768px) {
    font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.medium.letter};
  }
`;

const DroppableSlotContainer = styled.div`
  display: grid;
  padding: 0;
  box-sizing: border-box;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 400px;
  max-width: 400px;
  height: 350px;
  margin: 0 auto;
`;

const StyledImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledCardText = styled.p`
  margin: 0;
  padding: 5px 0;
`;

const StyledSlotContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyledSlotHeading = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
  font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
  line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
  color: ${({ theme: { colors } }) => colors.Black};
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
  }
`;

const StyledIconBoxTwo = styled.div`
  transform: translate(0, 40%);
  width: 100%;
  height: 60px;
`;

const StyledArrowBoxTwo = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #000;

  &::after, &::before {
    content: '';
    position: absolute;
    width: 30%;
    height: 6px;
    right: -8px;
    background-color: #000;
  }
  
  &::after {
    top: -12px;
    transform: rotate(45deg);
  }
  
  &::before {
    top: 12px;
    transform: rotate(-45deg);
  }
`;

const StyledIconBoxSix = styled.div`
  transform: rotate(90deg);
  width: 45%;
  height: 10px;
`;

const StyledArrowBoxSix = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #000;

  &::after, &::before {
    content: '';
    position: absolute;
    width: 67%;
    height: 6px;
    right: -8px;
    background-color: #000;
  }
  
  &::after {
    top: -12px;
    transform: rotate(45deg);
  }
  
  &::before {
    top: 12px;
    transform: rotate(-45deg);
  }
`;

const StyledIconBoxEight = styled.div`
  transform: rotate(180deg);
  width: 100%;
  height: 5px;
`;

const StyledArrowBoxEight = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #000;

  &::after, &::before {
    content: '';
    position: absolute;
    width: 30%;
    height: 6px;
    right: -8px;
    background-color: #000;
  }
  
  &::after {
    top: -12px;
    transform: rotate(45deg);
  }
  
  &::before {
    top: 12px;
    transform: rotate(-45deg);
  }
`;

const QuizCardMatchupA = forwardRef(
  (
    {
      onChange,
      isError,
      createCodeButtonText,
      enterCodeButtonText,
      isDisabled,
      onSubmitCode,
      copyMode,
      questionConfig,
      questionId,
      onReadyToSubmitChange,
      onQuestionStateChange,
      ...props
    },
    ref,
  ) => {
    const { assetServer } = useContext(AppSettingsContext);
    const [dropParent, setDropParent] = useState({});

    function handleDragEnd(event) {
      if (event.over && event.over.id) {
        const isCorrectAnswer = questionConfig.solution[
          event.active.id
        ].includes(event.over.id);
        if (!isCorrectAnswer) {
          return;
        }
        setDropParent((state) => {
          var stateWithDropParentRemoved = pickBy((k, v) => {
            return k !== event.active.id;
          }, state);
          return {
            ...stateWithDropParentRemoved,
            [`${event.over.id}`]: event.active.id,
          };
        });
      }
    }

    useImperativeHandle(ref, () => ({
      validateQuestion: () => {
        var answerResponse = Object.entries(questionConfig.solution).map(
          ([k, v]) => {
            const dropParentInQuestion = Object.entries(dropParent).find(
              ([dpk, dpv]) => dpv === k,
            );
            const [dpk, dpv] = dropParentInQuestion;
            return { slotId: dpk, answerId: k, isCorrect: v.includes(dpk) };
          },
        );
        if (answerResponse.every((r) => r.isCorrect)) {
          console.log("Quiz answer passed");
          onQuestionStateChange("Done");
        } else {
          console.log("Quiz answer failed");
          onQuestionStateChange("Failed");
        }
        console.log(answerResponse);
      },
    }));

    const dropParentValues = useMemo(
      () => Object.values(dropParent),
      [dropParent],
    );

    const answerMarkeup = Object.entries(questionConfig.answers).reduce(
      (accumulator, [answerKey, answerValue]) => {
        accumulator[answerKey] = (
          <DraggableAnswerBlock
            key={questionId+answerKey}
            isDropped={dropParentValues.includes(answerKey)}
            id={answerKey}
          >
            <>
              {answerValue.image !== "" && 
                <StyledImageContainer>
                  <img src={`${assetServer}/${answerValue.image}`} alt={`${answerValue.imageAlt}`} title={`${answerValue.imageAlt}`} />
                </StyledImageContainer>
              }
            </>
          </DraggableAnswerBlock>
        );
        return accumulator;
      },
      {},
    );

    const dropSlots = questionConfig.groupOne.slots.map((slotId) => (
      <StyledSlotContainer>
        {(slotId === "1" || slotId === "3" || slotId === "7" || slotId === "9" ) ? ( 
            <DroppableAnswerSlot id={slotId} key={questionId+slotId}>
              {dropParent[slotId] ? answerMarkeup[dropParent[slotId]] : null}
            </DroppableAnswerSlot>
          ) : (slotId === "2") ? (
            <StyledIconBoxTwo id={slotId} >
              <StyledArrowBoxTwo></StyledArrowBoxTwo>
            </StyledIconBoxTwo>
          ): (slotId === "6") ? (
            <StyledIconBoxSix id={slotId} >
              <StyledArrowBoxSix></StyledArrowBoxSix>
            </StyledIconBoxSix>
          ) : (slotId === "8") ? (
            <StyledIconBoxEight id={slotId} >
              <StyledArrowBoxEight></StyledArrowBoxEight>
            </StyledIconBoxEight>
          ) : null
        }
      </StyledSlotContainer>
    ));

    const submitEnabled =
      Object.keys(dropParent).length >= dropSlots.length - 5;

    useEffect(() => {
      if(onReadyToSubmitChange)
      {
        onReadyToSubmitChange(submitEnabled);
      }
    }, [submitEnabled]);

    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor);

    const sensors = useSensors(
      mouseSensor,
      touchSensor,
    );

    return (
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <LeftColumn>
          <DroppableSlotContainer>{dropSlots}</DroppableSlotContainer>
        </LeftColumn>
        <RightColumn>
          {Object.entries(answerMarkeup).map(([key, value]) =>
            !Object.values(dropParent).includes(key) ? (
              value
            ) : (
              <PlaceholderNode />
            ),
          )}
        </RightColumn>
      </DndContext>
    );
  },
);

export default QuizCardMatchupA;
