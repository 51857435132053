import React from "react";
import { ThemeProvider } from "styled-components/macro";;

const themeVariables = {
    colors: {
        EGMediumTurquoise: "#5CD4E1",
        EGLightSeaGreen: "#00ACBF",
        EGTeal: "#007280",
        EGSeagrass: "#BADA55",
        EGKhaki: "#E3E67A",
        EGYellowGreen: "#D2D82B",
        EGOlive: "#92961A",
        EGDarkSlatteGrey: "#312E2B",
        EGLightestGrey: "#F4F4F4",
        Grey80: "#4B4F4F",
        Grey60: "#787B7B",
        Grey40: "#A5A7A7",
        Grey20: "#D2D3D3",
        Grey10: "#E0E1E0",
        OhNoRed: "#D82E3D",
        PerfectlyPink: "#E27997",
        PrettyYellow: "#FAC411",
        White: "#FFFFFF",
        GatorGreen: "#7EB457",
        SwampGreen: "#3B8B48",
        GulfBlue: "#5B8DBD",
        ExtraDarkBlue: "#2B5767",
        LightGrey: "#F1F1F1",
        Black: "#161616",
        PureBlack: "#000000",
        DarkGray: '#515151'
    },
    typography: {
        display: {
            largest: {
                fontSize: "142px",
                fontWeight: "700",
                lineHeight: "164px"
            },
            large: {
                fontSize: "57px",
                fontWeight: "400",
                lineHeight: "72px"
            },
            medium: {
                fontSize: "45px",
                fontWeight: "400",
                lineHeight: "52px"
            },
            small: {
                fontSize: "36px",
                fontWeight: "400",
                lineHeight: "44px"
            }
        },
        headline: {
            largest: {
                fontSize: "42px",
                fontWeight: "700",
                lineHeight: "48px"
            },
            large: {
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "40px"
            },
            medium: {
                fontSize: "28px",
                fontWeight: "400",
                lineHeight: "36px"
            },
            small: {
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "32px"
            },
            subHeading: {
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "28px"
            }
        },
        title: {
            large: {
                fontSize: "22px",
                fontWeight: "700",
                lineHeight: "28px"
            },
            medium: {
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                letter: ".15px"
            },
            small: {
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letter: ".1px"
            }
        },
        label: {
            large: {
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letter: ".1px"
            },
            medium: {
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "16px",
                letter: ".5px"
            },
            small: {
                fontSize: "11px",
                fontWeight: "500",
                lineHeight: "16px",
                letter: ".5px"
            }
        },
        body: {
            large: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "28px",
                letter: ".5px"
            },
            medium: {
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                letter: ".25px"
            },
            small: {
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16px",
                letter: ".5px"
            }
        },
        buttonText: {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "16px",
            letter: ".5px"
        },
        courseCardTitleText: {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "18px",
            letter: ".5px"
        }
    }
};

const theme = {
    ...themeVariables,
    EGHeadlineLargest: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.headline.largest.fontSize};
        font-weight:  ${themeVariables.typography.headline.largest.fontWeight};
        line-height: ${themeVariables.typography.headline.largest.lineHeight};
    `,
    EGHeadlineSmall: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.headline.small.fontSize};
        font-weight:  ${themeVariables.typography.headline.small.fontWeight};
        line-height: ${themeVariables.typography.headline.small.lineHeight};
    `,
    EGHeadlineMedium: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.headline.medium.fontSize};
        font-weight:  ${themeVariables.typography.headline.medium.fontWeight};
        line-height: ${themeVariables.typography.headline.medium.lineHeight};
    `,
    EGHeadtitleLarge: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.title.large.fontSize};
        font-weight:  ${themeVariables.typography.title.large.fontWeight};
        line-height: ${themeVariables.typography.title.large.lineHeight};
    `,
    EGHeadtitleMedium: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.title.medium.fontSize};
        font-weight:  ${themeVariables.typography.title.medium.fontWeight};
        line-height: ${themeVariables.typography.title.medium.lineHeight};
    `,
    EGBodySmall: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.body.small.fontSize};
        font-weight:  ${themeVariables.typography.body.small.fontWeight};
        line-height: ${themeVariables.typography.body.small.lineHeight};
    `,
    EGDisplaySmall: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.display.small.fontSize};
        font-weight:  ${themeVariables.typography.display.small.fontWeight};
        line-height: ${themeVariables.typography.display.small.lineHeight};
    `,
    EGBodyMedium: `
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: ${themeVariables.typography.body.medium.fontSize};
        font-weight:  ${themeVariables.typography.body.medium.fontWeight};
        line-height: ${themeVariables.typography.body.medium.lineHeight};
    `
}

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  );
  
export default Theme;
  
