import React from "react";
import {Link} from 'react-router-dom';
import styled from "styled-components/macro";

const StyledLink = styled.a`
    align-items: center;
    background: ${({ buttonState, theme: { colors } }) => {
        if (buttonState === "secondary") {
            return colors.White;
        } else if (buttonState === "disabled") {
            return colors.Grey10;
        } else if (buttonState === "inverted") {
            return colors.EGKhaki;
        } else {
            return colors.EGTeal;
        }
    }};
    border: ${({ buttonState, theme: { colors } }) => {
        if (buttonState === "secondary") {
            return `solid ${colors.EGTeal} 2px`;
        } else if (buttonState === "disabled") {
            return "none";
        } else if (buttonState === "inverted") {
            return `solid ${colors.ExtraDarkBlue} 2px`;
        } else {
            return "none";
        }
    }};
    border-radius: 25px;
    color: ${({ buttonState, theme: { colors } }) => {
        if (buttonState === "secondary") {
            return colors.EGTeal;
        } else if (buttonState === "disabled") {
            return colors.Grey60;
        } else if (buttonState === "inverted") {
            return colors.ExtraDarkBlue;
        } else {
            return colors.White;
        }
    }};
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.buttonText.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.buttonText.fontWeight};
    height: 52px;
    letter: ${({ theme: { typography } }) => typography.buttonText.letter};
    line-height: ${({ theme: { typography } }) => typography.buttonText.lineHeight};
    margin: 0 12px;
    max-width: 293px;
    min-width: 220px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &.disable-link {
        pointer-events: none;
    }

    @media (max-width: 768px) {
        margin: 12px 0;
    }
`;

function LinkButtonWithText({ buttonText, buttonState, isDisabled, handleOnClick } ) {
    return (
            <StyledLink buttonState={buttonState} className={isDisabled ? `disable-link` : null} onClick={handleOnClick} >{buttonText}</StyledLink>
    );
}

export default LinkButtonWithText;