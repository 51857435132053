import styled from "styled-components/macro";
import { WaterDropletSVG } from "../../assets/index";

export const StyledInteractiveProgessBarContainer = styled.div`
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;

    @media (max-width: 768px) {
        width: 95%;
    }
`;

export const StyledInteractiveProgessBarTextContainer = styled.div`
    width: auto;
    height: auto;
    margin-right: 10px;
`;

export const StyledProgresText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    color: ${({ theme: { colors } }) => colors.PureBlack};
    margin: 0;
    padding: 0;
`;

export const StyledWaterDropletSVG = styled(WaterDropletSVG)`
    z-index: 10;
`;