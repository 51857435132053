import styled from "styled-components";

export const StyledMultipleChoiceToolTipContainer = styled.div`

`;

export const StyledToolTipTitle = styled.p`
    font-family: 'Poppins', sans-serif;
    color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    font-size: ${({ theme: { typography } }) => typography.title.small.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.title.small.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.title.small.lineHeight};
    letter: ${({ theme: { typography } }) => typography.title.small.letter};
`;

export const StyledToolTipContent = styled.p`
    font-family: 'Poppins', sans-serif;
    color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.small.letter};
`;
