import styled from "styled-components/macro";
import { useDroppable } from '@dnd-kit/core';

const AnswerSlot = styled.span`
    display: inline-block;
    min-width: 150px;
    height: 50px;
    border: 1.397px dashed ${({ theme: { colors } }) => colors.Grey20};
    border-radius:8.381px;
    background: ${({ theme: { colors } }) => colors.White};
    margin: 0;
    vertical-align: middle;
    @media (max-width: 480px) {
      min-width: 112px;
      width: 112px;
      height: 28px;
      border-radius: 0px;
    }

    @media (min-width: 481px) and (max-width: 768px) {

    }

    @media (min-width: 769px) and (max-width: 1230px) {

  }
`

const AnswerSlotContainer = styled.span`
  display: inline-block;
  margin: 3px 0;
`

export default function DroppableTextAnswerSlot({style: propsStyle, ...props}) {

    const {isOver, setNodeRef} = useDroppable({
      id: props.id,
    });
    let style = {
      color: isOver ? '#A5A7A7' : undefined,
      background: isOver ? '#A5A7A7' : 'white',
      border: isOver ? '1px solid rgba(0,0,0,0)':''
    };

    if (props.isPhotosynthesisImageQuestion) {
      style.background = isOver ? "white": "transparent"
      style.border = "2px dashed black";
    }

    // const newStyle = {...propsStyle, ...style};
    
    return (
      <AnswerSlotContainer style={propsStyle} {...props}>
      <AnswerSlot style={style} ref={setNodeRef}>
        {props.children}
      </AnswerSlot>
      </AnswerSlotContainer>
    );
  }