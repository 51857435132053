import React from "react";
import styled from "styled-components/macro";
import InputMask from "react-input-mask";
import { TextField, FormControl } from "@mui/material";
import { ErrorExclamationSVG } from "../../assets";

const StyledStudentCodeTextField = styled(TextField)`
    input {
        padding: 20px 32px;
        color: var(--dark-gray,#515151);
        text-align: center;
        font-size: 36px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        line-height: 43px;
        height: 39px;
        border-radius: 12px;
        border:  ${({ $isError, theme: { colors } }) => {
            if ($isError) {
                return `2px solid var(--oh-no-red, ${colors.OhNoRed})`
            } else {
                return "2px solid var(--grey-80, #4B4F4F)"
            }
        }};
        width: 100%;
        background: var(--white, #FFF);
        appearance: none;
        outline: none;
        box-shadow: none;
    }
    fieldset {
        width: 100%;
        border: none;
        appearance: none;
        outline: none;
        box-shadow: none;
        background: none;
    }
`;

const StyeldFormControl = styled(FormControl)`
    width: ${({ $stretchToFitHorizontally }) => {
        if ($stretchToFitHorizontally) {
            return "100%";
        } else {
            return "auto";
        }
    }};
`

const InputErrorAdornment = styled.div`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    width: 24px;
    height: 24px;
`

const ErrorText = styled.p`
    ${({ theme: {colors, EGBodySmall} }) => `
        ${EGBodySmall}
        color: ${colors.OhNoRed};
    `}
    margin: 0;
    padding: 10px 0 0 0;
    color: 
`


function StudentCodeInput({ onChange, isError, value, isDisabled, stretchToFitHorizontally, ...props }) {
    const handleChangeValue = (event) => {
        onChange(event.target.value.replace(/[^0-9a-z]/gi, '').toUpperCase())
      };

    return (
        <>
        <StyeldFormControl $stretchToFitHorizontally={stretchToFitHorizontally} disabled={false}>
            <InputMask
            mask="***-***"
            alwaysShowMask
            value={value}
            disabled={isDisabled}
            onChange={handleChangeValue}
            >
                <StyledStudentCodeTextField
                id="student-code"
                disabled={isDisabled}
                name="cnpj"
                type="text"
                error={false}
                value={value}
                $isError={isError}
                spellCheck="false"
                {...props}
                />
            </InputMask>
            {isError && <InputErrorAdornment><ErrorExclamationSVG /></InputErrorAdornment>}
        </StyeldFormControl>
        {isError && <ErrorText>The code you entered doesn't match our records. Check your code and try again. </ErrorText>}
        </>
  );
}

export default StudentCodeInput;