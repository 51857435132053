import styled from "styled-components/macro";
import { useDroppable } from '@dnd-kit/core';

const AnswerSlot = styled.div`
    width: 100px;
    height: 120px;
    border-radius: 17.458px;
    border: 1.397px solid ${({ theme: { colors } }) => colors.Grey20};
    background: #FFF;
    margin: 15px;

    @media (max-width: 480px) {
      width: 60px;  
      height: 80px;
    }
  
    @media (min-width: 481px) and (max-width: 768px) {
      width: 60px;  
      height: 80px;
    }
`

export default function DroppableAnswerSlot(props) {
    const {isOver, setNodeRef} = useDroppable({
      id: props.id,
    });
    const style = {
      color: isOver ? '#A5A7A7' : undefined,
      background: isOver ? '#A5A7A7' : 'none'
    };
    
    
    return (
      <AnswerSlot ref={setNodeRef} style={style}>
        {props.children}
      </AnswerSlot>
    );
  }