import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { KeyOutlineSVG, LandingTreeSVG } from "../../assets";
import StudentCodeInput from "../StudentCodeInput";
import ButtonWithText from "../ButtonWithText";
import StudentCodeView from "../StudentCodeView";
import useStore from "../../shared/Store";
import useUserData from "../../shared/Using/UseUserData";

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
`

const FormRow = styled.div`
    width: 100%;
    margin-bottom: 50px;
    &:last-child
    {
        margin-bottom: 0;
    }
`

const StudentCodeLoginFormContainer = styled.div`
    width: 430px;
    margin-bottom: 24px;
`

const ResetText = styled.p`
    color: ${({ theme: { colors } }) => colors.DarkGray};
    ${({ theme }) => theme.EGBodyMedium};
    text-align: center;
    text-align: center;
    letter-spacing: 0.25px;
    user-select: none;
    margin: 0;
    padding: 0 0 52px 0;
    &:last-child
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`

const ResetTextButton = styled.span`
    color: ${({ theme: { colors } }) => colors.EGTeal};
    text-align: center;
    ${({ theme }) => theme.EGBodyMedium};
    cursor: pointer;
`

const InstructionBlock = styled.div`
    margin-bottom: 32px;
    text-align: center;
`

const InstructionBlockHeader = styled.h3`
    ${({ theme }) => theme.EGDisplaySmall};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 0;
    padding: 0 0 4px 0;
`

const InstructionBlockBody = styled.p`
    ${({ theme }) => theme.EGBodyMedium};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 0;
    padding: 0;
`

const StyledLandingTreeSVG = styled(LandingTreeSVG)`
    padding: 0;
    margin: 0 0 24px 0;
    box-sizing: content-box;
`

const TipContainer = styled.div`
    width: 100%;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    ${({ theme }) => theme.EGBodyMedium};
    letter-spacing: 0.25px;
`

const StudentCodeInputButtonWithText = styled(ButtonWithText)
`
    @media (max-width: 480px) {
        margin: 0;
        width: 100%;
        height: 52px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 0;
        height: 52px;
        width: 100%;
    }
`

const StyledIntitialButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 30px 0;

    button {
        margin: 10px 0;
    }
`;

async function fetchCode() {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || `https://${window.location.hostname}`;
    const response = await fetch(`${apiEndpoint}/api/Users/getCode`);
    return await response.json();
}

const FORM_STATES = {
    INITIAL: "initial",
    CONTINUE: "continue",
    GETCODE: "getcode",
};

function StudentCodeLoginForm({ onChange, isDisabled, onSubmitCode, ...props }) {
    // state options: initial, continue, getcode
    const [studentFormState, setStudentFormState] = useState(FORM_STATES.INITIAL);
    const [value, onValueChange] = useState("")
    const [genCode, setGenCode] = useState(null);
    const [isInvalid, setIsInvalid] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const userInvalid = useStore(s => s.userInvalid)
    const {checkValidCode} = useUserData();

    const handleSubmit = async (e) => {
        e.preventDefault()
        const isCreatingNewCode = studentFormState === FORM_STATES.GETCODE;
        const valueToSubmit = isCreatingNewCode ? genCode : value;
        if(valueToSubmit < 6) {
            setIsInvalid(true);
            setIsTouched(true);
            return;
        } else {
            if (!isCreatingNewCode) {
                let isValid = await checkValidCode(valueToSubmit)
    
                if(!isValid)
                {
                    setIsInvalid(true);
                    setIsTouched(true);
                    return
                }
                onSubmitCode(valueToSubmit, isCreatingNewCode)
            } else {
                onSubmitCode(valueToSubmit, isCreatingNewCode)
            }
        }

    }

    const handleStudentCodeInputChange = useCallback((val) => {
        onValueChange(val)
        if(isTouched && val.length < 6)
        {
            setIsInvalid(true);
            setIsTouched(true);
        }
        else
        {
            setIsInvalid(false);
        }
    }, [
        setIsInvalid,
        setIsTouched,
        isTouched,
    ])
    
    useEffect(() => {
        async function genCode() {
            const response = await fetchCode();
            setGenCode(response.code);
        }
        genCode();
    }, [setGenCode]);

    const handleContinueAdventureClick = () => {
        setStudentFormState("continue");
    }

    const handleGetNewCodeClick = () => {
        setStudentFormState(FORM_STATES.GETCODE);
    }

    return (
        <StudentCodeLoginFormContainer>
            <InstructionBlock>
                <StyledLandingTreeSVG sx={{ fontSize: 180 }} />
                <InstructionBlockHeader><KeyOutlineSVG  sx={{ fontSize: 29, marginRight:2 }} /> Your Special Code</InstructionBlockHeader>
                <InstructionBlockBody>Treasure this code! It's your magic key to jump back into your adventure. Lose it, and you'll have to start all over!</InstructionBlockBody>
            </InstructionBlock>

            {studentFormState === FORM_STATES.INITIAL ? (
                <>
                    <StyledIntitialButtonContainer>
                        <StudentCodeInputButtonWithText handleOnClick={handleContinueAdventureClick} stretchToFitHorizontally buttonState="primary" buttonText={"Continue Adventure"} />
                        <StudentCodeInputButtonWithText handleOnClick={handleGetNewCodeClick} stretchToFitHorizontally buttonState="secondary" buttonText={"Get Code & Start Adventure"} />
                    </StyledIntitialButtonContainer>

                    <ResetText>First time here or lost your code? <ResetTextButton onClick={handleGetNewCodeClick}>Get a new one</ResetTextButton></ResetText>
                </>
            ) : (

                <>
                    <FormContainer onSubmit={handleSubmit}>
                        <FormRow>
                            {studentFormState === FORM_STATES.CONTINUE ? (
                                <StudentCodeInput stretchToFitHorizontally value={value} onChange={handleStudentCodeInputChange} isError={isTouched && (userInvalid || isInvalid)} />
                            ) : studentFormState === FORM_STATES.GETCODE ? (
                                <StudentCodeView stretchToFitHorizontally value={genCode} />
                            ) : null}
                        </FormRow>
                        <FormRow>
                            {studentFormState === FORM_STATES.CONTINUE ? (
                                <StudentCodeInputButtonWithText type="submit" stretchToFitHorizontally buttonState={isDisabled ? "disabled" : "primary"} buttonText={"Continue Adventure"} />
                            ) : studentFormState === FORM_STATES.GETCODE ? (
                                <StudentCodeInputButtonWithText type="submit" stretchToFitHorizontally buttonState={isDisabled ? "disabled" : "primary"} buttonText={"Start Adventure"} />
                            ) : null}
                        </FormRow>
                    </FormContainer>
                    {studentFormState === FORM_STATES.CONTINUE ? (
                        <ResetText>First time here or lost your code? <ResetTextButton onClick={handleGetNewCodeClick}>Get a new one</ResetTextButton></ResetText>
                    ) : studentFormState === FORM_STATES.GETCODE ? (
                            <>
                                <ResetText>Have a special code already? <ResetTextButton onClick={handleContinueAdventureClick}>Use my special code</ResetTextButton></ResetText>
                                <TipContainer>Save your spot! Want to get back to your learning fast? Just copy this link or add it to your bookmarks. You'll be right back in the game in no time!</TipContainer>
                            </>
                    ) : null}
                </>
            )}
        </StudentCodeLoginFormContainer>

  );
}

export default StudentCodeLoginForm;