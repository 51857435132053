import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { CloseIconWhiteSVG } from "../../assets/index";
import InformationalToolTip from "../InformationalToolTip";
import { AppSettingsContext } from "../../AppSettingsContext";

const StyledMainHeaderImageContainer = styled.div`
    background-image: ${(props) => `url(${props.heroImage})`};
    background-size: cover;
    background-position: top center;
    height: ${({ $isCondensed }) => {
        if ($isCondensed) {
            return `151px`;
        } else {
            return `auto`;
        }
    }};
    width: auto;
`;

const StyledMainHeaderContentContainer = styled.div`
    background-color: ${({ theme: { colors } }) => colors.EGTeal};
    display: flex;
    flex-direction: column;
    height: ${({ $isCondensed }) => {
        if ($isCondensed) {
            return `111px`;
        } else {
            return `auto`;
        }
    }};
    opacity: .65;
    padding: ${({ $isCondensed }) => {
        if ($isCondensed) {
            return `20px 40px`;
        } else {
            return `30px 40px`;
        }
    }};
    width: auto;
    position: relative;
`;

const StyledTopContentContainer = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledHeadingContainer = styled.div`
    width: ${({ $isQuizView }) => {
        if ($isQuizView) {
            return `100%`;
        } else {
            return `70%`;
        }
    }};
    height: 120px;
    display: flex;
    align-items: flex-end;

    @media (max-width: 480px) {
        height: 60px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 80px;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        height: 100px;
    }
`;

const StyledTitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    justify-content: end;
`;

const StyledMapLink = styled(Link)`
    width: 46px;
    height: 46px;
    cursor: pointer;
    margin: 0;
    padding: 0;

    svg {
        height: 100%;
        width: 100%;
    }

    @media (max-width: 480px) {
        width: 30px;
        height: 30px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 36px;
        height: 36px;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        width: 46px;
        height: 46px;
    }
`;

const StyledHeading = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.medium.lineHeight};
    color: ${({ theme: { colors } }) => colors.White};
    margin: 0;
    padding: 0;
    text-shadow: ${({ theme: { colors } }) => `2px 5px 15px ${colors.Black}`};

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.medium.lineHeight};
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        font-size: ${({ theme: { typography } }) => typography.headline.large.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.large.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.large.lineHeight};
    }
`;

const StyledLessonTitleContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
`;

const StyledCourseTitle = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.largest.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.largest.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.largest.lineHeight};
    color: ${({ theme: { colors } }) => colors.White};
    margin: 0 0 15px 0;
    padding: 0;
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    text-shadow: ${({ theme: { colors } }) => `1px 3px 20px ${colors.Black}`};
	-webkit-background-clip: text;
	background-clip: text;

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.display.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.display.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.display.small.lineHeight};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.display.medium.lineHeight};
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        font-size: ${({ theme: { typography } }) => typography.display.large.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.display.large.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.display.large.lineHeight};
    }
`;

const StyledInformationalToolTipCondensedWrapper = styled.div`
    bottom: ${({ $isCondensed }) => {
        if ($isCondensed) {
            return `33%`;
        } else {
            return `5%`;
        }
    }};
    right: ${({ $isCondensed }) => {
        if ($isCondensed) {
            return `100px;`;
        } else {
            return `3%`;
        }
    }};
    position: absolute;
`;

function TopicMainHeader(props) {
    let {isCondensed, courseTitle, lessonTitle, heroImage, imageCredit, imageCreditStyle, isQuizView, courseId, imageAlt} = props;
    if (courseTitle === "Food Chains") {
        courseTitle = "Food Chains & Food Webs";
    }
    const { assetServer } = useContext(AppSettingsContext);
    return (
        <StyledMainHeaderImageContainer $isCondensed={isCondensed} heroImage={assetServer + `/${heroImage}`} >
            <StyledMainHeaderContentContainer $isCondensed={isCondensed} >
                <StyledTopContentContainer>

                    {isQuizView ? (
                        <StyledHeadingContainer $isQuizView={isQuizView}>
                            <StyledHeading>{`${lessonTitle} Quiz`}</StyledHeading>
                        </StyledHeadingContainer>
                    ) : (
                        <StyledHeadingContainer $isQuizView={isQuizView}>
                            <StyledHeading>{courseTitle}</StyledHeading>
                        </StyledHeadingContainer>
                    )}
                    {!isQuizView &&
                        <StyledTitleContainer>
                            <StyledMapLink to={`/course/${courseId}/lessonSelect`} ><CloseIconWhiteSVG /></StyledMapLink>
                        </StyledTitleContainer>
                    }
                </StyledTopContentContainer>
                {!isCondensed && 
                    <StyledLessonTitleContainer>
                        <StyledCourseTitle>{lessonTitle}</StyledCourseTitle>
                    </StyledLessonTitleContainer>
                }
                {imageCredit !== "" && 
                    <StyledInformationalToolTipCondensedWrapper $isCondensed={isCondensed}>
                        <InformationalToolTip toolTipStyle={imageCreditStyle} toolTipTitle={""} toolTipContent={imageCredit} toolTipAltText={imageAlt} />
                    </StyledInformationalToolTipCondensedWrapper>
                }
            </StyledMainHeaderContentContainer>
        </StyledMainHeaderImageContainer>   
    );
}

export default TopicMainHeader;