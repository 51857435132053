import { useCallback, useContext, useState } from "react";
import styled from "styled-components/macro";
import { LightbulbSVG, GreenCheckBoxSVG, RedCheckBoxSVG, SelectionCheckBoxSVG, UncheckedCheckBoxSVG } from "../../assets";
import MultipleChoiceToolTip from "../MultipleChoiceToolTip";
import { AppSettingsContext } from "../../AppSettingsContext";

const Wrapper = styled.div`
    padding: 20px 0;

    @media (max-width: 480px) {
        padding: 5px 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 5px 0;
    }

    @media (min-width: 769px) {
        padding: 10px 0;
    }
`

const LabelContentWrapper = styled.div`
    display: flex;
    border-radius: 10px;
    border: 2px solid var(--grey-80, #4B4F4F);
    ${({ state }) => {
        switch (state) {
            case "correctSelection":
                return `
                    border: 2px solid var(--grey-80, green);
                `
            case "incorrectSelection":
            case "incorrectSelectionNoTip":
                return `
                    border: 2px solid var(--grey-80, red);
                `
            case "correctNonselection":
                return `

                `
            case "incorrectNonselection":
                return `
                `
            default:
                return `
                    border: 2px solid var(--grey-80, #4B4F4F);
                `
        }
    }}
    width: 100%; 
    padding: 8px 24px 8px 16px;
    align-items: center;
    box-sizing: border-box;
`

const Input = styled.input`
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const CheckboxVisual = styled.div`
    width: 18px;
    height: 18px;
    margin-left: 30px;
    ${({ state }) => {
        switch (state) {
            case "correctSelection":
                return `
                `
            case "incorrectSelection":
                return `
                    svg {
                        width: 59px;
                        height: 59px;
                    }
                `
            case "incorrectSelectionNoTip":
                return `
                `
            case "correctNonselection":
                return `
                `
            case "incorrectNonselection":
                return `
                `
            default:
                return `
                `
        }
    }}
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 22px;
        height: 22px;
    }
`

const Label = styled.label`
    width: 100%;
    display flex;
    border 1px solid grey:
    border-radius: 2px;
    align-items: center;
    cursor: pointer;
    * {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
`

const LabelText = styled.div`
    flex: 1 1 auto;
    padding: 11px 0;
`

const AnswerImage = styled.img`
    margin-right: 10px;
`

const StyledLightbulbSVG = styled(LightbulbSVG)
    `
`

const Checkbox = ({
    id,
    label,
    image,
    tip,
    isChecked,
    isMultiSelect,
    onChange,
    setTouched,
    state,
    ...props }
) => {
    const { assetServer } = useContext(AppSettingsContext);
    const onChangeHandler = useCallback(() => {
        onChange(id)
    })
    const handleOnLabelClick = useCallback((e) => {
        if (state === "incorrectNonselection") {
            e.preventDefault()
        }
    })

    return (
        <Wrapper>
            <Input
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={onChangeHandler}
                {...props}
            />
            <Label onClick={handleOnLabelClick} htmlFor={id} >
                <LabelContentWrapper state={state}>
                    {image && <AnswerImage src={`${assetServer}/${image}`} />}
                    <LabelText dangerouslySetInnerHTML={{__html: label}} />
                    {state === "incorrectSelectionNoTip" ? <RedCheckBoxSVG /> :
                        state === "incorrectSelection" ?
                            <div>
                                <MultipleChoiceToolTip setTouched={setTouched} toolTipTitle={"Let's Learn"} toolTipContent={tip} />
                            </div> :
                        <>
                            <CheckboxVisual aria-hidden="true" isChecked={isChecked} state={state}>
                                {state === "correctSelection" ?
                                    <GreenCheckBoxSVG /> :
                                    isChecked ? <SelectionCheckBoxSVG />
                                    : isMultiSelect ? <UncheckedCheckBoxSVG /> : null
                                }
                            </CheckboxVisual>
                        </>

                    }
                </LabelContentWrapper>
            </Label>
        </Wrapper>
    );
}

export default Checkbox;