import { AvatarLockSVG } from ".";
import { 
    DefaultAvatarSVG,
    AfroManAvatarSVG,
    AvatarBlackShirtManSVG,
    BlondeFemale1AvatarSVG,
    BlondeFemale2AvatarSVG,
    Boy1AvatarSVG,
    Boy2AvatarSVG,
    Businessman1AvatarSVG,
    Businessman2AvatarSVG,
    DoctorAvatarSVG,
    DreadlocksFemaleAvatarSVG,
    EmployeeAvatarSVG,
    Female1AvatarSVG,
    Female2AvatarSVG,
    FemaleAssistantAvatarSVG,
    FemaleAvatarAvatarSVG,
    Girl1AvatarSVG,
    Girl2AvatarSVG,
    Girl3AvatarSVG,
    Girl4AvatarSVG,
    Girl5AvatarSVG,
    Male1AvatarSVG,
    Male2AvatarSVG,
    Man1AvatarSVG,
    Man2AvatarSVG,
    Man3AvatarSVG,
    OldLadyAvatarSVG,
    OldWomanAvatarSVG,
    SkinHeadWomanAvatarSVG,
    TeenAvatarSVG,
    Teenager1AvatarSVG,
    Teenager2AvatarSVG,
    Woman1AvatarSVG,
    Woman2AvatarSVG,
 } from "./avatars";

export const icons = [
    // {svg: DefaultAvatarSVG, label: "DefaultAvatarSVG"},
    {svg: AfroManAvatarSVG, label: "AfroManAvatarSVG"},
    {svg: Man1AvatarSVG, label: "Man1AvatarSVG"},
    {svg: AvatarBlackShirtManSVG, label: "AvatarBlackShirtManSVG"},
    {svg: OldLadyAvatarSVG, label: "OldLadyAvatarSVG"},
    // {svg: BlondeFemale1AvatarSVG, label: "BlondeFemale1AvatarSVG"},
    {svg: BlondeFemale2AvatarSVG, label: "BlondeFemale2AvatarSVG"},
    // {svg: Boy1AvatarSVG, label: "Boy1AvatarSVG"},
    {svg: Boy2AvatarSVG, label: "Boy2AvatarSVG"},
    // {svg: Businessman1AvatarSVG, label: "Businessman1AvatarSVG"},
    {svg: Businessman2AvatarSVG, label: "Businessman2AvatarSVG"},
    // {svg: DoctorAvatarSVG, label: "DoctorAvatarSVG"},
    {svg: DreadlocksFemaleAvatarSVG, label: "DreadlocksFemaleAvatarSVG"},
    {svg: EmployeeAvatarSVG, label: "EmployeeAvatarSVG"},
    {svg: Teenager2AvatarSVG, label: "Teenager2AvatarSVG"},
    {svg: Female1AvatarSVG, label: "Female1AvatarSVG"},
    {svg: Female2AvatarSVG, label: "Female2AvatarSVG"},
    {svg: FemaleAssistantAvatarSVG, label: "FemaleAssistantAvatarSVG"},
    // {svg: FemaleAvatarAvatarSVG, label: "FemaleAvatarAvatarSVG"},
    // {svg: Girl1AvatarSVG, label: "Girl1AvatarSVG"},
    // {svg: Girl2AvatarSVG, label: "Girl2AvatarSVG"},
    // {svg: Girl3AvatarSVG, label: "Girl3AvatarSVG"},
    // {svg: Girl4AvatarSVG, label: "Girl4AvatarSVG"},
    // {svg: Girl5AvatarSVG, label: "Girl5AvatarSVG"},
    {svg: Male1AvatarSVG, label: "Male1AvatarSVG"},
    {svg: Male2AvatarSVG, label: "Male2AvatarSVG"},
    
    // {svg: Man2AvatarSVG, label: "Man2AvatarSVG"},
    // {svg: Man3AvatarSVG, label: "Man3AvatarSVG"},
    
    // {svg: OldWomanAvatarSVG, label: "OldWomanAvatarSVG"},
    {svg: SkinHeadWomanAvatarSVG, label: "SkinHeadWomanAvatarSVG"},
    {svg: TeenAvatarSVG, label: "TeenAvatarSVG"},
    {svg: Teenager1AvatarSVG, label: "Teenager1AvatarSVG"},
    
    {svg: Woman1AvatarSVG, label: "Woman1AvatarSVG"},
    //Woman2AvatarSVG
]

export const getAvatarWithLock = (avatarSelectionLocked, currentAvatarSvg) => avatarSelectionLocked ? AvatarLockSVG : icons.find(i => i.label === currentAvatarSvg)?.svg || DefaultAvatarSVG
export const getAvatar = (avatarLabel) => icons.find(i => i.label === avatarLabel)?.svg || DefaultAvatarSVG