import { React, useEffect, useState, createContext } from "react";
import styled from "styled-components/macro";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import Theme from "./Theme";
import LoginPage from "./pages/Login";
import LandingPage from "./pages/Landing";
import CoursePage from "./pages/Course";
import useStore from "./shared/Store";
import LessonSelectPage from "./pages/LessonSelect";
import GamePage from "./pages/Game";
import { AppSettingsContext } from "./AppSettingsContext";
import ErrorPage from "./pages/ErrorPage";
import ReactGA from "react-ga4";

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const queryClient = new QueryClient()
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || `https://${window.location.hostname}`;

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const doc = document.documentElement

function App() {
  const [isOffline, setIsOffline] = useState(false);
  const visableModals = useStore(({visiableModals}) => visiableModals);
  const [appSettings, setAppSettings] = useState({});

  useEffect(() => {
    let vh = document.documentElement.clientHeight;
    doc.style.setProperty('--doc-height', `${vh}px`)
    const debouncedHandleResize = debounce(function handleResize() {
      vh = document.documentElement.clientHeight;
      doc.style.setProperty('--doc-height', `${vh}px`)
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
})

  async function fetchSettings() {
    const response = await fetch(`${apiEndpoint}/api/settings`);
    return await response.json();
  }

  useEffect( () => {
    async function fetchData() {
      const response = await fetchSettings();
      setAppSettings(response);
      ReactGA.initialize(response.googleMeasurementId);
    }
    fetchData();
  },[])

  


  useEffect(() => {
    var ifConnected = window.navigator.onLine;
    if (ifConnected) {
      setIsOffline(false);
    } else {
      setIsOffline(true);
    }

    setInterval(function(){ 
      var ifConnected = window.navigator.onLine;
        if (ifConnected) {
          setIsOffline(false);
        } else {
          setIsOffline(true);
        }
    }, 60000);
  }, [isOffline])

  return (
    <BrowserRouter>
        <Theme>
        <Container>
          <QueryClientProvider client={queryClient}>
            <AppSettingsContext.Provider value={appSettings}>
              {isOffline ? (
                <>
                  <ErrorPage isOffline={isOffline} />
                </>
              ) : (
                <>
                  <Routes>
                    <Route path={"/"} element={<LandingPage key={"LandingPagePage"} />} />
                    <Route path={"/login"} element={<LoginPage key={"LoginPagePage"} />} />
                    <Route path={"/course/:courseId/lesson/:lessonId/page/:pageId"} element={<CoursePage key={"CoursePagePage"} />} />
                    <Route path={"/course/:courseId/lessonSelect"} element={<LessonSelectPage key={"LessonSelectPagePage"} />} />
                    <Route path={"/course/:courseId/game/:gameId"} element={<GamePage key={"GamePage"} />} />
                  </Routes>
                  <AnimatePresence>
                    {visableModals.map((modalDef) =>
                      <div key={modalDef.tag}>{modalDef.modal}</div>
                    )}
                  </AnimatePresence>
                </>
              )}
            </AppSettingsContext.Provider>
          </QueryClientProvider>
        </Container>
      </Theme>
    </BrowserRouter>
  );
}

export default App;
