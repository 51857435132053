import React, { useContext } from "react";
import styled from "styled-components/macro";
import InformationalToolTip from "../InformationalToolTip";
import { AppSettingsContext } from "../../AppSettingsContext";

const StyledTopicContentTextImageContainer = styled.div`
    width: auto;
    height: auto;
    padding: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
`;

const StyledTopicContentTextContainer = styled.div`
    width: auto;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (max-width: 480px) {
        padding: 10px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 15px;
    }
`;

const StyledTopicHeadingText = styled.h3`
    color: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.medium.lineHeight};
    margin: 0 0 20px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.display.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.display.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.display.small.lineHeight};
    }
`;

const StyledTopicText = styled.p`
    color: ${({ theme: { colors } }) => colors.Grey80};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    margin: 5px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    }

    strong
    {
        font-weight: 700;
    }
`;

const StyledTopicContentImageContainer = styled.div`
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

`;

const StyledImage = styled.img`
    width: ${({ $isResized }) => {
        if ($isResized) {
            return `85%;`
        } else {
            return `100%;`
        }
    }};
    height: auto;
    margin: 0 auto;
`;

const StyledVideoContainer = styled.div`
    width: 100%;
    max-height: 700px;
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    display: flex;
    justify-content: center;

    video {
        width: 100%;
        max-height: 700px;
    }
`;

const StyledVideoPoster = styled.div`
    background-image: ${({ assetServer }) => `url("${assetServer}/images/play-icon.png")`};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-size: contain;
    background-position: center center;
    cursor: pointer;
    background-repeat: no-repeat;
`;

/**
 * @param {string} imageUrl - string of image url or location if needed - optional
 * @param {string} imageAlt - string of image alt text if needed - optional
 * @param {string} heading - string of the topic heading if needed - optional
 * @param {string} textContent - string of the topic content if needed - optional
 * @param {string} videoUrl - string of video url or location - optional
 * @param {string} videoWidth - string of the video width - required if video
 * @param {string} videoHeight - string of the video height - required if video
 */
function TopicContentFeature(props) {
    const {
        imageUrl,
        imageAlt,
        imageCredit,
        imageCreditStyle,
        heading,
        textContent,
        videoUrl,
        videoWidth,
        videoHeight
    } = props;

    const { assetServer } = useContext(AppSettingsContext);
    const isResized = imageUrl === "images/course2/lesson1/Lesson_1_page_4_image_1.webp";

    const onClickVideo = () => {
        document.getElementById("video").play();
        document.getElementById("video-preview").style.display = "none";
    }

    return (
        <StyledTopicContentTextImageContainer>
            <StyledTopicContentImageContainer>
                <>
                    {imageUrl && 
                        <StyledImage $isResized={isResized} src={`${assetServer}/${imageUrl}`} alt={imageAlt} title={imageAlt} ></StyledImage>
                    }
                    {videoUrl && 
                        <StyledVideoContainer>
                        <StyledVideoPoster id="video-preview" assetServer={assetServer} onClick={onClickVideo} ></StyledVideoPoster>
                        <video id="video" controls="controls" loop="loop" width={videoWidth} height={videoHeight} src={`${assetServer}/${videoUrl}`} type="video/mp4" ></video>
                        </StyledVideoContainer>
                    }
                    {imageCredit !== "" && 
                        <InformationalToolTip toolTipStyle={imageCreditStyle} toolTipTitle={""} toolTipContent={imageCredit} toolTipAltText={imageAlt} />
                    }
                    <InformationalToolTip toolTipStyle={imageCreditStyle} toolTipTitle={""} toolTipContent={imageCredit} toolTipAltText={imageAlt} />
                </>
            </StyledTopicContentImageContainer>
            {heading !== "" && textContent !== "" && 
                <StyledTopicContentTextContainer>
                    <StyledTopicHeadingText>{heading}</StyledTopicHeadingText>
                    <StyledTopicText  dangerouslySetInnerHTML={{__html: textContent}} />
                </StyledTopicContentTextContainer>
            }
        </StyledTopicContentTextImageContainer>
    );
}

export default TopicContentFeature;