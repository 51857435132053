import styled from "styled-components/macro";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import Checkbox from "./Checkbox";
import { AppSettingsContext } from "../../AppSettingsContext";

const QuizMultipleChoiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 480px) {
        padding: 20px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 20px;
    }
`

const AnswerContainer = styled.div`
    flex 1 1 40%;
    margin-left: 20px;

    @media (max-width: 480px) {
        max-width: 516px;
        margin-left: 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        max-width: 516px;
        margin-left: 0;
    }
`

const QuizContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 480px) {
        flex-direction: column;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        flex-direction: column;
    }

    @media (min-width: 769px) {
        justify-content: space-between;
    }
`;

const ImageContainer = styled.div`
    margin: 0 15px 0 0;
    width: 100%;

    @media (max-width: 480px) {
        margin: 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 0;
    }

    @media (min-width: 769px) {
        width: 50%;
    }
`;

const AnswerList = styled.ul`
    padding: 0;
    list-style: none;

    @media (max-width: 480px) {
        margin: 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 0;
    }

    @media (min-width: 769px) {
        margin: 0;
    }

    li {
        @media (min-width: 769px) {
            padding: 5px;
        }
    }

`

const StyledImage = styled.img`
    width: 450px;
    height: 450px;
    overflow: hidden;

    @media (max-width: 480px) {
        width: 100%;
        height: auto;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
        height: auto;
    }

    @media (min-width: 769px) {
        width: 100%;
        height: auto;
        max-width: 400px;
    }
`;

const QuestionContainer = styled.div`
`

const QuestionCopy = styled.p`
    color: ${({ theme: { colors } }) => `2px solid ${colors.EGDarkSlatteGrey}`};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.medium.lineHeight};

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
        margin: 10px 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
        margin: 10px 0;
    }
`

const StyledErrorMessageContainer = styled.div`
    width: auto;
    height: auto;
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    padding: 10px 15px;
    user-select: none;
`;

const StyledErrorMessageText = styled.p`
    color: ${({ theme: { colors } }) => colors.Grey80};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.large.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    margin: 5px 0;
`;

const QuizMultipleChoice = forwardRef(
    (
      {
        onChange,
        isError,
        isDisabled,
        questionId,
        questionConfig,
        onReadyToSubmitChange,
        onQuestionStateChange,
        ...props
      },
      ref,
    ) => {
    const { assetServer } = useContext(AppSettingsContext);
    const isMutuallyExclusive = !Array.isArray(questionConfig.solution)
    const isMultiSelect = Array.isArray(questionConfig.solution)
    const [isChecked, setIsChecked] = useState([]);
    const [quizResults, setQuizResults] = useState({});
    const [touchedErrors, setTouchedErrors] = useState([]);
    const [questionState, setQuestionState] = useState("Ready");
    let isAlmostThereMultiSelect = false;

    const onCheckboxChange = (id) => {
        if(questionState === "Ready")
        {
            setIsChecked((state) => {
                if(isMutuallyExclusive)
                {
                    return [id];
                }

                if (state.includes(id)) {
                    // Remove id from array
                    return state.filter(item => item !== id);
                } else {
                    // Add id to array
                    return [...state, id];
                }
            })
        }
    }

    const submitEnabled = isChecked.length > 0

    const getCorrectAnswersKey = () => {
        let results = {};
        Object.entries(questionConfig.answers).forEach(([answerKey, answerValue]) => {
            const isSelected = isChecked.includes(answerKey);
            const noTip = !answerValue.tip || answerValue.tip === ""
            const isCorrect = isMutuallyExclusive 
                ? isSelected ? questionConfig.solution === answerKey : questionConfig.solution !== answerKey
                : isSelected ? questionConfig.solution.includes(answerKey) : !questionConfig.solution.includes(answerKey);
    
            if (isSelected && isCorrect) {
                results[answerKey] = "correctSelection";
            } else if (isSelected && !isCorrect && noTip) {
                results[answerKey] = "incorrectSelectionNoTip";
            } else if (isSelected && !isCorrect) {
                results[answerKey] = "incorrectSelection";
            } else if (!isSelected && isCorrect) {
                results[answerKey] = "correctNonselection";
            } else {
                results[answerKey] = "incorrectNonselection";
            }
        });
    
        return results;
    }

    const getQuizResults = () => {
        let correctAnswers = [];
        let incorrectAnswers = [];
        let isCorrect = true;
    
        if (isMutuallyExclusive) {
            // If solution is a string, compare directly
            if (isChecked[0] === questionConfig.solution) {
                correctAnswers.push(isChecked[0]);
            } else {
                isCorrect = false;
                incorrectAnswers.push(isChecked[0]);
            }
        } else {
            Object.keys(questionConfig.answers).forEach(answerKey => {
                const isSelected = isChecked.includes(answerKey);
                const answerIsCorrect = isMutuallyExclusive 
                    ? isSelected ? questionConfig.solution === answerKey : questionConfig.solution !== answerKey
                    : isSelected ? questionConfig.solution.includes(answerKey) : !questionConfig.solution.includes(answerKey);
        
                    if (answerIsCorrect) {
                        correctAnswers.push(answerKey);
                    } else {
                        isCorrect = false;
                        incorrectAnswers.push(answerKey);
                    }
            });
        }

        const results = getCorrectAnswersKey();
        return { results, correctAnswers, incorrectAnswers, isCorrect };
    }

    useImperativeHandle(ref, () => ({
        validateQuestion: () => {
            const results = getQuizResults();
            setQuizResults(results)
            const {isCorrect} = results;
            if (isCorrect) {
                setQuestionState("Done");
                onQuestionStateChange("Done");
            } else {
                if (isMultiSelect) {
                    let resultValues = Object.values(results.results);
                    const incorrectSelections = resultValues.includes("incorrectSelection")
                    const correctSelections = resultValues.includes("correctSelection")
                    
                    if (incorrectSelections && correctSelections) {
                        isAlmostThereMultiSelect = true;
                    }
                }
                setQuestionState("Failed");
                onQuestionStateChange("Failed", isAlmostThereMultiSelect);
            }
        },
        reset: () => {
            setQuizResults({})
            setQuestionState("Ready")
            setIsChecked([])
            setTouchedErrors([])
            isAlmostThereMultiSelect = false;
        }
      }));

      

    useEffect(() => {
        if(onReadyToSubmitChange)
        {
            onReadyToSubmitChange(submitEnabled);
        }
    }, [submitEnabled]);

    useEffect(() => {
        if(questionState === "Failed" && quizResults?.results && touchedErrors.length === Object.values(quizResults.results).filter(r => r === "incorrectSelection").length)
        {
            setQuestionState("ReadyForRetry");
            onQuestionStateChange("ReadyForRetry");
        }
    }, [touchedErrors, quizResults, questionState]);

    const handleSetTouched = (answerConfigKey) => () => {
        setTouchedErrors((s) => !s.includes(answerConfigKey) ? [...s, answerConfigKey] : s)
    }

    const getCheckboxStateForId = (id) => {
        if (!quizResults.results || !quizResults.results.hasOwnProperty(id)) {
            return "default";
        }
        return quizResults.results[id]
    }

    const showLightBulbTip = (data) => {
        if (Object.keys(data).length !== 0) {
            let resultValues = Object.values(data.results);
            const incorrectSelections = resultValues.includes("incorrectSelection")

            if (incorrectSelections) {
                return true;
            }
        }
    }

    return (
        <QuizMultipleChoiceContainer>
            {questionConfig.image && 
                <QuestionContainer>
                    <QuestionCopy>
                        {questionConfig.question}
                    </QuestionCopy>
                </QuestionContainer>
            }
            <QuizContainer>
                {!questionConfig.image && 
                    <QuestionContainer>
                        <QuestionCopy>
                            {questionConfig.question}
                        </QuestionCopy>
                    </QuestionContainer>
                }
                {questionConfig.image && 
                    <ImageContainer>
                        <StyledImage src={`${assetServer}/${questionConfig.image}`} />
                    </ImageContainer>
                }
                <AnswerContainer>
                    {showLightBulbTip(quizResults) ? <StyledErrorMessageContainer><StyledErrorMessageText>Each light bulb is a learning opportunity. Make sure you click on all of them before trying again.</StyledErrorMessageText></StyledErrorMessageContainer> : null}
                    <AnswerList>
                        {Object.entries(questionConfig.answers).map(
                            ([answerConfigKey, answerConfig]) =>
                                <Checkbox
                                    setTouched={handleSetTouched(answerConfigKey)}
                                    key={questionId+answerConfigKey}
                                    {...answerConfig}
                                    id={answerConfigKey}
                                    state={getCheckboxStateForId(answerConfigKey)}
                                    onChange={onCheckboxChange}
                                    isChecked={isChecked.includes(answerConfigKey)} 
                                    isMultiSelect={isMultiSelect}
                                />
                        )}
                    </AnswerList>
                </AnswerContainer>
            </QuizContainer>
        </QuizMultipleChoiceContainer>
    );
  })

  export default QuizMultipleChoice