import { useDraggable } from '@dnd-kit/core';
import styled from "styled-components/macro";

const AnswerTextBlock = styled.div`
    display: flex;
    box-sizing: border-box;
    ${({ isDropped, theme: { colors } }) => isDropped ? `
      width: 174px;
      height: 50px;
      background: none;
      margin: 0 0;
      font-size:  18px;
      line-height: 22px;
      font-family: Poppins;
      position: relative;
      
    ` : `
      width: 174px;
      height: 50px;
      background: var(--white, #FFF);
      margin: 20px 10px;
      border: 1.397px solid ${colors.Grey60};
      font-size:  18px;
      line-height: 22px;
      font-family: Poppins;
      cursor: pointer;
      
    `};
    border-radius: 8.381px;
    padding: 0 10px;
    text-align:center;
    justify-content: center;
    align-items: center;
    touch-action: none;
    border: 1px solid rgba(0,0,0,0);
    :hover {
      border-radius: 6px;
      border: 1px solid var(--eg-teal, #007280);
      background: var(--eg-medium-turquoise, #5CD4E1);
    }

  ${({ dragging, theme: { colors } }) => dragging ? `
      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    ` : ``
    }

    @media (max-width: 480px) {
        width: 125px;
        height: 36px;
        box-sizing: border-box;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 145.455% */
        letter-spacing: 0.5px;
        ${({ isDropped, theme: { colors } }) => isDropped ? `
          width: 114px;
          height: 28px;
        ` : `
          margin: 15px 5px;
        `};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 125px;
        height: 36px;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 145.455% */
        letter-spacing: 0.5px;
        box-sizing: border-box;
        ${({ isDropped, theme: { colors } }) => isDropped ? `
          width: 152px;
          height: 52px;
          padding: 0;
        ` : `
          margin: 15px 5px;
        `};
    }
`

export default function DraggableAnswerTextBlock({id, children, isDropped, ...props}) {
    const {isDragging, setNodeRef, listeners, transform, attributes} = useDraggable({
      id
    });

    let style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      } : undefined;

    if (isDropped && props.isPhotosynthesisImageQuestion) {
      style = {
        background: "white"
      }
    }
  
    return (
      <AnswerTextBlock
        dragging={isDragging}
        ref={setNodeRef}
        style={style}
        isDropped={isDropped}
        {...listeners}
        {...attributes}
      >
        {children}
        </AnswerTextBlock>
    );
  }