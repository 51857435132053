import { React } from 'react';
import styled from "styled-components/macro";
import TopicMainHeader from "../../components/TopicMainHeader";
import {WaterBottleSVG, EqualSignSVG, HeartSVG} from "../../assets";

const StyledInteractiveQuizLaunchContainer = styled.div`
    background-color: ${({ theme: { colors } }) => colors.White};
    width: auto;
    height: auto;
    padding: 80px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        padding: 25px 15px;
    }
`;

const StyledTitle = styled.p`
    color: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.medium.lineHeight};
    text-align: center;
    margin: 10px 0;

   @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.display.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.display.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.display.small.lineHeight};
    }
`;

const StyledTextContent = styled.p`
    color: ${({ theme: { colors } }) => colors.Grey80};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.medium.lineHeight};
    text-align: center;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
    }
`;

const StyledQuizAttemptMessage = styled.p`
    color: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.small.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.small.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.small.lineHeight};
    text-align: center;
    margin: 20px 0 10px 0;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.large.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.large.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.large.lineHeight};
    }
`;

const StyledQuizAttemptsSpan = styled.span`
    color: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.small.fontSize};
    font-weight: 700;
    line-height: ${({ theme: { typography } }) => typography.display.small.lineHeight};
    margin-right: 5px;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.large.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.large.fontWeight};
        line-height: ${({ theme: { typography } }) => typography.headline.large.lineHeight};
    }
`;

const StyledIconContainer = styled.div`
    width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 20px 0;
    align-items: center;
`;

const StyledWaterBottleContainer = styled.div`
    width: 45px;
    height: 52px;

    svg {
        width: 45px;
        height: 52px;
    }
`;

const StyledEqualSignContainer = styled.div`
    width: 67px;
    height: 96px;
    margin: 0 20px;

    svg {
        width: 67px;
        height: 96px;
    }
`;

const StyledHeartContainer = styled.div`
    width: 67px;
    height: 96px;

    svg {
        width: 67px;
        height: 96px;
    }
`;

const getQuizLaunchTitle = (userStatus) => {
    let quizLaunchTitle = "";

    {userStatus === "first" && (
        quizLaunchTitle = "Ready to learn and earn water droplets?"
    )}

    {userStatus === "resume" && (
        quizLaunchTitle = "Resume your adventure!"
    )}

    {userStatus === "retake" && (
        quizLaunchTitle = "Ready for another round?"
    )}

    return quizLaunchTitle;
};

const getQuizLaunchContent = (userStatus) => {
    let quizLaunchText = "";

    {userStatus === "first" && (
        quizLaunchText = "Every right answer on your quiz fills your water canteen! The more droplets you earn, the more you can power-up in your games."
    )}

    {userStatus === "resume" && (
        quizLaunchText = "Dive back into the quiz and earn water droplets for extra lives in the game."
    )}

    {userStatus === "retake" && (
        quizLaunchText = "By retaking the quiz, you have the chance to earn even more water droplets! These droplets can be spent on extra lives in the game."
    )}

    return quizLaunchText;
};

function InteractiveQuizLaunch(props) {
    const {userStatus, courseTitle, lessonTitle, heroImage, imageCredit, imageCreditStyle, courseId} = props;

    return (
        <>
            <TopicMainHeader
                isCondensed={true}
                lessonTitle={lessonTitle}
                heroImage={heroImage}
                imageCredit={imageCredit}
                imageCreditStyle={imageCreditStyle}
                isQuizView={true}
                courseId={courseId}
            />
            <StyledInteractiveQuizLaunchContainer>
                <StyledTitle>{getQuizLaunchTitle(userStatus)}</StyledTitle>
                <StyledIconContainer>
                    <StyledWaterBottleContainer>
                        <WaterBottleSVG />
                    </StyledWaterBottleContainer>
                    <StyledEqualSignContainer>
                        <EqualSignSVG />
                    </StyledEqualSignContainer>
                    <StyledHeartContainer>
                        <HeartSVG />
                    </StyledHeartContainer>
                </StyledIconContainer>
                <StyledTextContent>{getQuizLaunchContent(userStatus)}</StyledTextContent>
                <StyledTextContent>The more you learn and earn, the healthier our digital Everglades becomes!</StyledTextContent>
            </StyledInteractiveQuizLaunchContainer> 
        </>
    );
}

export default InteractiveQuizLaunch;