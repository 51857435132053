import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserData from "../UseUserData";

const useLoadUserOrRedirect = () => {
    const navigate = useNavigate();
    const {userLoggedIn, userInitialized, userId, userHasSeenSplashPage} = useUserData();

    useEffect(() => {
        if (!userLoggedIn && userInitialized) {
            navigate("/login");
        }
    }, [userId, userInitialized, userLoggedIn, navigate])

    return {userLoggedIn, userId, userInitialized, userHasSeenSplashPage}
};

export default useLoadUserOrRedirect;