import { useCallback, useEffect, useRef } from "react";
import useStore from "../../Store";
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || `https://${window.location.hostname}`;


const useUserData = () => {
    const {
      userId,
      userLoggedIn,
      userInitialized,
      completedCourses,
      completedLessons,
      dropletCount,
      completedAcheivements,
      badgesEarned,
      userInvalid,
      userHasSeenSplashPage,
      userData: {
        completedQuizes,
        partiallyCompletedQuizes
      },
      avatarSvg
    } = useStore(({
      userId,
      userLoggedIn,
      userInitialized,
      completedCourses,
      completedLessons,
      dropletCount,
      completedAcheivements,
      badgesEarned,
      userInvalid,
      userHasSeenSplashPage,
      userData: {
        completedQuizes,
        partiallyCompletedQuizes
      },
      userData
    }) => {
      return {
      userId,
      userLoggedIn,
      userInitialized,
      completedCourses,
      completedLessons,
      dropletCount,
      completedAcheivements,
      badgesEarned,
      userInvalid,
      userHasSeenSplashPage,
      userData: {
        completedQuizes,
        partiallyCompletedQuizes
      },
      avatarSvg: userData.avatarSvg
    }});

    const prevUserId = useRef(userId);

    const setUserIdState = useStore().setUserId;
    const setUserLoggedIn = useStore().setUserLoggedIn;
    const setUserInitialized = useStore().setUserInitialized;
    const setProgressData = useStore().setProgressData;
    const setUserInvalid = useStore().setUserInvalid;
    const setUserHasSeenSplashPage = useStore().setUserHasSeenSplashPage;

    const setUserId = (id) => {
      setUserIdState(id)
    }

    const resetUser = (id) => {
      setUserLoggedIn(false)
      setUserInitialized(false)
      setUserId(id)
    }

    const checkValidCode = async (code) => {
      const result = await fetch(`${apiEndpoint}/api/Users/${code}`)
      if(!result.ok)
      {
        return false
      }

      return true
    }

    const fetchData = async (userId) => {
      if(userLoggedIn)
      {
        return
      }

      if(!userId)
      {
        setUserInitialized(true)
        return
      }

      const result = await fetch(`${apiEndpoint}/api/Users/${userId}`)
      if(!result.ok)
      {
        setUserInvalid(true)
        setUserLoggedIn(false)
        setUserInitialized(true)
        setUserId(null)
        setProgressData({
          completedCourses: completedCourses || [],
          completedLessons: completedLessons || [],
          completedAcheivements: completedAcheivements || [],
          badgesEarned: badgesEarned || [],
          dropletCount,
          userAvatar,
          userData: {
            completedQuizes: completedQuizes || [],
            partiallyCompletedQuizes: partiallyCompletedQuizes || []
          },
        })
        
        return
      }

      const {
        user: {
          completedCourses,
          completedAcheivements,
          badgesEarned,
          completedQuizes,
          partiallyCompletedQuizes,
          completedLessons,
          dropletCount,
          userAvatar
        }} = await result.json()
  
      setUserLoggedIn(true)
      setUserInitialized(true)
      setUserInvalid(false)
      setProgressData({
        completedCourses: completedCourses || [],
        completedLessons: completedLessons || [],
        completedAcheivements: completedAcheivements || [],
        badgesEarned: badgesEarned || [],
        dropletCount,
        userAvatar,
        userData: {
          completedQuizes: completedQuizes || [],
          partiallyCompletedQuizes: partiallyCompletedQuizes || []
        },
      })
    }

    useEffect(() => {
      if(prevUserId.current !== userId && prevUserId.current !== null)
      {
        setUserHasSeenSplashPage(false)
      }
      fetchData(userId)
      // make sure to catch any error
      .catch(console.error);
    }, [ userId ])

    const initUser = (id) => {
      setUserLoggedIn(false)
      fetch(`${apiEndpoint}/api/Users/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        resetUser(id)
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
    }

    // UPDATE user
    useEffect(() => {
      if(userLoggedIn)
      {
        fetch(`${apiEndpoint}/api/Users/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: userId,
            completedCourses: completedCourses || [],
            completedLessons: completedLessons || [],
            completedAcheivements: completedAcheivements || [],
            badgesEarned: badgesEarned || [],
            userAvatar: avatarSvg,
            dropletCount: dropletCount,
            completedQuizes: completedQuizes || [],
            partiallyCompletedQuizes: partiallyCompletedQuizes || []
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
        });
      }
    }, [
      userLoggedIn,
      completedCourses,
      completedLessons,
      completedAcheivements,
      badgesEarned,
      completedQuizes,
      partiallyCompletedQuizes,
      avatarSvg,
      userId,
    ])

    return {
      initUser,
      resetUser,
      setUserId,
      userInitialized,
      userId,
      userLoggedIn,
      userInvalid,
      userHasSeenSplashPage,
      checkValidCode
    }
};

export default useUserData;