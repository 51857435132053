import { React } from 'react';
import styled from "styled-components/macro";
import useOutsideClick from "../../shared/Using/UseOutsideClick";
import useStore from "../../shared/Store";
import { useCallback } from "react";
import { motion } from 'framer-motion';
import { CloseButtonSVG, QuizModalCloseButtonSVG } from "../../assets";
import ButtonWithText from "../ButtonWithText";
import InteractiveProgressBar from "../InteractiveProgressBar";

const containerTransitionAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
        duration: 0.1
    }      
  };

const ModalContainer = styled(motion.div)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

const ModalBackdrop = styled(motion.div)`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    opacity: 0.6499999761581421;
    z-index: 100;
`;

const ModalContent = styled.div`
    display: flex;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: space-between;
    width: 90%;
    height: auto;
    border-radius: 10px;
    flex-direction: column;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
`;

const ModalContentAnimationContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000;
`;

const ModalHeaderContainer = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
`;

const ModalFooterContainer = styled.div`
    width: auto;
    height: auto;
    position: sticky;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        justify-content: center;
    }
`;

const ModalMainContentContainer = styled.div`
    width: auto;
    height: 90vh;
    min-height: 250px;
    max-height: 800px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    color: ${({ theme: { colors } }) => colors.Grey80};
    overflow: hidden scroll;

    @media (max-width: 768px) {
        min-height: 250px;
        max-height: 625px;
    }
`;

const HeaderTitle = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    letter: ${({ theme: { typography } }) => typography.display.medium.letter};
    color: ${({ theme: { colors } }) => colors.PureBlack};
    margin: 10px 0;
    padding: 0 70px 0 15px;

    @media (max-width: 768px) {
        margin: 30px 0 10px;
        font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
        letter: ${({ theme: { typography } }) => typography.headline.medium.letter};
    }
`;

const HeaderText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    color: ${({ theme: { colors } }) => colors.Grey80};
    margin: 10px 0;
    padding: 0 70px 0 15px;

    @media (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        letter: ${({ theme: { typography } }) => typography.body.medium.letter};
    }
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1000;

  
  ${({ closeButtonStyle }) => closeButtonStyle === "Inner" ? `
    top: 24px;
    right: 14px;
  `: `
    top: 0px;
    right: 0px;
    transform: translate(50%, -50%);
  `};

  svg {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 768px) {
        width: 40px;
        height: 40px;

        svg {
            width: 40px;
            height: 40px;
        }
    }
`

function ModalPopUp({children, closeOnClickOutside, closeButtonClick, doIntroAnimation, closeButtonStyle = "Inner", hideCloseButton = false, tag}) {
    const hideModal = useStore().hideModal;
    const handleClickOutside = useCallback(() => {
        if(closeOnClickOutside) {
            hideModal(tag)
        }
    }, [hideModal, closeOnClickOutside]);
    const outsideClickRef = useOutsideClick(handleClickOutside);

    const containerTransitionAnimation = doIntroAnimation === false ? {
        initial: { opacity: 1 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: {
            duration: 0.1
        }      
      } : {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: {
            duration: 0.1
        }      
      };

    const contentTransitionAnimation = doIntroAnimation === false ? {
        initial: { 
            y: 0
        },
        animate: {
            y: 0,
            transition: {
                duration: 0.2,
                type: "spring",
                damping: 25,
                stiffness: 500,
              },
        },
        exit: {
            //fix a bug where if there is no actual difference between the current and exit state,
            //the parent compoenent in AnimatePresence will not unmount
            y: -1 
        }  
    } : {
        initial: { 
            y: -50
        },
        animate: {
            y: 0,
            transition: {
                duration: 0.2,
                type: "spring",
                damping: 25,
                stiffness: 500,
              },
        },
        exit: {
            //fix a bug where if there is no actual difference between the current and exit state,
            //the parent compoenent in AnimatePresence will not unmount
            y: -1 
        }  
    };

    return (
        <ModalContainer  {...containerTransitionAnimation}>
            <ModalBackdrop />
            <ModalContentAnimationContainer  {...contentTransitionAnimation}>
                <ModalContent  ref={outsideClickRef}>
                    {
                        hideCloseButton === false &&
                        <CloseButton closeButtonStyle={closeButtonStyle} onClick={closeButtonClick}>
                            {closeButtonStyle === "Inner" ? <CloseButtonSVG sx={{ fontSize: 58 }}  /> : <QuizModalCloseButtonSVG />}
                        </CloseButton>
                    }
                    <ModalMainContentContainer>
                        {children}
                    </ModalMainContentContainer>
                </ModalContent>
            </ModalContentAnimationContainer>
        </ModalContainer>
    )
}

export default ModalPopUp;