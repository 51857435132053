import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from 'framer-motion';
import styled from "styled-components/macro";
import useStore from "../../shared/Store";
import MapProgressIndicator from "../../components/MapProgressIndicator";
import PageContainer from "../../components/PageContainer";
import HeaderNavBar from "../../components/HeaderNavBar";
import MapContainer from "../../components/MapContainer";
import CourseData from "../../json/courseData";
import useLoadUserOrRedirect from "../../shared/Using/UseLoadUserOrRedirect";
import SplashPageContainer from "../../components/SplashPageContainer";
import SplashPageData from "../../assets/splashpageassets/splashPage.json";
import { AlligatorSVG, FishSVG, FPLSponsorLogoSVG, ManateeSVG, EvergladesEcoQuestSVG } from "../../assets/splashpageassets";


const RelativePositionElement = styled.div`
    position: absolute;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    user-select: none;
    appearance: none;

    ${({ $xPos, $yPos }) => {
      return `
        left: ${$xPos}%;
        top: ${$yPos}%;
      `
    }};

    @media (max-width: 948px) {
      left: 50%;
      transform: translate(-50%, -53%);
    }
`;

const RelativePositionSplashElement = styled.div`
    position: absolute;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    user-select: none;
    appearance: none;

    &.EvergladesEcoQuest {
      width: 63%;
      left: 50%;
      top: 57%;
    }

    &.Fish {
      z-index: 1000;
    }

    ${({ $xPos, $yPos }) => {
      return `
        left: ${$xPos}%;
        top: ${$yPos}%;
      `
    }};
`;

const MapProgressOriginContainer = styled.div`
  transform: translate(-13%, -53%);
  @media (max-width: 948px) {
      transform: translate(0, -53%);
  }
`

const AnimatedObjectOriginContainer = styled.div`
  transform: translate(-13%, -53%);

  &.EvergladesEcoQuest {
    animation: fadeIn 5s;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  &.Manatee {
    animation: 3s slide-left, bobbing 2s infinite ease-in-out;
    animation-delay: 0s, 5s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    box-sizing: border-box;

    @keyframes bobbing {
      0% { transform: translate(-50%, -50%); }
      50% { transform: translate(-50%, -60%); }
      100% { transform: translate(-50%, -50%); }
    }

  }

  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }

  &.Alligator {
    animation: 3s slide-right, bobbing 2s infinite ease-in-out;
    animation-delay: 0s, 5s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    box-sizing: border-box;

    @keyframes bobbing {
      0% { transform: translate(-50%, -50%); }
      50% { transform: translate(-50%, -60%); }
      100% { transform: translate(-50%, -50%); }
    }

  }

  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }

  &.Fish {
    animation: 3s fish-slide-right;
    animation-delay: 0s, 1s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 46%;
    box-sizing: border-box;
  }

  @keyframes fish-slide-right {
    from {
      margin-left: -600%;
    }
    to {
      margin-left: 0%;
    }
  }

  &.FPLSponsorLogo {
    animation: fadeIn 12s;
    animation-delay: 0s, 20s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 26%;
    box-sizing: border-box;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    20% { opacity: 0; }
    60% { opacity: 0.8; }
    80% { opacity: 0.9; }
    100% { opacity: 1; }
  }

  @media (max-width: 948px) {
      transform: translate(0, -53%);
  }
`


const animationStyles = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const LandingPage = () => {
    const {userHasSeenSplashPage} = useLoadUserOrRedirect()
    const [pageState, setPageState] = useState("");
    const setUserHasSeenSplashPage = useStore().setUserHasSeenSplashPage

    useEffect(() => {
      if (!userHasSeenSplashPage) {
          setPageState("SplashPage")
      }
    }, [userHasSeenSplashPage])

    const getIconSvg = (svgString) => {
      switch (svgString) {
          case 'AlligatorSVG':
              return AlligatorSVG
          case 'FishSVG':
            return FishSVG
          case 'FPLSponsorLogoSVG':
            return FPLSponsorLogoSVG
          case 'ManateeSVG':
            return ManateeSVG
          case 'EvergladesEcoQuestSVG':
            return EvergladesEcoQuestSVG
      }
    }
    
    const { completedLessons, completedCourses } = useStore(
      ({ completedLessons, completedCourses }) => ({ completedLessons, completedCourses })
    )

    useEffect(() => {
      if(pageState == "SplashPage")
      {
        setTimeout(() => {
          setPageState("")
        }, "9000");
        setUserHasSeenSplashPage(true)
      }

    }, [pageState, setUserHasSeenSplashPage])

    return (
      <motion.div {...animationStyles}>
        <PageContainer>
          {pageState === "SplashPage" &&
            <SplashPageContainer>
              {SplashPageData.map((item) => {
                const IconSvg = getIconSvg(item.iconSVG);
                return (
                <RelativePositionSplashElement key={item.iconId} style={{width:`${item.width}`}} className={`${item.iconId}`} $xPos={item.splashPagePosition.x} $yPos={item.splashPagePosition.y}>
                  <AnimatedObjectOriginContainer className={`${item.iconId}`}>
                    <IconSvg style={{width:`100%`, height:`auto`}} />
                  </AnimatedObjectOriginContainer>
                </RelativePositionSplashElement>
              )})}
            </SplashPageContainer>
          }
          {pageState !== "SplashPage" &&
          <>
            <HeaderNavBar />
            <MapContainer>
              {CourseData.map((course) => {
                return (
                <RelativePositionElement key={course.courseId} $xPos={course.mapPosition.x} $yPos={course.mapPosition.y}>
                  <MapProgressOriginContainer>
                    <MapProgressIndicator {...{
                      mapProgressStatus : {
                        status: {
                          locked: !course.prerequisistes.every(prereq => completedCourses.includes(prereq)),
                          complete:  completedCourses.includes(course.courseId)
                        },
                        course: course.courseId,
                        lessons: course.lessons,
                        completedTopic: course.lessons.filter(l => completedLessons.includes(l.lessonId)).length,
                        courseView: true,
                        courseTitle: course.courseTitle,
                        gameTitle: `Course ${course.courseId} Game`,
                        to: `course/${course.courseId}/lessonSelect`,
                        mapProgressIcon: course.mapProgressIcon
                      }
                    }} 
                    />
                  </MapProgressOriginContainer>
                </RelativePositionElement>
              )})}
            </MapContainer>
          </>
        }
        </PageContainer>
        </motion.div>
    )
  }

  export default LandingPage;