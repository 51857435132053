import { 
    LockIconSVG,
 } from ".";

 import { 
    FishingRodSVG,
    WaterCupSVG,
    Engineering2SVG,
    Boots2SVG,
    Layer_2SVG,
    EcologistSVG,
    Layer_1SVG,
    Frame14426SVG,
    EarthOnShieldSVG,
    AllligatorHoleIconSVG,
    SnakeSVG,
    Frame14428SVG
 } from "./badges";

export const badges = [
    {svg: LockIconSVG, label: "LockIconSVG"},
    {svg: WaterCupSVG, label: "WaterCupSVG"},
    {svg: FishingRodSVG, label: "FishingRodSVG"},
    {svg: Engineering2SVG, label: "Engineering2SVG"},
    {svg: Boots2SVG, label: "Boots2SVG"},
    {svg: Layer_2SVG, label: "Layer_2SVG"},
    {svg: EcologistSVG, label: "EcologistSVG"},
    {svg: Layer_1SVG, label: "Layer_1SVG"},
    {svg: Frame14426SVG, label: "Frame14426SVG"},
    {svg: EarthOnShieldSVG, label: "EarthOnShieldSVG"},
    {svg: AllligatorHoleIconSVG, label: "AllligatorHoleIconSVG"},
    {svg: SnakeSVG, label: "SnakeSVG"},
    {svg: Frame14428SVG, label: "Frame14428SVG"},
]

export const getbadgeSVG = (badgeLabel) => badges.find(i => i.label === badgeLabel)?.svg || LockIconSVG