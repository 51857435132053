import { useDraggable } from '@dnd-kit/core';
import styled from "styled-components/macro";

const AnswerBlock = styled.div`
    width: 100px;
    height: 120px;
    border-radius: 17.458px;
    border: 1.397px solid ${({ theme: { colors } }) => colors.Grey20};
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({ isDropped, theme: { colors } }) => isDropped ? 0 : 10}px;
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.body.medium.lineHeight};
    letter: ${({ theme: { typography } }) => typography.body.medium.letter};
    color: ${({ theme: { colors } }) => colors.Black};
    text-align: center;
    flex-direction: column;
    overflow: hidden;
    :hover {
      border: 1px solid var(--eg-teal, #007280);
      background: var(--eg-medium-turquoise, #5CD4E1);
    }

    @media (max-width: 480px) {
      width: 60px;  
      height: 80px;
      font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
      font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
      line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
      letter: ${({ theme: { typography } }) => typography.body.small.letter};
    }

    @media (min-width: 481px) and (max-width: 768px) {
      width: 60px;  
      height: 80px;
      font-size: ${({ theme: { typography } }) => typography.body.small.fontSize};
      font-weight: ${({ theme: { typography } }) => typography.body.small.fontWeight};
      line-height: ${({ theme: { typography } }) => typography.body.small.lineHeight};
      letter: ${({ theme: { typography } }) => typography.body.small.letter};
    }
`

export default function DraggableAnswerBlock({id, children, isDropped, ...props}) {
    const {isDragging, setNodeRef, listeners, transform, attributes} = useDraggable({
      id
    });

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      } : undefined;
  
    return (
      <AnswerBlock
        dragging={isDragging}
        ref={setNodeRef}
        style={style}
        isDropped={isDropped}
        {...listeners}
        {...attributes}
      >
        {children}
        </AnswerBlock>
    );
  }