import styled from "styled-components/macro";
import ModalPopUp from "../ModalPopUp";
import {BadgeSVG} from "../../assets/index";
import BadgeCarousel from "./BadgeCarousel";
import useStore from "../../shared/Store";
import { useCallback } from "react";

const BadgesDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin: 44px 0;
  justify-content: center;
  width: 53%;
  height: 85%;
`;

const StyledBadgeContainer = styled.div`
    padding: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 626px;
    width: 45%;

    @media (max-width: 575px) {
        padding: 50px 20px;
    }
`
const BadgeModalPopupContent = styled.div`
    display: flex;
    align-items: center;
    background: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: center;
    flex-direction: row;
`

const Vector = styled.div`
    height: 683.5px;
    flex-shrink: 0;
    border-left: 6px solid black;
    
    @media (max-width: 768px) {
        display: none;
    }
`
const StyledBadgeIconContainer = styled.div`
    border-radius: 50%;
    width: 309px;
    height: 309px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme: { colors } }) => colors.EGMediumTurquoise};

    svg {
        width: 227px;
        height: 227px;
    }

    @media (max-width: 480px) {
        height: 180px;
        width: 180px;

        svg {
            height: 140px;
            width: 140px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 239px;
        width: 239px;

        svg {
            height: 176px;
            width: 176px;
        }
    }
`
const BadgeSummary = styled.div`
    max-width: 1265px;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 30px 0;
    ${({ theme }) => theme.EGHeadlineLargest};

    @media (max-width: 480px) {
        ${({ theme }) => theme.EGHeadtitleLarge};
        margin: 20px 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        ${({ theme }) => theme.EGHeadlineMedium};
        margin: 20px 0;
    }
`
const BadgeDescription = styled.div`
    max-width: 1145px;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media (max-width: 480px) {
        ${({ theme }) => theme.EGBodySmall};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        ${({ theme }) => theme.EGBodyMedium};
    }
`
const StyledBadgesCounter = styled.p`
    position: absolute;
    font-family: Poppins, sans-serif;
    font-size: 76px;
    font-weight: 700;
    line-height: 70px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    width: 227px;
    height: 70px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.White};
    transform: translate(0px, -31%);

    @media (max-width: 480px) {
        font-size: 45px;
        line-height: 50px;
        transform: translate(0px, -6%);
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: 55px;
        line-height: 65px;
        transform: translate(0px, -21%);
    }
`;

export default function BadgeModalPopup({
    tag
}) {
    const badgesEarned = useStore(s => s.badgesEarned);
    const hideModal = useStore().hideModal;
    const closeButtonClick = useCallback(() => {
            hideModal(tag)
    }, [hideModal]);

  return (
    <ModalPopUp closeButtonStyle="Inner" closeButtonClick={closeButtonClick} closeOnClickOutside tag={tag}>
        <BadgeModalPopupContent>
            <StyledBadgeContainer>
                <StyledBadgeIconContainer><BadgeSVG/><StyledBadgesCounter>{badgesEarned.length > 0 ? badgesEarned.length : 0}</StyledBadgesCounter></StyledBadgeIconContainer>
                <BadgeSummary>Hello Everglades Adventurer!</BadgeSummary>
                <BadgeDescription>Dive deep into each lesson and discover the secrets of the Everglades! As you flow through each lesson, you are journeying down the river of knowledge. With every lesson you master, you uncover a new badge! </BadgeDescription>
            </StyledBadgeContainer>
            <Vector/>
            <BadgesDisplayContainer>
                <BadgeCarousel />
            </BadgesDisplayContainer>
        </BadgeModalPopupContent>
    </ModalPopUp>
  )
}