import { React, useState, useRef } from 'react';
import styled from "styled-components/macro";
import ButtonWithText from "../ButtonWithText";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CloseButtonSVG } from "../../assets";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const StyledDialogContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px 85px 70px 85px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 53px;
  right: 53px;
  transform: translate(50%, -50%);

  svg {
    width: 50px;
    height: 50px;

    path {
      fill: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    }
  }

  @media (max-width: 768px) {
        width: 40px;
        height: 40px;

        svg {
            width: 40px;
            height: 40px;
        }
    }
`

const StyledAlertHeading = styled.h1`
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.display.medium.lineHeight};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    margin: 20px 0;
`;

const StyledButton = styled(ButtonWithText)`
  width: 250px;
`;


function ExitWarningDialog(props) {
  const {open, onClose, onLeave} = props;
  const theme = useTheme();
  const [maxWidth, setMaxWidth] = useState("md");

  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogContentContainer>
          <CloseButton onClick={onClose}>
                <CloseButtonSVG sx={{ fontSize: 55 }} />
          </CloseButton>
          <StyledAlertHeading id="alert-dialog-title">
            {"Are you sure you want to leave?"}
          </StyledAlertHeading>
          <DialogContent></DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }} >
            <StyledButton buttonText="Cancel" handleOnClick={onClose} buttonState="secondary"/>
            <StyledButton buttonText="Leave" handleOnClick={onLeave} buttonState="primary-alert" />
          </DialogActions>
        </StyledDialogContentContainer>
      </Dialog>
    </div>
  );
}

export default ExitWarningDialog;