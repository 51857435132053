import { React } from 'react';
import {LockIconSVG, HabitatTreeSVG, HabitatWaterSVG, HabitatPlantSVG, WaterShedSVG, QuizIconSVG, WaterShedLessonSVG, WaterShedDamSVG, WaterShedHandsWorldSVG, FishQuestSVG, LivingInTheEvergladesSVG, PlantsOfTheEvergladesSVG, AnimalsOfTheEvergladesSVG, AlligatorSVG, FoodChainSVG, FoodChainWebSVG, FishSVG, FishSkeletonSVG, ManateeQuestSVG, GatorQuestionSVG, GatorQuestSVG, BearSVG} from "../../assets/index";  
import styled from "styled-components/macro";

import { Link } from 'react-router-dom';

const StyledCourseCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    align-self: flex-start;

    svg {
        width: 70px;
        height: auto;
    }
`;

const StyledCardButton = styled(Link)`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 5px;
    width: 150px;
    height: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: ${({ $isLocked }) => {
        if ($isLocked) {
            return `default;`
        } else {
            return `pointer;`
        }
    }};
    user-select: none;
    appearance: none;
    position: relative;
    flex-direction: column;
    padding: 0;
    margin: 15px;
    user-select: none;
    appearance: none;
    text-decoration: none;

    @media (max-width: 768px) {
        width: auto;
        margin: 15px 5px;
    }
`;

const StyledIconContainer = styled.span`
    align-items: center;
    background-color: ${({ theme: { colors } }) => colors.LightGrey};
    border: ${({ theme: { colors } }) => `solid ${colors.EGDarkSlatteGrey} 3px`};
    border-radius: 50%;
    display: flex;
    height: 90px;
    justify-content: center;
    min-height: 100px;
    min-width: 100px;
    width: 90px;
    z-index: 100;
    position: relative;

    svg {
        height: 65px;
        width: 65px;
    }
`;

const StyledCardContainer = styled.div`
    width: 140px;
    height: auto;
    min-height: 107px;
    border-radius: 7px;
    background-color: ${({ theme: { colors } }) => colors.White};
    border: ${({ theme: { colors } }) => `solid ${colors.EGDarkSlatteGrey} 2px`};
    position: relative;
    padding-top: 57px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: -50px;
    justify-content: space-between;
`;

const StyledRule = styled.hr`
    width: 50px;
    border: 3px solid ${({ color, theme: { colors } }) => color || colors.Grey40};
    border-radius: 3px;
    margin: 0 auto;
`;

const StyledCourseTitle = styled.p`
    color: ${({ theme: { colors } }) => colors.Black};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.courseCardTitleText.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.courseCardTitleText.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.courseCardTitleText.lineHeight};
    letter: ${({ theme: { typography } }) => typography.courseCardTitleText.letter};
    text-transform: uppercase;
    text-align: center;
    padding: 0 20px;
    margin: 7px 0 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledLessonStatus = styled.p`
    color: ${({ theme: { colors } }) => colors.White};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.title.small.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.title.small.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.title.small.lineHeight};
    letter: ${({ theme: { typography } }) => typography.title.small.letter};
    text-transform: uppercase;
    text-align: center;
    padding: 7px;
    margin: 0;
    background-color: ${({ backgroundColor, theme: { colors } }) => backgroundColor || colors.Grey40};
    border-top: ${({ theme: { colors } }) => `2px solid ${colors.EGDarkSlatteGrey}`};
`;

const getLessonStatus = (lessonStatus) => {
    
    if (lessonStatus.isLocked) {
        return "Locked";
    }

    if (lessonStatus.isComplete) {
        return "Complete";
    }

    if (lessonStatus.isOpened) {
        return "Start";
    } 
    
    if (lessonStatus.isIncomplete) {
        return "Continue";
    }
    
};

const getGameStatus = (gameStatus) => {
    
    if (gameStatus.isLocked) {
        return "Locked";
    }

    if (gameStatus.isComplete) {
        return "Complete";
    }

    if (gameStatus.isOpened) {
        return "Start";
    } 
    
    if (gameStatus.isIncomplete) {
        return "Continue";
    }
    
};

const getIconSvg = (svgString) => {
    switch (svgString) {
        case 'HabitatTreeSVG':
            return HabitatTreeSVG
        case 'HabitatWaterSVG':
            return HabitatWaterSVG
        case 'HabitatPlantSVG':
            return HabitatPlantSVG
        case 'WaterShedSVG':
            return WaterShedSVG
        case 'WaterShedLessonSVG':
            return WaterShedLessonSVG
        case 'WaterShedDamSVG':
            return WaterShedDamSVG
        case 'WaterShedHandsWorldSVG':
            return WaterShedHandsWorldSVG
        case 'FishQuestSVG':
            return FishQuestSVG
        case 'ManateeQuestSVG':
            return ManateeQuestSVG
        case 'GatorQuestSVG':
            return GatorQuestSVG
        case 'LivingInTheEvergladesSVG':
            return LivingInTheEvergladesSVG
        case 'PlantsOfTheEvergladesSVG':
            return PlantsOfTheEvergladesSVG
        case 'AnimalsOfTheEvergladesSVG':
            return AnimalsOfTheEvergladesSVG
        case 'AlligatorSVG':
            return AlligatorSVG
        case 'FoodChainSVG':
            return FoodChainSVG
        case 'FoodChainWebSVG':
            return FoodChainWebSVG
        case 'FishSVG':
            return FishSVG
        case 'FishSkeletonSVG':
            return FishSkeletonSVG
        case 'BearSVG':
            return BearSVG
    }
}

function CourseCard(props) {
    const {lessonItem, currentLessonsQuizIndicator} = props;
    const cardType = lessonItem.type
    const isLocked = cardType == "Game" ? lessonItem.gameStatus?.isLocked : lessonItem.lessonStatus?.isLocked;
    const cardColor = cardType == "Game" ? isLocked ? null : lessonItem.gameColor : isLocked ? null : lessonItem.lessonColor;
    const IconSvg = cardType == "Game" ? getIconSvg(lessonItem.gameIcon) : getIconSvg(lessonItem.lessonIcon);
    const statusText = cardType == "Game" ? getGameStatus(lessonItem.gameStatus) : getLessonStatus(lessonItem.lessonStatus);
    const title = cardType == "Game" ? lessonItem.gameTitle : lessonItem.lessonTitle;
    const linkTo = isLocked ? '' : lessonItem.url

    const IconItem = cardType == "Game" ? 
        lessonItem.gameStatus?.isLocked ? <LockIconSVG /> : <IconSvg />
        : lessonItem.lessonStatus?.isLocked ? <LockIconSVG /> : <IconSvg />
    

    return (
        <StyledCourseCardContainer>
            <StyledCardButton to={linkTo} $isLocked={isLocked} >
                <StyledIconContainer>
                    {IconItem}
                </StyledIconContainer>
                <StyledCardContainer>
                    <StyledRule color={cardColor} />
                    <StyledCourseTitle>{title}</StyledCourseTitle>
                    <StyledLessonStatus backgroundColor={cardColor} >{statusText}</StyledLessonStatus>
                </StyledCardContainer>
            </StyledCardButton>
            {currentLessonsQuizIndicator && 
                <QuizIconSVG />
            }
        </StyledCourseCardContainer>
    );
}

export default CourseCard;