import { React, useState } from 'react';
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import CourseCard from '../CourseCard';
import MapProgressIndicator from '../MapProgressIndicator';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const StyledLessonNavigationContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
`;

const StyledLessonNavigationHeaderContainer = styled.div`
    width: auto;
    height: 151px;
    background-color: ${({ theme: { colors } }) => colors.GulfBlue};
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 0 40px;

    @media (max-width: 480px) {
        justify-content: center;
        padding: 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        justify-content: center;
        padding: 0 20px;
    }
`;

const StyledHeader = styled.h2`
    color: ${({ theme: { colors } }) => colors.EGDarkSlatteGrey};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.medium.lineHeight};
    text-align: center;
    text-transform: uppercase;
    margin: 5px;
`;

const StyledRailContentContainer = styled.div`
    align-items: center;
    width: auto;
    height: 100%;
    display: flex;
    padding: 90px 20px;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 30px 20px;
    }
`;

const StyledProgressButton = styled(Link)`
    display: flex;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    appearance: none;

    @media (max-width: 480px) {
        margin-left: 0;

        svg {
            width: 30px;
            height: 30px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin-left: 10px;

        svg {
            width: 40px;
            height: 40px;
        }
    }
`;

const StyledBackIcon = styled(ArrowBackIosNewIcon)`
    color: ${({ theme: { colors } }) => colors.White};
`;

function LessonNavigationComponent(props) {
    const {lessonProgress, mapProgressStatus, to} = props;
    
    return (
        <StyledLessonNavigationContainer>
            <StyledLessonNavigationHeaderContainer>
                <StyledProgressButton to={to}>
                    <StyledBackIcon fontSize="large" />
                </StyledProgressButton>
                <MapProgressIndicator mapProgressStatus={mapProgressStatus} />
            </StyledLessonNavigationHeaderContainer>
            <StyledRailContentContainer>
                {lessonProgress.map((lessonItem, idx) => (
                    <CourseCard key={`${lessonItem.lessonId}${lessonItem.gameId}}`} lessonItem={lessonItem} currentLessonsQuizIndicator={lessonItem.currentLessonsQuizIndicator} />
                ))}
            </StyledRailContentContainer>
        </StyledLessonNavigationContainer>
    );
}

export default LessonNavigationComponent;