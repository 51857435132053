import { React, useCallback, useState } from "react";
import styled from "styled-components/macro";
import { VirtualizedPage } from "../AvatarSelectionCarousel/VirtualizedPage";
import { getbadgeSVG } from "../../assets/BadgesIcons";
import useStore from "../../shared/Store";

const BadgeCarouselContainer = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 100%;
    overflow: scroll;
    
    @media (max-width: 480px) {
        padding: 0 15px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 5px 15px;
    }
`

const BadgeCaroselContainer = styled.div`
 margin: 0 0 10px 0;
`

const BadgeContainer = styled.div`
 display: flex;
 flex-flow: row wrap;
 justify-content: center;
`

const BadgeIconContainer = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 10px;
 padding: 4px;
 width: 118px;
 height: 118px;
 flex-shrink: 0;
 background: ${({ isActive, theme: { colors } }) => isActive ? colors.EGMediumTurquoise : colors.Grey20};
 border-radius: 50%;

    svg {
        width: 110px;
    }

    @media (max-width: 480px) {
        margin: 5px;
        padding: 14px;
        width: 40px;
        height: 40px;

        svg {
            width: 40px;
            margin: 0px;
            padding: 0px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 5px;
        padding: 16px;
        width: 40px;
        height: 40px;

        svg {
            width: 43px;
            margin: 0px;
            padding: 0px;
        }
    }

    @media (min-width: 769px) and (max-width: 1100px) {
        margin: 5px 5px 10px 5px;
        padding: 16px;
        width: 70px;
        height: 70px;

        svg {
            width: 73px;
            margin: 0px;
            padding: 0px;
        }
    }
`

function BadgeCarousel() {
    const badgesEarned = useStore(s => s.badgesEarned);
    const totalNumBadges = 12;
    const badges = Array(totalNumBadges).fill("");
    badges.splice.apply(badges, [0, badgesEarned.length].concat(badgesEarned))

    const badgeComponenets = badges.reduce((badgeArray, item, index) => {
        const chunkIndex = Math.floor(index / 12);

        if (!badgeArray[chunkIndex]) {
            badgeArray[chunkIndex] = []
        }

        badgeArray[chunkIndex].push(item);

        return badgeArray;
    }, []);

    return (
        <BadgeCarouselContainer>
            <BadgeCaroselContainer>
                {badgeComponenets.map((BadgeSvgArray, index) => (
                        <BadgeContainer>
                            {BadgeSvgArray.map((label) => {
                                const BadgeSvg = getbadgeSVG(label)
                                return (
                                    <BadgeIconContainer isActive={label} key={label}>
                                        <BadgeSvg sx={{ fontSize: 82.641 }} />
                                    </BadgeIconContainer>
                                )
                            }
                            )}
                        </BadgeContainer>
                    ))
                } 
            </BadgeCaroselContainer>
        </BadgeCarouselContainer>
    );
}

export default BadgeCarousel