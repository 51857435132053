import { motion } from "framer-motion";
import React from "react";

const pageStyle = {
  position: "absolute",
  width: "100%",
};

export const Page = ({
  index,
  renderPage,
  x,
  isDisabled,
  onDragEnd,
}) => {
    const child = React.useMemo(() => 
        renderPage({ index }), 
        [index, renderPage]
    );

  return (
    <motion.div
      style={{
        ...pageStyle,
        x,
        left: `${index * 100}%`,
        right: `${index * 100}%`,
      }}
      draggable={!isDisabled}
      drag={isDisabled ? false : "x"}
      dragElastic={1}
      onDragEnd={onDragEnd}
    >
      {child}
    </motion.div>
  );
};

Page.displayName = "page";
