import { uniq } from "ramda";
import CourseData from "../../../json/courseData";
import Acheivements from "../../Acheivements";
import UserConfigModal from "../../../components/UserConfigModal";

const getCurrentCourseAcheivements = (courseId) => CourseData.find(course => course.courseId === courseId).acheivements

const createAppSlice = (set) => ({
  visiableModals: [],
  dropletCount: 0,
  addDroplets: (value) => set((state) => ({ dropletCount: state.dropletCount + value })),
  showModal: (value) => set((state) => ({ visiableModals: [value, ...state.visiableModals] })),
  hideModal: (value) => set((state) => ({ visiableModals: state.visiableModals.filter(modal => modal.tag !== value)  })),
  completedAcheivements: [],
  addCompletedAcheivement: (acheivement) => set((state) => {
    if(!state.completedAcheivements.includes(acheivement))
    {
      return { completedAcheivements: [...state.completedAcheivements, acheivement] }
    }
    return { }
  }),
  badgesEarned: [],
  addBadgesEarned: (badge) => set((state) => {
    if(!state.badgesEarned.includes(badge))
    {
      return { badgesEarned: [...state.badgesEarned, badge] }
    }
    return { }
  }),
  completedCourses: [],
  setProgressData: ({completedCourses, dropletCount, completedLessons, completedAcheivements, badgesEarned, userAvatar, userData}) => set((state) => {
    return { 
    completedCourses,
    completedLessons,
    completedAcheivements,
    badgesEarned,
    dropletCount: dropletCount || 0,
    userData: {
      ...state.userData,
      ...userData,
      avatarSvg: userAvatar
    }
  }}),
  setCompletedCourses: (value) => set({ completedCourses: value }),
  currentCourse: null,
  setCurrentCourse: (value) => set({ currentCourse: value }),
  completedLessons: [],
  setCompletedLessons: (value) => set({ completedLessons: value }),
  currentLesson: null,
  setCurrentLesson: (value) => set({ currentLesson: value }),
  completedPages: [],
  currentPage: null,
  setCurrentPage: (value) => set({ currentPage: value }),
  draggingAnswer: null,
  startDraggingAnswer: (item) => set({ draggingAnswer: item }),
  setCurrentLessonComplete: (_) => set((state) => {
    const completedLessons = [...state.completedLessons, state.currentLesson]
    var currentCourse = CourseData.find(cd => cd.courseId === state.currentCourse)
    var currentCourseLessons = currentCourse.lessons
    var currentLesson = currentCourseLessons.find(ls => ls.lessonId === state.currentLesson)
    var lastLesson = currentCourseLessons.filter(l => l.type !== "Game").slice(-1)
    var isLastLesson = state.currentLesson === lastLesson[0].lessonId;
    var visiableModals = state.visiableModals;
    var currentLessonPages = currentLesson?.pages
    var lastPage = currentLessonPages?.slice(-1)
    var isLastPage = state.currentPage === (lastPage && lastPage[0].pageId);
    var completedAcheivements = state.completedAcheivements;
    var completedCourses = state.completedCourses;
    var currentCourseAcheivements = getCurrentCourseAcheivements(state.currentCourse);

    if(isLastLesson && isLastPage && !completedCourses.includes(state.currentCourse))
    {
      completedCourses = uniq([...completedCourses, state.currentCourse])
      var currentCourseAcheivements = getCurrentCourseAcheivements(state.currentCourse);
      if(currentCourseAcheivements != null && currentCourseAcheivements.length > 0)
      {
        currentCourseAcheivements.forEach(acheivement => {
          //Add acheivement
          if(!completedAcheivements.includes(Acheivements.AvatarSelectionAcheivement) && acheivement === Acheivements.AvatarSelectionAcheivement)
          {
            visiableModals = [...visiableModals, {tag: "UserConfigModal", modal: <UserConfigModal tag={"UserConfigModal"}/>}]
          }
          completedAcheivements = uniq([...completedAcheivements, acheivement])
        });
      }
    }

    return { completedLessons, completedCourses, completedAcheivements, currentCourseAcheivements, visiableModals }
  }),
  nextPage: (_) => set((state) => {
    var currentCourse = CourseData.find(cd => cd.courseId === state.currentCourse)
    var currentCourseLessons = currentCourse.lessons
    var currentLesson = currentCourseLessons.find(ls => ls.lessonId === state.currentLesson)
    var currentLessonPages = currentLesson.pages
    var lastPage = currentLessonPages.slice(-1)
    var lastLesson = currentCourseLessons.filter(l => l.type !== "Game").slice(-1)
    var currentPageIndex = currentLessonPages.findIndex(p => p.pageId === state.currentPage);
    var isLastPage = state.currentPage === lastPage[0].pageId;
    var isLastLesson = state.currentLesson === lastLesson[0].lessonId;
    var currentLessonHasQuiz = currentLesson.quiz != null
    var currentPage = isLastPage ?
    currentLessonHasQuiz ? "quiz" : "0" 
      : currentLessonPages[currentPageIndex + 1].pageId

    var completedCourses = state.completedCourses;
    var completedLessons = state.completedLessons;
    var completedAcheivements = state.completedAcheivements;
    var visiableModals = state.visiableModals;

    if(isLastPage && !completedLessons.includes(state.currentLesson))
    {
      completedLessons = uniq([...completedLessons, state.currentLesson])
    }

    if(isLastLesson && isLastPage && !completedCourses.includes(state.currentCourse))
    {
      completedCourses = uniq([...completedCourses, state.currentCourse])
      var currentCourseAcheivements = getCurrentCourseAcheivements(state.currentCourse);
      if(currentCourseAcheivements != null && currentCourseAcheivements.length > 0)
      {
        currentCourseAcheivements.forEach(acheivement => {
          //Add acheivement
          if(!completedAcheivements.includes(Acheivements.AvatarSelectionAcheivement) && acheivement === Acheivements.AvatarSelectionAcheivement)
          {
            visiableModals = [...visiableModals, {tag: "UserConfigModal", modal: <UserConfigModal tag={"UserConfigModal"}/>}]
          }
          completedAcheivements = uniq([...completedAcheivements, acheivement])
        });
      }
    }

    var maxLesson = CourseData.find(cd => cd.courseId === state.currentCourse).lessons.length
    var currentLesson = isLastPage ?  currentLessonHasQuiz ? state.currentLesson : 0 : state.currentLesson

    var currentCourse = state.currentPage === lastPage.pageId && state.currentLesson >= maxLesson ? state.currentCourse : state.currentCourse

    return { currentPage, currentLesson, currentCourse, completedLessons, completedCourses, completedAcheivements }
  }),
  previousPage: (_) => set((state) => {
    var currentCourse = CourseData.find(cd => cd.courseId === state.currentCourse)
    var currentCourseLessons = currentCourse.lessons
    var currentLesson = currentCourseLessons.find(ls => ls.lessonId === state.currentLesson)
    var currentLessonPages = currentLesson.pages
    var firstPage = currentLessonPages.slice(0, 1)[0]
    var lastPage = currentLessonPages.slice(-1)[0]
    var currentPageIndex = currentLessonPages.findIndex(p => p.pageId === state.currentPage);

    var currentPage = state.currentPage === "quiz" ? lastPage.pageId : state.currentPage === firstPage.pageId ? state.currentPage : currentLessonPages[currentPageIndex - 1].pageId;
    return { currentPage }
  }),
  setCourseProgress: (value) => set({ courseProgress: value }),
});

export default createAppSlice;