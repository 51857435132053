import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import ButtonWithText from "../ButtonWithText";
import ModalPopUp from "../ModalPopUp";
import useStore from "../../shared/Store";
import { InfoIconSVG, QuizBubbleCloseSVG, QuizModalCloseButtonSVG, WaterDropletSVG } from "../../assets";
import { motion, useAnimate, useCycle } from 'framer-motion';
import ExitWarningDialog from '../ExitWarningDialog';

const QuizModalContainer = styled.div`
    border-radius: 20px;
    background: var(--white, #FFF);
    /* modal shadow */
    box-shadow: 4px 4px 12px 12px rgba(0, 0, 0, 0.10);
    position: absolute;
    height: 80%;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
`

const InstructionBlock = styled.div`
    flex: 1 1 auto;
`

const InstructionBlockHeader = styled.h3`
    ${({ theme }) => theme.EGDisplaySmall};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.display.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.display.medium.fontWeight};
    letter: ${({ theme: { typography } }) => typography.display.medium.letter};
    margin: 0;
    padding: 0 0 15px 0;

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.headline.small.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.small.fontWeight};
        letter: ${({ theme: { typography } }) => typography.headline.small.letter};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.headline.medium.fontWeight};
        letter: ${({ theme: { typography } }) => typography.headline.medium.letter};
    }
`

const InstructionBlockBody = styled.p`
    ${({ theme }) => theme.EGBodyMedium};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    color: var(--80-grey, #4B4F4F);
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.body.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.body.large.fontWeight};
    letter: ${({ theme: { typography } }) => typography.body.large.letter};
    margin: 0;
    padding: 0;

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        letter: ${({ theme: { typography } }) => typography.body.medium.letter};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.body.medium.fontSize};
        font-weight: ${({ theme: { typography } }) => typography.body.medium.fontWeight};
        letter: ${({ theme: { typography } }) => typography.body.medium.letter};
    }
`

const HeaderContainer = styled.div`
    height: auto;
    box-sizing: border-box;
    margin: 46px 90px;

    @media (max-width: 480px) {
        margin: 15px 20px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 25px 30px;
    }
`

const FooterContainer = styled.div`
    position: absolute;
    border-top: 1px solid #787B7B;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 90px;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    background: ${({ theme: { colors } }) => colors.White};

    @media (max-width: 1200px) {
        padding: 85px 65px 40px;
    }

    @media (max-width: 480px) {
        padding: 15px 20px;
        flex-direction: column-reverse;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 25px 30px;
        flex-direction: column-reverse;
    }
`
const CheckAnswerButton = styled(ButtonWithText)`
    width: 100%;
    min-width: 185px;
    box-sizing: border-box;

    @media (max-width: 480px) {
        width: 100%;
        margin: 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`
const BackToLearningButton = styled(ButtonWithText)`
`
const QuizContent = styled.div`
    padding: 20px 90px;
    display: flex;
    margin-bottom: 150px;

    @media (max-width: 480px) {
        padding: 0;
        flex-direction: column;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 20px 25px;
        flex-direction: column;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        padding: 20px 40px;
    }
`

const QuizContentContainer = styled.div`
    height: 100%
    padding: 20px 0;
`

const QuizProgressIndicator = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0 30px 0 20px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 25px 85px 25px 65px;
    box-sizing: border-box;
  }

  @media (max-width: 480px) {
    padding: 10px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        padding: 15px 20px;
    }
`

const ImageCreditMarkupContainer = styled.div`
  padding: 0 90px;
  @media (max-width: 1200px) {

  }
`

const QuizProgressIndicatorBarBackground = styled.div`
    width: 100%;
    max-width: 741px;
    height: 12px;
    border-radius: 8px;
    background: rgba(120, 123, 123, 0.10);
    border: 1px solid #787B7B;
    position: relative;
    flex: 1 1 auto;
`

const QuizProgressIndicatorLabel = styled.div`
  margin-right: 13px;
  flex: 0 0 auto;
`

const QuizProgressIndicatorBarFill = styled.div`
    position: absolute;
    width: ${({ percentage }) => percentage}%;
    height: 12px;
    background: ${({ theme: { colors } }) => colors.Grey60};
    border-radius: 8px;
`

const WaterDropletContainer = styled(motion.div)`
    position: absolute;
    right: -20px;
    top: -20px;
    width: 40px;
    height: 40px;
    svg {
        width: 100%;
        height: 100%;
    }
`

const WaterDropletCount = styled.div`
    position: absolute;
    top: 3px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    color: var(--eg-dark-slatte-grey, #312E2B);
    /* EG Title Medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
`

const ImageCreditHeader = styled.div`
    display: flex;
    align-items: center;
    color: var(--80-grey, #4B4F4F);
    /* EG Title Medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    margin-bottom: 12px;
    svg
    {
        margin-right: 14px;
    }
`

const ImageCreditItem = styled.p`
    margin: 0 0 1em 0;
    padding: 0;
    color: var(--80-grey, #4B4F4F);
    /* EG Body Medium */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
`

const SubmitButtonTipContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, calc(-100% - 18px));
    width: 90%;
`

const SubmitButtonTipBubble = styled.div`
    background: white;
    border-radius: 20px;
    padding: 10px;
    position: relative;
    filter: drop-shadow(0px 2px 5px grey);
    width: 100%;
    box-sizing: border-box;
    &::before
    {
        height: 15px;
        width: 30px;
        content: ''; 
        position: absolute;
        background: white;
        left: 50%;
        top: 99%;
        transform: translate(-50%, 1%);
        clip-path: polygon(12.5% 0, 87.5% 0, 50% 100%)
    }

    * { box-sizing: border-box }
`

const ButtonAndBubbleContainer = styled.div`
    position: relative;
    flex: 1 1 auto;
    max-width: 447.6px;
`

const StyledQuizButtonCloseSVG = styled(QuizBubbleCloseSVG)
`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
`

const QuizProgressIndicatorMarkup = ({dropletCount, currentQuestionIndex, totalQuestions}) => {
    const percentageComplete = ((currentQuestionIndex)/totalQuestions)*100
    const prevCountRef = useRef(dropletCount);
    const [scope, animate] = useAnimate();

    function sequence() {
        animate([
            [scope.current, { scale: 1.5 }, { duration: 0.5 }],
            [scope.current, { scale: 1 }, { duration: 0.5 }],
        ])
    }

    useEffect(() => {
        if(prevCountRef.current < dropletCount)
        {
            sequence()
        }
        prevCountRef.current = dropletCount;
    }, [dropletCount])

    return (
        <QuizProgressIndicator>
            <QuizProgressIndicatorLabel>{currentQuestionIndex} of {totalQuestions}</QuizProgressIndicatorLabel>
            <QuizProgressIndicatorBarBackground>
                <QuizProgressIndicatorBarFill percentage={percentageComplete}/>
                <WaterDropletContainer
                    ref={scope}
                >
                    <WaterDropletSVG />
                    <WaterDropletCount>{dropletCount}</WaterDropletCount>
                </WaterDropletContainer>
            </QuizProgressIndicatorBarBackground>
        </QuizProgressIndicator>
    )
}

const ImageCreditMarkup = ({credits}) => {
    return (
        <ImageCreditMarkupContainer>
            <ImageCreditHeader><InfoIconSVG /> Image Credit:</ImageCreditHeader>
            {credits.map(credit => <ImageCreditItem>{credit}</ImageCreditItem>)

            }
        </ImageCreditMarkupContainer>
    )
}

function QuizModal({ 
    children,
    header,
    subhead,
    submitEnabled,
    nextQuestionEnabled,
    retryEnabled,
    onSubmit,
    currentQuestionIndex,
    totalQuestions,
    credits,
    dropletCount,
    submitButtonTip,
    onCloseBubbleClick,
    tag,
    currentCourse,
    currentLessonPageData,
    setUserQuizStatus,
    ...props 
}) {

    const {
        hideModal,
        userData,
        addOrUpdatePartiallyCompletedQuiz
       } = useStore();

    const [openExitWarning, setExitWarningOpen] = useState(false);

    const handleClickExitWarningOpen = useCallback((event) => {
        setExitWarningOpen(true);
    }, []);

    const handleClickExitWarningCancel = () => {
        setExitWarningOpen(false);
    };

    const handleClickExitWarningLeave = useCallback((event) => {
        if (event.currentTarget.innerHTML === "Leave") {
            // Add this quiz info to the partiallyCompletedQuizes array since the quiz is now started
            // need to find what questionId the user is on and capture that
            addOrUpdatePartiallyCompletedQuiz({
                quizId: currentLessonPageData.pageId,
                courseId: currentCourse,
                resumeQuestionIndex: currentQuestionIndex,
                dropletCount,
                retryNumber: userData.partiallyCompletedQuizes.retryNumber === undefined ?  0 : userData.partiallyCompletedQuizes.retryNumber + 1
            })
            setUserQuizStatus("resume")
            hideModal(tag)
        }
    }, [hideModal, dropletCount, currentCourse, currentQuestionIndex, userData, currentLessonPageData]);

    const handleCloseBubbleClick = useCallback(() => {
        if(onCloseBubbleClick)
        {
            onCloseBubbleClick()
        }
    }, [onCloseBubbleClick]);

    const handleSubmitClick = useCallback(() => {
        if(onCloseBubbleClick)
        {
            onCloseBubbleClick()
        }
        if(onSubmit)
        {
            onSubmit()
        }
    }, [onCloseBubbleClick, onSubmit]);

    const isLastQuestion = currentQuestionIndex === totalQuestions;

    return (
        <ModalPopUp closeButtonStyle="Outer" tag={tag} closeButtonClick={handleClickExitWarningOpen}>
            <HeaderContainer>
                <InstructionBlock>
                    <InstructionBlockHeader>{header}</InstructionBlockHeader>
                    <InstructionBlockBody>{subhead}</InstructionBlockBody>
                </InstructionBlock>
            </HeaderContainer>
            <QuizContentContainer>
                <QuizContent>
                        {children}
                </QuizContent>
                {credits.length > 0 && 
                    <ImageCreditMarkup credits={credits} />
                }
            </QuizContentContainer>
            <FooterContainer>
                <BackToLearningButton buttonState={"secondary"} handleOnClick={handleClickExitWarningOpen} buttonText={"Back to Learning"} />
                <QuizProgressIndicatorMarkup dropletCount={dropletCount} currentQuestionIndex={currentQuestionIndex} totalQuestions={totalQuestions} />
                <ButtonAndBubbleContainer>
                {submitButtonTip && 
                    <SubmitButtonTipContainer>
                        <SubmitButtonTipBubble>
                            <StyledQuizButtonCloseSVG onClick={handleCloseBubbleClick} />
                            {submitButtonTip}
                        </SubmitButtonTipBubble>
                    </SubmitButtonTipContainer>
                }
                <CheckAnswerButton buttonState={submitEnabled || retryEnabled ? "primary" : "disabled"} handleOnClick={handleSubmitClick} buttonText={retryEnabled ? "Try Again" : nextQuestionEnabled ? isLastQuestion ? "Next" : "Next Question" : "Check Answer"} />
                </ButtonAndBubbleContainer>
            </FooterContainer>
            <ExitWarningDialog open={openExitWarning} onClose={handleClickExitWarningCancel} onLeave={handleClickExitWarningLeave} />
        </ModalPopUp>
  );
}

export default QuizModal;