import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import ButtonWithText from "../ButtonWithText";

const StyledStudentCodeBox = styled.div`
        display: flex;
        text-align: center;
        padding: 13.5px 20px;
        color: var(--dark-gray, #515151);
        text-align: left;
        font-size: 36px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        line-height: 36px;
        border-radius: 52px;
        border: 2px solid var(--grey-80, #4B4F4F);
        box-sizing: border-box;
        width: ${({ stretchToFitHorizontally }) => {
            if (stretchToFitHorizontally) {
                return "100%";
            } else {
                return "auto";
            }
        }};
        width: 100%;
        background: var(--white, #FFF);
        appearance: none;
        outline: none;
        box-shadow: none;
        @media (max-width: 480px) {
            display: block;
        }
        
        @media (min-width: 481px) and (max-width: 768px) {
            display: block;
        }
`;

const StyledStudentCodeValue = styled.p`
        display: flex;
        align-items: center;
        color: var(--dark-gray, #515151);
        text-align: left;
        font-size: 36px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        line-height: 36px;
        margin: 0;
        padding: 0;
        flex: 1 1 auto;
        @media (max-width: 480px) {
            display: block;
            padding: 10px 20px 20px 20px;
            text-align: center;
        }
        
        @media (min-width: 481px) and (max-width: 768px) {
            display: block;
            padding: 10px 20px 20px 20px;
            text-align: center;
        }
`;

const StyledButtonWithText = styled(ButtonWithText)`
    flex: 1 1 auto;
    @media (max-width: 480px) {
        margin: 0;
        height: 52px;
    }
    
    @media (min-width: 481px) and (max-width: 768px) {
        margin: 0;
        height: 52px;
    }
`

function StudentCodeView({ onChange, value, isDisabled, stretchToFitHorizontally, ...props }) {
    const [copyButtonText, setCopyButtonText] = useState("Copy")
    const [copiedMode, setCopiedMode] = useState(false)
    var formattedValue = useMemo(() => {
        const index = 3;
        const firstPart = value.slice(0, index);
        const secondPart = value.slice(index);
        return `${firstPart}-${secondPart}`
    }, [value])

    var handleCopyButtonClick = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value);
        setCopyButtonText("Copied");
        setCopiedMode(true);
        setTimeout(() => {
            setCopyButtonText("Copy");
            setCopiedMode(false);
        }, 1000);
    }

    return (
        <StyledStudentCodeBox
        id="student-code"
        disabled={isDisabled}
        name="cnpj"
        type="text"
        stretchToFitHorizontally={stretchToFitHorizontally}
        {...props}
    >
        <StyledStudentCodeValue>{formattedValue}</StyledStudentCodeValue>
        <StyledButtonWithText handleOnClick={handleCopyButtonClick} buttonState={copiedMode ? "primary" : "secondary"} buttonText={copyButtonText} />
    </StyledStudentCodeBox>

  );
}

export default StudentCodeView;