import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components/macro";

const StyledButton = styled(motion.button)`
    background: ${({ $buttonState, theme: { colors } }) => {
        if ($buttonState === "secondary") {
            return colors.White;
        } else if ($buttonState === "disabled") {
            return colors.Grey10;
        } else if ($buttonState === "inverted") {
            return colors.EGKhaki;
        } else if ($buttonState === "primary-alert") {
            return colors.OhNoRed;
        } else {
            return colors.EGTeal;
        }
    }};
    border: ${({ $buttonState, theme: { colors } }) => {
        if ($buttonState === "secondary") {
            return `solid ${colors.EGTeal} 2px`;
        } else if ($buttonState === "disabled") {
            return "none";
        } else if ($buttonState === "inverted") {
            return `solid ${colors.ExtraDarkBlue} 2px`;
        } else {
            return "none";
        }
    }};
    border-radius: 25px;
    color: ${({ $buttonState, theme: { colors } }) => {
        if ($buttonState === "secondary") {
            return colors.EGTeal;
        } else if ($buttonState === "disabled") {
            return colors.Grey60;
        } else if ($buttonState === "inverted") {
            return colors.ExtraDarkBlue;
        } else {
            return colors.White;
        }
    }};
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.buttonText.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.buttonText.fontWeight};
    height: 52px;
    letter: ${({ theme: { typography } }) => typography.buttonText.letter};
    line-height: ${({ theme: { typography } }) => typography.buttonText.lineHeight};
    padding: 0 40px;
    text-align: center;
    text-transform: uppercase;
    width: ${({ $stretchToFitHorizontally }) => {
        if ($stretchToFitHorizontally) {
            return "100%";
        } else {
            return "auto";
        }
    }};
    cursor: ${({ $buttonState }) => $buttonState == "disabled" ? "auto" : "pointer"};
    margin: 0 10px 0 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 480px) {
        margin: 0;
        width: 100%;
        height: 36px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        margin: 0;
        width: 100%;
        height: 36px;
    }
`;

function ButtonWithText({ buttonText, handleOnClick, buttonState, stretchToFitHorizontally, className, whileTap } ) {
  return (
      <StyledButton $stretchToFitHorizontally={stretchToFitHorizontally} className={className} disabled={buttonState === "disabled"} $buttonState={buttonState} onClick={handleOnClick} >{buttonText}</StyledButton>
  );
}

export default ButtonWithText;