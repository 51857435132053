import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { KeyOutlineSVG, LandingTreeSVG, QuestionMarkSVG } from "../../assets";
import StudentCodeInput from "../StudentCodeInput";
import ButtonWithText from "../ButtonWithText";
import StudentCodeView from "../StudentCodeView";
import useStore from "../../shared/Store";
import useUserData from "../../shared/Using/UseUserData";

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
`

const FormRow = styled.div`
    width: 100%;
    margin-bottom: 50px;
    &:last-child
    {
        margin-bottom: 0;
    }
`

const StudentCodeFormContainer = styled.div`
    width: 430px;
    margin-bottom: 24px;
`

const ResetText = styled.p`
    color: ${({ theme: { colors } }) => colors.DarkGray};
    ${({ theme }) => theme.EGBodyMedium};
    text-align: center;
    text-align: center;
    letter-spacing: 0.25px;
    user-select: none;
    margin: 0;
    padding: 0 0 52px 0;
    &:last-child
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`

const CopyModeText = styled.p`
    color: ${({ theme: { colors } }) => colors.DarkGray};
    ${({ theme }) => theme.EGBodySmall};
    text-align: center;
    text-align: center;
    letter-spacing: 0.25px;
    user-select: none;
    margin: 0;
    padding: 10px 0 20px 0;
`

const HavingIssuesText = styled.p`
    color: ${({ theme: { colors } }) => colors.DarkGray};
    ${({ theme }) => theme.EGBodySmall};
    text-align: center;
    text-align: center;
    letter-spacing: 0.25px;
    user-select: none;
    margin: 0;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span, a {
        margin: 0 5px;
    }

    a {
        color: ${({ theme: { colors } }) => colors.EGLightSeaGreen};
    }
`

const ResetTextButton = styled.span`
    color: ${({ theme: { colors } }) => colors.EGTeal};
    text-align: center;
    ${({ theme }) => theme.EGBodyMedium};
    cursor: pointer;
`

const InstructionBlock = styled.div`
    margin-bottom: 32px;
    text-align: center;
`

const InstructionBlockHeader = styled.h3`
    ${({ theme }) => theme.EGDisplaySmall};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 0;
    padding: 0 0 4px 0;
`

const InstructionBlockBody = styled.p`
    ${({ theme }) => theme.EGBodyMedium};
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 0;
    padding: 0;
`

const StyledLandingTreeSVG = styled(LandingTreeSVG)`
    padding: 0;
    margin: 0 0 24px 0;
    box-sizing: content-box;
`

const TipContainer = styled.div`
    width: 100%;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    ${({ theme }) => theme.EGBodyMedium};
    letter-spacing: 0.25px;
`

const StudentCodeInputButtonWithText = styled(ButtonWithText)
`
@media (max-width: 480px) {
    margin: 0;
    width: 100%;
    height: 52px;
}

@media (min-width: 481px) and (max-width: 768px) {
    margin: 0;
    height: 52px;
    width: 100%;
}
`

async function fetchCode() {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || `https://${window.location.hostname}`;
    const response = await fetch(`${apiEndpoint}/api/Users/getCode`);
    return await response.json();
}


function StudentCodeForm({ onChange, canGenerateNewCode, isError, createCodeButtonText, onChangeCodeClick, copyCodeButtonText, enterCodeButtonText, isDisabled, onSubmitCode, copyMode, currentCode, isCreateMode, setIsCreateMode, isAvatarModal, ...props }) {
    
    const [value, onValueChange] = useState("")
    const [genCode, setGenCode] = useState(null);
    const [isInvalid, setIsInvalid] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const toggleCreateMode = useCallback(() => setIsCreateMode((v) => !v), [])
    const userInvalid = useStore(s => s.userInvalid)
    const {checkValidCode} = useUserData();

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(!isCreateMode && value.length < 6)
        {
            setIsInvalid(true);
            setIsTouched(true);
            return;
        }

        const valueToSubmit = isCreateMode ? genCode : value;

        if(!isCreateMode)
        {
            var isValid = await checkValidCode(valueToSubmit)
            if(!isValid)
            {
                setIsInvalid(true);
                setIsTouched(true);
                return
            }
        }
        
        onSubmitCode(valueToSubmit, isCreateMode)
    }

    const continueButtonText = useMemo(() => 
        isCreateMode ? createCodeButtonText : 
        copyMode ? copyCodeButtonText : enterCodeButtonText, [
        copyMode,
        createCodeButtonText,
        enterCodeButtonText,
        isCreateMode
    ])

    const handleStudentCodeInputChange = useCallback((val) => {
        onValueChange(val)
        if(!isCreateMode && isTouched && val.length < 6)
        {
            setIsInvalid(true);
            setIsTouched(true);
        }
        else
        {
            setIsInvalid(false);
        }
    }, [
        isCreateMode,
        setIsInvalid,
        setIsTouched,
        isTouched,
    ])
    
    useEffect(() => {
        async function genCode() {
            const response = await fetchCode();
            setGenCode(response.code);
        }
        genCode();
    }, [setGenCode]);

    const handleOnChangeCodeClick = (e) => {
        setIsTouched(true);
        if(copyMode)
        {
            e.preventDefault()
        }
        
        if(onChangeCodeClick)
        {
            onChangeCodeClick()
        }
    }

    return (
        <StudentCodeFormContainer>
            <InstructionBlock>
                <StyledLandingTreeSVG sx={{ fontSize: 180 }} />
                <InstructionBlockHeader><KeyOutlineSVG  sx={{ fontSize: 29, marginRight:2 }} /> Your Special Code</InstructionBlockHeader>
                <InstructionBlockBody>Treasure this code! It's your magic key to jump back into your adventure. Lose it, and you'll have to start all over!</InstructionBlockBody>
            </InstructionBlock>
            <FormContainer onSubmit={handleSubmit}>
                <FormRow>
                    {isCreateMode ? 
                        <StudentCodeView stretchToFitHorizontally value={genCode} />
                    : copyMode ?
                        <StudentCodeView stretchToFitHorizontally value={currentCode} />
                        : <StudentCodeInput stretchToFitHorizontally value={value} onChange={handleStudentCodeInputChange} isError={isTouched && (userInvalid || isInvalid)} />
                    }
                </FormRow>
                <FormRow>
                    <StudentCodeInputButtonWithText handleOnClick={handleOnChangeCodeClick} stretchToFitHorizontally buttonState={isDisabled ? "disabled" : "primary"} buttonText={continueButtonText} />
                </FormRow>
            </FormContainer>
            {isCreateMode ?  
                 <>
                    <ResetText>Have a special code already? <ResetTextButton onClick={toggleCreateMode}>Use my special code</ResetTextButton></ResetText>
                    <TipContainer>Save your spot! Want to get back to your learning fast? Just copy this link or add it to your bookmarks. You'll be right back in the game in no time!</TipContainer>
                </>
            : copyMode ?
            <CopyModeText>Want to get back to your learning fast? Just copy this link or add it to your bookmarks. You'll be right back in the game in no time!</CopyModeText>
                : (canGenerateNewCode === null || canGenerateNewCode === true) ? <ResetText>First time here or lost your code? <ResetTextButton onClick={toggleCreateMode}>Get a new one</ResetTextButton></ResetText> : null
            }
            {isAvatarModal &&
                <HavingIssuesText><span><QuestionMarkSVG /></span>Having issues? <a href="https://www.evergladesliteracy.org/ecoquest-form-request" target="_blank" >Let us know.</a> We'll get them fixed.</HavingIssuesText>
            }
        </StudentCodeFormContainer>

  );
}

export default StudentCodeForm;