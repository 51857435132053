import { React, Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import { StyledInformationalToolTipContainer, StyledToolTipTitle, StyledToolTipContent } from './styles';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
        fontSize: 30,
        "&::before": {
            backgroundColor: '#FFFFFF',
          }
      },[`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      color: '#515151',
      boxShadow: theme.shadows[3],
      fontSize: 12,
      fontWeight: 400,
      fontFamily: 'Poppins',
      maxWidth: 300,
      userSelect: 'none'
    },
  }));

function InformationalToolTip(props) {
    const {toolTipTitle, toolTipContent, toolTipStyle, toolTipAltText} = props;
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
      setOpen(s => !s);
    };

    const iconStyle = toolTipStyle?.toLowerCase() === "dark" ? { color: '#404040'} : { color: '#fff'}
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <StyledInformationalToolTipContainer onClick={handleTooltipClose}>
                <LightTooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top-start"
                    open={open}
                    title={
                        <Fragment>
                            {toolTipTitle !== "" && 
                                <StyledToolTipTitle>{toolTipTitle}</StyledToolTipTitle>
                            }
                            <StyledToolTipContent>{toolTipContent}</StyledToolTipContent>
                            <StyledToolTipContent>{toolTipAltText}</StyledToolTipContent>
                        </Fragment>
                    }
                    arrow
                >
                    <IconButton onClick={handleTooltipOpen} sx={{ padding: 0}}>
                        <InfoIcon fontSize='large' sx={iconStyle}></InfoIcon>
                    </IconButton>
                </LightTooltip>
            </StyledInformationalToolTipContainer>
        </ClickAwayListener>

    );
}

export default InformationalToolTip;