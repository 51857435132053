import React from "react";
import styled from "styled-components/macro";
import { SplashPageBackgroundSVG } from "../../assets/splashpageassets";
import { motion } from "framer-motion";

const StyledSplashPageContainer = styled(motion.div)`
    position: relative;
    flex: 1 0 auto;
    background: #8FD0DE;
`

const Page = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
`

const SplashPageOverflow = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: calc(100vh * 1.77); /* Adjust the multiplier according to your desired aspect ratio */
    max-height: calc(100vw / 1.77); /* Adjust the divisor according to your desired aspect ratio */
    margin: auto;
    left: 50%;
    transform: translate(-50%, 0);
    box-sizing: border-box;

    @media (max-width: 600px) {
        position: absolute;
        width: var(--doc-height);
        height: 100vw;
        max-width: var(--doc-height);
        max-height: 56vh;
        max-height: 56dvh;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        box-sizing: border-box;
    }
`

const StyeldSplashPageBackgroundSVG = styled(SplashPageBackgroundSVG)`
    position: relative;
    width: 100% !important;
    height: 100% !important;
`

const RelativePositionElement = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    user-select: none;
    appearance: none;
    ${({ $xPos, $yPos }) => {
      return `
        left: ${$xPos}%;
        top: ${$yPos}%;
      `
    }};

    @media (max-width: 948px) {
      left: 50%;
      transform: translate(-50%, -53%);
    }
`;

const MapProgressOriginContainer = styled.div`
  transform: translate(-13%, -53%);
  @media (max-width: 948px) {
      transform: translate(0, -53%);
  }
`

const transitionAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

function SplashPageContainer({children, ...props}) {
    return (
        <StyledSplashPageContainer  {...transitionAnimation} {...props}>
            <SplashPageOverflow>
                <Page>
                    <StyeldSplashPageBackgroundSVG />
                    {children}
                </Page>
            </SplashPageOverflow>
        </StyledSplashPageContainer>
    );
  }

export default SplashPageContainer;