import React, { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { motion } from 'framer-motion';
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import useStore from "../../shared/Store";
import HeaderNavBar from "../../components/HeaderNavBar";
import TopicMainHeader from "../../components/TopicMainHeader";
import TopicContent from "../../components/TopicContent";
import TopicContentFeature from "../../components/TopicContentFeature";
import InteractiveQuizLaunch from "../../components/InteractiveQuizLaunch";
import PageControls from "../../components/PageControls";
import CourseData from "../../json/courseData";
import ScrollToTop from '../../components/ScrollToTop'
import QuizViewer from "../../components/QuizViewer";
import LessonProgressIndicator from "../../components/LessonProgressIndicator";
import useLoadUserOrRedirect from "../../shared/Using/UseLoadUserOrRedirect";
import ModalPopUp from "../../components/ModalPopUp";
import SuccessPage from "../../components/SuccessPage";
import { getbadgeSVG } from "../../assets/BadgesIcons";
import Acheivements from "../../shared/Acheivements";
import UserConfigModal from "../../components/UserConfigModal";

const animationStyles = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const StyledCoursePageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.GulfBlue};
`;

const StyledCoursePageContentContainer = styled.div`
  width: auto;
  padding: 0;
  margin: 0 60px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.White};
  flex: 1 1 auto;
  @media (max-width: 480px) {
    margin: 0;
  }

  @media (min-width: 481px) and (max-width: 1000px) {
    margin: 0 30px;
  }
`;

const StyleCourseInnerContent = styled(motion.div)`
  flex: 1 1 auto;
`

const StyledLessonProgressIndicatorContainer = styled.div``

const CoursePage = (props) => {
    const navigate = useNavigate();
    const { courseId, lessonId, pageId } = useParams();
    
    const {
      addCompletedAcheivement,
      completedAcheivements,
      setCurrentLessonComplete,
      addBadgesEarned,
      showModal,
      hideModal,
     } = useStore()

    const [userQuizStatus, setUserQuizStatus] = useState("");
    useLoadUserOrRedirect()

    const {
      nextPage,
      currentCourse,
      currentLesson,
      currentPage,
      previousPage,
      setCurrentPage,
      setCurrentLesson,
      setCurrentCourse,
      userData
     } = useStore();

     const getCurrentLessonPageData = pageId === "quiz" ? (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).quiz : (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).pages.find(p => p.pageId === pageId);

     const getLessonPages = (data) => data.map((page, index) => {
       return index + 1
     });

     const removeQuizFromLessonPages = (data) => {
      // We need to do this to pull out the quiz objects from the pages array in order to get an accurate count of the number of pages in the lesson.
        let pagesArray = data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).pages;
        let newPagesArray = [];
        
        pagesArray.forEach((page, index)=> {
          if (page.pageType !== "Quiz") {
            newPagesArray.push(page);
          }
        })

        return newPagesArray;
     }
     
     const getCurrentCourseTitle = (data) => data.find((course) => course.courseId === courseId).courseTitle;
 
     const getCurrentLessonTitle = (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).lessonTitle
 
     const getLessonImageCredit = (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).imageCredit

     const getCurrentLessonIcon = (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).lessonIcon

     const getCurrentLessonBadgeData = (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).badge
 
     const getCurrentTopicIndex = pageId === "quiz" ? (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).pages.length : (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).pages.findIndex(p => p.pageId === pageId)

     const getAltHeaderConfig = (data) => data.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).pages.find(l => l.pageId === pageId).altHeader
 
     const checkQuizStatus = (completedQuizes, quizPageId) => {
          let returnValue = false;
          completedQuizes.forEach((item) => {
              if (item === quizPageId) {
                  returnValue = true;
              }
          });
          return returnValue;
      }

     const currentLessonPageData = getCurrentLessonPageData(CourseData);
     const currentLessonPagesWithQuizes = removeQuizFromLessonPages(CourseData);
     const currentLessonPages = getLessonPages(currentLessonPagesWithQuizes);
     const currentCourseTitle = getCurrentCourseTitle(CourseData);
     const altHeaderConfig = getAltHeaderConfig(CourseData);
     const headerText = altHeaderConfig ? altHeaderConfig : currentCourseTitle;
     const currentLessonTitle = getCurrentLessonTitle(CourseData);
     const currentLessonIcon = getCurrentLessonIcon(CourseData);
     const currentLessonBadgeData = getCurrentLessonBadgeData(CourseData);
     const currentTopicIndex = getCurrentTopicIndex(CourseData);
     const lessonImageCredit = getLessonImageCredit(CourseData);
     const lessonPages = CourseData.find((course) => course.courseId === courseId).lessons.find(l => l.lessonId === lessonId).pages;
     const isFirstPageOfLesson = lessonPages.findIndex(p => p.pageId === pageId) == 0;
     const isLastPageOfLesson =  lessonPages.slice(-1)[0].pageId === pageId;
 
    const isQuizPage = currentLessonPageData.pageType == "Quiz";

    useEffect(() => {
      if (checkQuizStatus(userData.completedQuizes, currentLessonPageData.pageId)) {
          setUserQuizStatus("retake")
        } else if (userData.partiallyCompletedQuizes.find((data) => data.quizId === currentLessonPageData.pageId)) {
            userData.partiallyCompletedQuizes.forEach((quiz) => {
                if (currentLessonPageData.pageId === quiz.quizId) {
                  setUserQuizStatus("resume")
                }
            });
        } else {
          setUserQuizStatus("first")
        } 
    }, [currentLessonPageData.pageId])

    const IconSvg= getbadgeSVG(currentLessonBadgeData.badgeIcon)
    const handleContinue = () => {
      hideModal("LessonQuizModal");
      if(isLastPageOfLesson)
      {
        showModal({tag: "BadgeClaim", modal: 
          <ModalPopUp doIntroAnimation={false} hideCloseButton= {true} tag={"SuccessPage"}>
                <SuccessPage
                    badge = {<IconSvg></IconSvg>}
                    header = {currentLessonBadgeData.summary}
                    subhead = {currentLessonBadgeData.description}
                    handleBadgeClaim={handleBadgeClaim}
                    rewardType = {'Badge'}
                />
          </ModalPopUp>
        })
      }
      else
      {
        nextPage()
      }
    }

    const handleBadgeClaim = () => {
      addBadgesEarned(currentLessonBadgeData.badgeIcon);
      hideModal("BadgeClaim");
      onLessonComplete()
    }

    const handleRetakeQuiz = useCallback(() => {
      console.log("update quiz progress")
      setUserQuizStatus("retake");
      hideModal("LessonQuizModal");
    }, [])

    const onNextPage = useCallback(() => {
      if (isQuizPage) {
        showModal({tag: "LessonQuizModal", modal: <QuizViewer
          currentLessonIcon={currentLessonIcon}
          currentLessonBadgeData={currentLessonBadgeData}
          quizConfig={currentLessonPageData.quizConfig}
          tag="LessonQuizModal"
          topics={currentLessonPages}
          currentTopic={currentTopicIndex}
          handleContinue={handleContinue}
          handleRetakeQuiz={handleRetakeQuiz}
          currentCourse={currentCourse}
          currentLessonPageData={currentLessonPageData}
          setUserQuizStatus={setUserQuizStatus}
          />
        })
        return
      }
      nextPage()
    }, [showModal, nextPage, isQuizPage])

    const onPreviousPage = useCallback(previousPage, [])
    const onLessonComplete = useCallback(() => {
      setCurrentLessonComplete()
      setCurrentPage(null)
      setCurrentLesson(null)
      setCurrentCourse(null)
      navigate(`/course/${courseId}/lessonSelect`)
    }, [courseId, completedAcheivements, setCurrentLessonComplete, completedAcheivements])

    useEffect(() => {
      setCurrentPage(pageId)
      setCurrentLesson(lessonId)
      setCurrentCourse(courseId)
    }, [])

    useEffect(() => {
      if(currentCourse === null || currentLesson === null || currentPage === null)
      {
        return;
      }
      if(currentLesson === 0 || currentPage === 0)
      {
        if (!isQuizPage) {
        showModal({tag: "BadgeClaim", modal: 
          <ModalPopUp doIntroAnimation={false} hideCloseButton= {true} tag={"SuccessPage"}>
                 <SuccessPage
                    badge = {<IconSvg></IconSvg>}
                    header = {currentLessonBadgeData.summary}
                    subhead = {currentLessonBadgeData.description}
                    handleBadgeClaim={handleBadgeClaim}
                    rewardType = {'Badge'}
                />
          </ModalPopUp>
        })}
        return;
      }
      navigate(`/course/${currentCourse}/lesson/${currentLesson}/page/${currentPage}`)
    }, [
      currentCourse,
      currentLesson,
      currentPage,
      onLessonComplete,
      completedAcheivements
    ])

    const lessonprogressStatus = {
        lessonIconSVG: currentLessonIcon,
        topics: currentLessonPages,
        currentTopic: currentTopicIndex
    };

    return (
        <StyledCoursePageContainer key={`${courseId}${lessonId}${pageId}`}>
          <ScrollToTop />
          <HeaderNavBar
            progressIndicator={true}
            waterDropCount={0}
            lessonProgressStatus={lessonprogressStatus}
          />
          <StyledCoursePageContentContainer>
          <StyleCourseInnerContent {...animationStyles}>
            {isQuizPage ? (
              <InteractiveQuizLaunch
                userStatus={userQuizStatus}
                courseTitle={currentCourseTitle}
                lessonTitle={currentLessonTitle}
                heroImage={"images/course1/lesson1/Lesson_1_hero.webp"}
                imageCredit={""}
                courseId={courseId}
            />
            ) : (
              <>
                {
                currentLessonPageData.components.map((component, index) => {
                  const key = `${component.componentName}${currentCourseTitle}${component.imageUrl}${component.videoUrl}${component.textContent}`
                  if (component.componentName === "TopicMainHeader") {
                    return (
                      <Fragment key={key}>
                          <TopicMainHeader
                              isCondensed={component.isCondensed}
                              courseTitle={headerText}
                              lessonTitle={currentLessonTitle}
                              heroImage={component.heroImage}
                              imageCredit={component.imageCredit}
                              imageCreditStyle={component.imageCreditStyle}
                              courseId={courseId}
                              imageAlt={component.imageAlt}
                          />
                          <StyledLessonProgressIndicatorContainer>
                            <LessonProgressIndicator {...{
                              lessonIconSVG: currentLessonIcon,
                              topics: currentLessonPages,
                              currentTopic: currentTopicIndex,
                              isMobileVersion: true
                            }} />
                          </StyledLessonProgressIndicatorContainer>
                      </Fragment>
                    )
                  }

                  if (component.componentName === "TopicContentFeature") {
                    return <TopicContentFeature key={key}
                              imageUrl={component.imageUrl}
                              imageAlt={component.imageAlt}
                              imageCredit={component.imageCredit}
                              imageCreditStyle={component.imageCreditStyle}
                              heading={component.heading}
                              textContent={component.textContent}
                              videoUrl={component.videoUrl}
                              videoWidth={component.videoWidth}
                              videoHeight={component.videoHeight}
                            />
                  }

                  if (component.componentName === "TopicContent" || component.componentName === "subTopicHeading") {
                    return <TopicContent key={key}
                                componentName={component.componentName}
                                imageLocationRight={component.imageLocationRight}
                                isGallery={component.isGallery}
                                imageUrl={component.imageUrl}
                                imageCreditStyle={component.imageCreditStyle}
                                imageAlt={component.imageAlt}
                                imageCredit={component.imageCredit}
                                heading={component.heading}
                                textContent={component.textContent}
                                videoUrl={component.videoUrl}
                                videoWidth={component.videoWidth}
                                videoHeight={component.videoHeight}
                                galleryImages={component.galleryImages}
                                isTextOnly={component.isTextOnly}
                                lessonObjectives={component.lessonObjectives}
                            />
                  }
                })}
              </>
            )}
            </StyleCourseInnerContent>
            <PageControls nextButtonText={isQuizPage ? "Start" : "Next"} isDisabled={isFirstPageOfLesson} nextPage={onNextPage} previousPage={onPreviousPage} />
          </StyledCoursePageContentContainer>
        </StyledCoursePageContainer>
      
    )
  }

  export default CoursePage;