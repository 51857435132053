import React, {useEffect, useState} from "react";
import { motion } from 'framer-motion';
import {
  useNavigate
} from "react-router-dom";
import StudentCodeLoginForm from "../../components/StudentCodeLoginForm";
import styled from "styled-components/macro";
import PageContainer from "../../components/PageContainer";
import ButtonWithText from "../../components/ButtonWithText";
import useUserData from "../../shared/Using/UseUserData";
import SplashPageContainer from "../../components/SplashPageContainer";
import SplashPageData from "../../assets/splashpageassets/splashPage.json";
import { AlligatorSVG, FishSVG, FPLSponsorLogoSVG, ManateeSVG, EvergladesEcoQuestSVG } from "../../assets/splashpageassets";


const WelcomePageContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const WelcomeContent = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 192px 125px;
  box-sizing: border-box;
  @media (max-width: 480px) {
    padding: 75px 15px 35px 22px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    padding: 75px 15px 35px 22px;
  }
`

const Title = styled.h1`
  color: var(--eg-light-sea-green, #00ACBF);
  /* EG Display Large */
  font-size: 57px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  margin: 0 0 15px;
  padding: 0;
  @media (max-width: 480px) {
    margin: 0 0 6px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    margin: 0 0 6px;
  }
`

const Subtitle = styled.h2`
  color: var(--extra-dark-blue, #2B5767);
  /* EG DIsplay Small */
  font-size: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  margin: 0 0 30px;
  padding: 0;
  @media (max-width: 480px) {
    margin: 0 0 10px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    margin: 0 0 10px;
  }
`

const Rule = styled.div`
  width: 166px;
  height: 8px;
  border-radius: 100px;
  flex-shrink: 0;
  background: #00ACBF;
  margin: 0 0 40px 6px;
`

const Content = styled.div`
  color: var(--80-grey, #4B4F4F);
  /* EG Body Large */
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  padding: 0;
  margin: 0 0 28px;
`

const ContentBlock = styled.p`
  padding: 0 0 28px;
  margin: 0;
  max-width: 1060px;
`

const StudentCodeContainer = styled.div`
  min-height: 100vh;
  padding: 192px 125px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background: ${({ theme: { colors } }) => colors.EGLightestGrey};
  @media (max-width: 480px) {
    padding: 51px 15px 15px 22px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    padding: 51px 15px 15px 22px;
  }
`

const ContinueButton = styled(ButtonWithText)`
  width: 100%;
  max-width: 351px;
`

const RelativePositionElement = styled.div`
    position: absolute;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    user-select: none;
    appearance: none;

    &.EvergladesEcoQuest {
      width: 63%;
      left: 50%;
      top: 57%;
    }

    &.Fish {
      z-index: 1000;
    }

    ${({ $xPos, $yPos }) => {
      return `
        left: ${$xPos}%;
        top: ${$yPos}%;
      `
    }};
`;

const AnimatedObjectOriginContainer = styled.div`
  transform: translate(-13%, -53%);

  &.EvergladesEcoQuest {
    animation: fadeIn 5s;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  &.Manatee {
    animation: 3s slide-left, bobbing 2s infinite ease-in-out;
    animation-delay: 0s, 5s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    box-sizing: border-box;

    @keyframes bobbing {
      0% { transform: translate(-50%, -50%); }
      50% { transform: translate(-50%, -60%); }
      100% { transform: translate(-50%, -50%); }
    }

  }

  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }

  &.Alligator {
    animation: 3s slide-right, bobbing 2s infinite ease-in-out;
    animation-delay: 0s, 5s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    box-sizing: border-box;

    @keyframes bobbing {
      0% { transform: translate(-50%, -50%); }
      50% { transform: translate(-50%, -60%); }
      100% { transform: translate(-50%, -50%); }
    }

  }

  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }

  &.Fish {
    animation: 3s fish-slide-right;
    animation-delay: 0s, 1s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 46%;
    box-sizing: border-box;
  }

  @keyframes fish-slide-right {
    from {
      margin-left: -600%;
    }
    to {
      margin-left: 0%;
    }
  }

  &.FPLSponsorLogo {
    animation: fadeIn 12s;
    animation-delay: 0s, 20s;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    padding-bottom: 26%;
    box-sizing: border-box;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    20% { opacity: 0; }
    60% { opacity: 0.8; }
    80% { opacity: 0.9; }
    100% { opacity: 1; }
  }

  @media (max-width: 948px) {
      transform: translate(0, -53%);
  }
`

const animationStyles = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  
  const LoginPage = () => {
    const navigate = useNavigate();
    const [pageState, setPageState] = useState("");
    const [isCreateMode, setIsCreateMode] = useState(false)

    const {
      isLoading,
      setUserId,
      userLoggedIn,
      userInitialized,
      userInvalid,
      userId,
      initUser
    } = useUserData()

    const setUser = (code, isCreateMode) => {
      isCreateMode ? initUser(code) : setUserId(code)
    }

    const handleContinueClick = () => {
      setPageState("EnterCode")
    }

    const getIconSvg = (svgString) => {
      switch (svgString) {
          case 'AlligatorSVG':
              return AlligatorSVG
          case 'FishSVG':
            return FishSVG
          case 'FPLSponsorLogoSVG':
            return FPLSponsorLogoSVG
          case 'ManateeSVG':
            return ManateeSVG
          case 'EvergladesEcoQuestSVG':
            return EvergladesEcoQuestSVG
      }
    }

    useEffect(() => {
        setTimeout(() => {
          setPageState("Welcome")
        }, "9000");
    }, [])

    useEffect(() => {
        if(userLoggedIn)
        {
          navigate("/");
          return
        }
        if(userInvalid)
        {
          return
        }
        setPageState("SplashPage")
    }, [userLoggedIn, userInvalid, navigate])

    if((userId && !userInitialized && !userInvalid) || userLoggedIn)
    {
      return null;
    }
  
    return (
      <motion.div {...animationStyles}>
        <PageContainer>
          {pageState === "SplashPage" &&
            <SplashPageContainer>
              {SplashPageData.map((item) => {
                const IconSvg = getIconSvg(item.iconSVG);
                return (
                <RelativePositionElement key={item.iconId} style={{width:`${item.width}`}} className={`${item.iconId}`} $xPos={item.splashPagePosition.x} $yPos={item.splashPagePosition.y}>
                  <AnimatedObjectOriginContainer className={`${item.iconId}`}>
                    <IconSvg style={{width:`100%`, height:`auto`}} />
                  </AnimatedObjectOriginContainer>
                </RelativePositionElement>
              )})}
            </SplashPageContainer>
          }
          {pageState === "EnterCode" &&
            <StudentCodeContainer>
              <StudentCodeLoginForm canGenerateNewCode disabled={isLoading} onSubmitCode={setUser} />
            </StudentCodeContainer>
          }
          {pageState === "Welcome" &&
            <WelcomePageContainer>
                <WelcomeContent>
                <Title>Welcome to the Everglades EcoQuest</Title>
                <Content>
                  <ContentBlock>
                  Are you up for the challenge? Your journey will take you through America's Everglades - a most amazing place. As you travel through the Everglades, you will unlock knowledge and have it tested to earn water droplets. You will also play fun games and earn twelve unique Everglades EcoQuest badges. At the end you will be an Everglades Champion.
                  </ContentBlock>
                </Content>
                <ContinueButton handleOnClick={handleContinueClick} buttonText={"Start your journey"} />
              </WelcomeContent>
            </WelcomePageContainer>
          }
        </PageContainer>
      </motion.div>
    )
  }

export default LoginPage;