import create from "zustand";
import createAppSlice from "./App/AppSlice";
import createUserSlice from "./User/UserSlice";
import { persist } from "zustand/middleware";

// Utility functions for local storage
const ZustandLocalStorage = {
  // Asynchronously sets an item in local storage
  setItem: async (name, value) => {
    try {
      return await localStorage.setItem(name, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting item ${name}: ${error}`);
    }
  },
  // Asynchronously gets an item from local storage
  getItem: async (name) => {
    try {
      const value = await localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error(`Error getting item ${name}: ${error}`);
    }
  },
  // Asynchronously removes an item from local storage
  removeItem: async (name) => {
    try {
      return await localStorage.removeItem(name);
    } catch (error) {
      console.error(`Error removing item ${name}: ${error}`);
    }
  },
};

// Create the store
const useStore = create(
  persist(
    (set, get) => ({
      ...createAppSlice(set, get),
      ...createUserSlice(set, get)
    }),
    {
      name: "store",
      storage: ZustandLocalStorage,
      partialize: (state) => {
        return {
          userId: state.userId
        }
      }
    },
  ),
);

export default useStore;