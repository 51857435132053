import React, { useEffect } from "react";
import { motion } from 'framer-motion';
import { useParams } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import LessonNavigationComponent from "../../components/LessonNavigationComponent";
import CourseData from "../../json/courseData";
import useStore from "../../shared/Store";
import HeaderNavBar from "../../components/HeaderNavBar";
import useLoadUserOrRedirect from "../../shared/Using/UseLoadUserOrRedirect";

const animationStyles = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  
  const LessonSelectPage = () => {
    const { courseId } = useParams()
    useLoadUserOrRedirect()
    
    var courseData = CourseData.find((course) => course.courseId === courseId);
    const currentCourseLessons = courseData.lessons;

    const { completedLessons, completedCourses } = useStore(
        ({ completedLessons, completedCourses }) => ({ completedLessons, completedCourses })
      )
    


    const lessonProgress = currentCourseLessons
      .map((lesson) => {
        const lessonHasQuiz = lesson.pages?.some(p => p.pageType === "Quiz")
        return {
          ...lesson,
          lessonHasQuiz
        }
      })
      .map((lesson, idx) => {
        return lesson.type == "Game" ? {
          type: "Game",
          courseId: courseId,
          gameId: lesson.gameId,
          gameTitle: lesson.gameTitle,
          gameIcon: lesson.gameIcon,
          gameColor: lesson.gameColor,
          url: `/course/${courseId}/game/${lesson.gameId}`,
          gameStatus : {
              isLocked: !(idx === 0 || completedLessons.includes(currentCourseLessons[idx - 1].lessonId)),
              isOpened: idx === 0 || completedLessons.includes(currentCourseLessons[idx - 1].lessonId),
              isIncomplete: !completedLessons.includes(lesson.lessonId),
              isComplete: completedLessons.includes(lesson.lessonId),
          },
          currentLessonsQuizIndicator: false
        } :
           {
              type: "Course",
              courseId: courseId,
              lessonId: lesson.lessonId,
              lessonTitle: lesson.lessonTitle,
              lessonIcon: lesson.lessonIcon,
              lessonColor: lesson.lessonColor,
              url: `/course/${courseId}/lesson/${lesson.lessonId}/page/${lesson.pages[0].pageId}`,
              lessonStatus : {
                  isLocked: !(idx === 0 || completedLessons.includes(currentCourseLessons[idx - 1].lessonId)),
                  isOpened: idx === 0 || completedLessons.includes(currentCourseLessons[idx - 1].lessonId),
                  isIncomplete: !completedLessons.includes(lesson.lessonId),
                  isComplete: completedLessons.includes(lesson.lessonId),
              },
              currentLessonsQuizIndicator: lesson.lessonHasQuiz
          }
      })

    const mapProgressStatus = {
        status: {
          locked: !courseData.prerequisistes.every(prereq => completedCourses.includes(prereq)),
          complete:  completedCourses.includes(courseData.courseId)
        },
        course: courseData.courseId,
        lessons: courseData.lessons,
        completedTopic: courseData.lessons.filter(l => completedLessons.includes(l.lessonId)).length,
        courseView: true,
        courseTitle: courseData.courseTitle,
        gameTitle: `Course ${courseData.courseId} Game`,
        to: `/`,
        mapProgressIcon: courseData.mapProgressIcon
    }

    return (
        <PageContainer>
            <HeaderNavBar />
            <motion.div {...animationStyles}>
                <LessonNavigationComponent to={"/"} lessonProgress={lessonProgress} mapProgressStatus={mapProgressStatus} />
            </motion.div>
        </PageContainer>
    )
  }

export default LessonSelectPage;