import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {HabitatTreeSVG, HabitatWaterSVG, HabitatPlantSVG, LockIconSVG, WaterShedSVG, HabitatSVG, FishQuestSVG, AlligatorSVG, FoodChainSVG, FoodChainWebSVG, FishSVG, FishSkeletonSVG, ManateeQuestSVG, GatorQuestSVG, BearSVG} from "../../assets/index"; 

const StyledProgressButton = styled(Link)`
    display: flex;
    align-items: center;
    margin: 5px;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    appearance: none;
`;

const StyledProgressMeterContainer = styled.div`
    align-items: center;
    background: ${({ $isLocked, $isComplete, theme: { colors } }) => {
        if ($isLocked) {
            return colors.LightGrey;
        } else if ($isComplete) {
            return colors.EGLightSeaGreen;
        } else {
            return colors.ExtraDarkBlue;
        }
    }};
    border: ${({ theme: { colors } }) => `solid ${colors.White} 5px`};
    border-radius: 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.25);
    display: flex;
    flex-direction: row;
    height: 46px;
    margin: 20px 20px 20px -32px;
    padding: 0;
    width: ${({ courseView }) => {
        if (courseView) {
            return `400px`;
        } else {
            return `300px`;
        }
    }};
    min-width: 200px;
    overflow: hidden;

    @media (max-width: 480px) {
        height: 30px;
        width: ${({ courseView }) => {
            if (courseView) {
                return `271px`;
            } else {
                return `250px`;
            }
        }};
        margin: 20px 2px 20px -32px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 40px;
        width: ${({ courseView }) => {
            if (courseView) {
                return `385px`;
            } else {
                return `300px`;
            }
        }};
    }
`;

const StyledIconContainer = styled.span`
    align-items: center;
    background-color: ${({ theme: { colors } }) => colors.LightGrey};
    border: ${({ theme: { colors } }) => `solid ${colors.EGDarkSlatteGrey} 3px`};
    border-radius: 50%;
    display: flex;
    height: 80px;
    justify-content: center;
    width: 80px;
    z-index: 100;
    margin-left: 15px;

    svg {
        height: 50px;
        width: 50px;
    }

    @media (max-width: 480px) {
        height: 50px;
        width: 50px;
        margin-left: 0;

        svg {
            height: 35px;
            width: 35px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 70px;
        width: 70px;
        margin-left: 10px;

        svg {
            height: 45px;
            width: 45px;
        }
    }
`;

const StyledProgressIndicatorSpan = styled.span`
    align-items: center;
    background-color: ${({ theme: { colors } }) => colors.EGLightSeaGreen};
    display: flex;
    height: 100%;
    padding-right: 20px;
    position: relative;
    width: ${(props) => `${props.topicProgressWidth}%`};

    @media (min-width: 481px) and (max-width: 1000px) {
        padding-right: 12px;
    }
`;

const StyledGameTitle = styled.p`
    color: ${({ $isLocked, theme: { colors } }) => {
        if ($isLocked) {
            return colors.Grey60;
        } else {
            return colors.White;
        }
    }};
    display: flex;
    justify-content: ${({ courseView, theme: { colors } }) => {
        if (courseView) {
            return `space-around`;
        } else {
            return `flex-start`;
        }
    }};
    position: absolute;
    z-index: 100;
    width: ${({ courseView }) => {
        if (courseView) {
            return `400px`;
        } else {
            return `300px`;
        }
    }};
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.title.large.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.title.large.fontWeight};

    span {
        padding-left: ${({ courseView }) => {
            if (!courseView) {
                return `55px`;
            }
        }};
    }

    @media (max-width: 480px) {
        width: 271px;
        font-size: ${({ theme: { typography } }) => typography.title.small.fontSize};
        margin: 0 10px;

        span:first-child {
            margin-right: 12px;
        }
    }
`;

const getSvg = (svgString) => {
    switch (svgString) {
        case 'LockIconSVG':
            return LockIconSVG
        case 'HabitatTreeSVG':
            return HabitatTreeSVG
        case 'HabitatWaterSVG':
            return HabitatWaterSVG
        case 'HabitatPlantSVG':
            return HabitatPlantSVG
        case 'WaterShedSVG':
            return WaterShedSVG
        case 'HabitatSVG':
            return HabitatSVG
        case 'FishQuestSVG':
            return FishQuestSVG
        case 'ManateeQuestSVG':
            return ManateeQuestSVG
        case 'GatorQuestSVG':
            return GatorQuestSVG
        case 'AlligatorSVG':
            return AlligatorSVG
        case 'FoodChainSVG':
            return FoodChainSVG
        case 'FoodChainWebSVG':
            return FoodChainWebSVG
        case 'FishSVG':
            return FishSVG
        case 'FishSkeletonSVG':
            return FishSkeletonSVG
        case 'BearSVG':
            return BearSVG
    }
}

function MapProgressIndicator(props) {
    let { status, course, lessons, completedTopic, courseView, courseTitle, gameTitle, mapProgressIcon, to } = props.mapProgressStatus;
    
    if (courseTitle === "Plants and Animals in the Everglades") {
        courseTitle = "Plants + Animals";
    }

    const lessonsLength = lessons.filter(l => {
        return l.type != "Game"
    }).length
    const topicProgressWidth = (completedTopic/lessonsLength)*100;
    const $isLocked = status.locked;
    const $isComplete = status.complete;

    const IconSvg = !status.locked ? getSvg(mapProgressIcon) :  getSvg("LockIconSVG")
    return (
        <StyledProgressButton to={!$isLocked && to}>
            <StyledIconContainer>
                <IconSvg />
            </StyledIconContainer>
            <StyledProgressMeterContainer $isLocked={$isLocked} $isComplete={$isComplete} courseView={courseView} tabIndex={0}>
                <StyledGameTitle $isLocked={$isLocked} courseView={courseView} ><span>{courseView ? courseTitle : gameTitle}</span>{courseView && <span>{`${completedTopic}/${lessonsLength}`}</span>}</StyledGameTitle>
                {courseView && !status.locked && !status.open &&
                    <StyledProgressIndicatorSpan topicProgressWidth={topicProgressWidth} ></StyledProgressIndicatorSpan>
                }
            </StyledProgressMeterContainer>
        </StyledProgressButton>
    );
}

export default MapProgressIndicator;