import styled from "styled-components/macro";
import ButtonWithText from "../ButtonWithText";
import LessonProgressIndicator from "../LessonProgressIndicator";
import { WaterBottleSVG } from "../../assets";

const SuccessPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    justify-content: center;
    width: auto;
    height: 100%;
`

const SuccessIconContainer = styled.div`
    background-color: ${({ theme: { colors } }) => colors.EGMediumTurquoise};
    border-radius: 50%;
    width: 309px;
    height: 309px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
        height: 180px;
        width: 180px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 200px;
        width: 200px;
        margin-bottom: 25px
    }
`

const SuccessBadgeIconContainer = styled.div`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme: { colors } }) => colors.EGMediumTurquoise};
    border-radius: 50%;
    display: flex;
    width: 309px;
    height: 309px;

    svg {
        width: 227px;
        height: 227px;
        flex-shrink: 0;
    }
     
    @media (max-width: 480px) {
        height: 180px;
        width: 180px;

        svg {
            height: 120px;
            width: 120px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 200px;
        width: 200px;

        svg {
            height: 140px;
            width: 140px;
        }
    }

    @media (min-width: 769px) and (max-width: 1000px) {
        height: 215px;
        width: 215px;

        svg {
            height: 150px;
            width: 150px;
        }
    }
`

const StyledWaterBottleSVG = styled(WaterBottleSVG)`
    width: 227px !important;
    height: 227px !important;

    @media (max-width: 480px) {
        height: 140px !important;
        width: 140px !important;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 145px !important;
        width: 145px !important;
    }
`;

const SuccessIconLabel = styled.div`
    max-width: 1265px;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    /* EG Headline Largest */
    ${({ theme }) => theme.EGHeadlineLargest};
    margin: 70px 0 30px 0;

    @media (max-width: 480px) {
        ${({ theme }) => theme.EGHeadtitleLarge};
        align-self: stretch;
        margin: 15px 0 20px 0;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        ${({ theme }) => theme.EGHeadlineMedium};
        margin: 20px 0 25px 0;
    }

    @media (min-width: 769px) and (max-width: 1000px) {
        margin: 30px 0 30px 0;
    }
`
const Header = styled.div`
    max-width: 1265px;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 25px 20px;
    /* EG Headline Largest */
    ${({ theme }) => theme.EGHeadlineLargest};

    @media (max-width: 480px) {
        ${({ theme }) => theme.EGHeadtitleLarge};
        align-self: stretch;
    }

    @media (min-width: 481px) and (max-width: 1000px) {
        ${({ theme }) => theme.EGHeadlineMedium};
    }
`
const Subhead = styled.div`
    max-width: 1145px;
    color: ${({ theme: { colors } }) => colors.DarkGray};
    text-align: center;
    margin: 0 30px;

    /* EG Headline Small */
    ${({ theme }) => theme.EGHeadlineSmall};
    
    @media (max-width: 480px) {
        ${({ theme }) => theme.EGBodySmall};
    }

    @media (min-width: 481px) and (max-width: 1000px) {
        ${({ theme }) => theme.EGBodyMedium};
    }
`

const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 30px 0 0 0;
    justify-content: center;
    align-items: center;

    button {
        width: 250px;
    }

    @media (max-width: 480px) {
        flex-direction: column;

        button {
            width: 70%;
            margin: 10px 0;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        flex-direction: column;

        button {
            width: 70%;
            margin: 10px 0;
        }
    }

    @media (min-width: 769px) and (max-width: 1000px) {
        flex-direction: row;
    }
`;

const LessonProgressIndicatorContainer = styled.div`
    width: 680px;
    height: 75.467px;
    flex-shrink: 0;

    @media (max-width: 480px) {
        height: 0;
    }

    @media (min-width: 481px) and (max-width: 1000px) {
        height: 0px;
    }
`
const StyledWaterCounter = styled.p`
    position: absolute;
    font-family: Poppins, sans-serif;
    font-size: 76px;
    font-weight: 700;
    line-height: 70px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    width: 227px;
    height: 70px;
    text-align: center;
    transform: translate(-3px, 25%);
    color: ${({ theme: { colors } }) => colors.White};

    @media (max-width: 480px) {
        font-size: 43px;
        line-height: 48px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: 48px;
        line-height: 50px;
    }
`;

export default function SuccessPage({
    badge,
    badgeLabel,
    header,
    subhead,
    currentLessonIcon,
    topics,
    handleRetakeQuiz,
    handleContinue,
    handleBadgeClaim,
    currentTopic,
    rewardType,
    waterDropCount
}) {
    const lessonProgressStatus = {
        lessonIconSVG: currentLessonIcon,
        topics: topics,
        currentTopic: currentTopic + 1
    };

    return (
        <SuccessPageContainer>
            {rewardType == 'Canteen' && 
                <>
                    <LessonProgressIndicatorContainer>
                        <LessonProgressIndicator {...lessonProgressStatus} />
                    </LessonProgressIndicatorContainer>
                    <SuccessIconLabel>{badgeLabel}</SuccessIconLabel>
                    <SuccessIconContainer><StyledWaterBottleSVG/><StyledWaterCounter>{waterDropCount}</StyledWaterCounter></SuccessIconContainer>
                    
                    <StyledButtonContainer>
                        <ButtonWithText handleOnClick={handleRetakeQuiz} buttonText={"Retake quiz"} buttonState="secondary" />
                        <ButtonWithText handleOnClick={handleContinue} buttonText={"Continue"} />
                    </StyledButtonContainer>

                </>
            }
            {rewardType == 'Badge' && 
                <>
                    <SuccessBadgeIconContainer>{badge}</SuccessBadgeIconContainer>
                    <Header>{header}</Header>
                    <Subhead>{subhead}</Subhead>
                    <StyledButtonContainer>
                        <ButtonWithText handleOnClick={handleBadgeClaim} buttonText={"Claim"} />
                    </StyledButtonContainer>
                </>
            }
        </SuccessPageContainer>
    )
}