import styled from "styled-components/macro";

const StyledPageContainer = styled.div`
    min-height: var(--doc-height);
    display: flex;
    flex-direction: column;
`

function PageContainer({children, ...props}) {
    return (
        <StyledPageContainer {...props}>
            {children}
        </StyledPageContainer>
    );
  }

export default PageContainer;