import styled from "styled-components/macro";
import useOutsideClick from "../../shared/Using/UseOutsideClick";
import useStore from "../../shared/Store";
import { useCallback, useMemo, useState } from "react";
import { motion } from 'framer-motion';
import StudentCodeForm from "../StudentCodeForm";
import AvatarSelectionCarousel from "../AvatarSelectionCarousel";
import { CloseButtonSVG } from "../../assets";
 import { getAvatarWithLock, icons } from "../../assets/AvatarIcons";
import Acheivements from "../../shared/Acheivements";
import useUserData from "../../shared/Using/UseUserData";

const containerTransitionAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
        duration: 0.1
    }      
  };

const contentTransitionAnimation = {
    initial: { 
        y: -50
    },
    animate: {
        y: 0,
        transition: {
            duration: 0.2,
            type: "spring",
            damping: 25,
            stiffness: 500,
          },
    },
    exit: {
        //fix a bug where if there is no actual difference between the current and exit state,
        //the parent compoenent in AnimatePresence will not unmount
        y: -1 
    }  
  };

const ModalContainer = styled(motion.div)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

const ModalBackdrop = styled(motion.div)`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    opacity: 0.6499999761581421;
    z-index: 100;
`;

const ModalContent = styled.div`
    display: flex;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    overflow: hidden;
    width: 90%;
    height: 90%;
    max-height: 959px;
    max-width: 1625px;
    transform: translate(-50%, -50%);
    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        transform: translate(-50%, 0%);
        top: auto;
        bottom: 0;
        height: 90%;
    }
`;

const ModalContentAnimationContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000;
`;

const AvatarSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  flex: 1 1 auto;
  margin: 44px 0;
`;

const CodeInputContainer = styled.div`
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    padding: 50px;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    display: flex;
`

const AvatarSelectInfo = styled.h2`
    color: ${({ theme: { colors } }) => colors.PureBlack};
    ${({ theme }) => theme.EGHeadlineSmall};
    text-align: center;
    margin: 26px;
    padding: 0;
`

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 24px;
  right: 14px;
`

const ScrollableModalContent = styled.div`
    display: flex;
    background: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
    @media (max-width: 1480px) {
        display: block;
        overflow-y: auto;
    }
`

const AvatarContainer = styled.div`
    display: inline-block;
`

function UserConfigModal(props) {
    const hideModal = useStore().hideModal;
    const currentCode = useStore(s => s.userId)
    const {
        resetUser,
        initUser
    } = useUserData()

    const handleClickOutside = useCallback(() => {
        hideModal(props.tag)
    }, [hideModal]);
    const outsideClickRef = useOutsideClick(handleClickOutside);
    const currentAvatarSvg = useStore((state) => {
        return state.userData?.avatarSvg
    });
    const avatarSelectionLocked = useStore((state) => {
        return !state.completedAcheivements.includes(Acheivements.AvatarSelectionAcheivement)
    });
    const DisplayAvatarSVG = getAvatarWithLock(avatarSelectionLocked, currentAvatarSvg);
    const [isCopyMode, setIsCopyMode] = useState(true);
    const [isCreateMode, setIsCreateMode] = useState(false)
    const handleOnChangeCodeClick = useCallback(() => {
        setIsCopyMode(false)
    })
    const handleSubmitCode = (code) => {
        isCreateMode ? initUser(code) : resetUser(code)
        hideModal(props.tag)
    }

    const handleAvatarChanged = () => {
        hideModal(props.tag)
    }

    const avatarSelectionCopy = useMemo(() => avatarSelectionLocked ? "Complete the course to unlock your avatar" : "Choose your Avatar", [avatarSelectionLocked])

    return (
        <ModalContainer  {...containerTransitionAnimation}>
            <ModalBackdrop />
            <ModalContentAnimationContainer  {...contentTransitionAnimation}>
                <ModalContent ref={outsideClickRef}>
                    <CloseButton onClick={handleClickOutside}>
                        <CloseButtonSVG sx={{ fontSize: 58 }}  />
                    </CloseButton>
                    <ScrollableModalContent>
                        <AvatarSelectContainer>
                            <AvatarContainer><DisplayAvatarSVG sx={{ fontSize: 165 }} /></AvatarContainer>
                            <AvatarSelectInfo>{avatarSelectionCopy}</AvatarSelectInfo>
                            <AvatarSelectionCarousel onAvatarChanged={handleAvatarChanged} avatarSelectionLocked={avatarSelectionLocked} />
                        </AvatarSelectContainer>
                        <CodeInputContainer>
                            <StudentCodeForm canGenerateNewCode={true} onSubmitCode={handleSubmitCode} currentCode={currentCode} setIsCreateMode={setIsCreateMode} isCreateMode={isCreateMode} onChangeCodeClick={handleOnChangeCodeClick} copyCodeButtonText="Change Code" createCodeButtonText="Change Code" enterCodeButtonText="Submit" copyMode={isCopyMode} isAvatarModal={true} />
                        </CodeInputContainer>
                    </ScrollableModalContent>
                </ModalContent>
            </ModalContentAnimationContainer>
        </ModalContainer>
    )
}

export default UserConfigModal