import React, { useContext, useEffect } from "react";
import styled from "styled-components/macro";
import useLoadUserOrRedirect from "../../shared/Using/UseLoadUserOrRedirect";
import { useParams } from "react-router-dom";
import { AppSettingsContext } from "../../AppSettingsContext";

const GamePageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    iframe
    {
        width: 100%;
        height: 100%;
    }
`

const GamePage = () => {
    const {userId} = useLoadUserOrRedirect()
    const { gameId, courseId } = useParams();
    const homeUrl = `https%3A%2F%2F${window.location.hostname}%2Fcourse%2F${courseId}%2FlessonSelect`;
    const { gameServer } = useContext(AppSettingsContext);

    return (
        <GamePageContainer>
            <iframe src={`${gameServer}?homeUrl=${homeUrl}&gameId=${gameId}&studentCode=${userId}`} />
        </GamePageContainer>
    )
  }

  export default GamePage;