import styled from "styled-components/macro";
import BackgroundImage from "../../assets/svg/FL.svg";
import MapSVG from "../../assets/MapIconSvg";
import { motion } from "framer-motion";

const StyledMapContainer = styled(motion.div)`
    position: relative;
    flex: 1 0 auto;
    background: var(--gulf-blue, #5B8DBD);
    overflow-x: hidden;
`

const Map = styled.div`
    max-width: 830px;
    position: relative;
    width: 100%;
    padding-bottom: 115.37%;
    height: 0;
    @media (max-width: 948px) {
        max-width: none;
        top: 65px;
    }
`

const MapOverflow = styled.div`
    position: absolute;
    width: 100%;
    max-width: 930px;
    left: 50%;
    transform: translate(-50%, 0);
    padding-right: 100px;
    box-sizing: border-box;
    @media (max-width: 948px) {
        padding-right: 0;
        left: 50%;
        transform: translate(-50%, -9.5%);
        width: 130%;
    }
`

const StyeldMapSVG = styled(MapSVG)`
    position: relative;
    top: -44px;
    width: 100%;
    height: auto;
`

const transitionAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

function MapContainer({children, ...props}) {
    return (
        <StyledMapContainer  {...transitionAnimation} {...props}>
            <MapOverflow>
                <Map>
                    <StyeldMapSVG />
                    {children}
                </Map>
            </MapOverflow>
        </StyledMapContainer>
    );
  }

export default MapContainer;