import React from "react";
import {useNavigate} from 'react-router-dom';
import styled from "styled-components/macro";
import LinkButtonWithText from "../LinkButtonWithText";

const StyledPageControlContainer = styled.div`
    height: 173px;
    margin: 0;
    padding: 20px;
    background-color: ${({ theme: { colors } }) => colors.EGKhaki};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
`;

function PageControls({ isDisabled, nextPage, previousPage, nextButtonText }) {
  const handleOnClickBack = () => {
    previousPage();
  }

  const handleonClickNextPage = () => {
    nextPage();
  }

  return (
      <StyledPageControlContainer>
        <LinkButtonWithText buttonText="Back" buttonState={isDisabled ? `inverted` : `primary`} isDisabled={isDisabled} handleOnClick={handleOnClickBack}/>
        <LinkButtonWithText buttonText={nextButtonText} buttonState="primary" handleOnClick={handleonClickNextPage}/>
      </StyledPageControlContainer>
  );
}

export default PageControls;