import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { DndContext } from '@dnd-kit/core';
import { pickBy } from 'ramda';
import DroppableAnswerSlot from "./DroppableAnswerSlot";
import DraggableAnswerTextBlock from "./DraggableAnswerTextBlock";
import DroppableTextAnswerSlot from "./DroppableTextAnswerSlot";
import { DndDropCaretSVG } from "../../assets";
import { AppSettingsContext } from "../../AppSettingsContext";

const LeftColumn = styled.div`
    display: flex;
    flex: 1 1 auto;
    box-sizing: border-box;
    
    @media (max-width: 480px) {
    }

    @media (min-width: 481px) and (max-width: 1029px) {
    }

    @media (min-width: 1030px) {
        max-width: 600px
    }
`

const ColumnContainer = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 480px) {
        display: block;
    }

    @media (min-width: 481px) and (max-width: 1029px) {
        display: block;
    }

    @media (min-width: 1030px) {
    }
`


const RightColumn = styled.div`
    flex: 1 1 auto;
    padding: 20px;
    
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    @media (max-width: 480px) {
        display: block;
    }

    @media (min-width: 481px) and (max-width: 1029px) {
        display: block;
    }

    @media (min-width: 1030px) {
        width: 50%;
    }
`

const RightColumnAnswers = styled.div`
    display: grid;
    grid-template-columns: 200px 200px;
    grid-template-rows: 52px 52px;
    gap: 70px 88px;
    place-items: center;
    padding: 20px;
    box-sizing: border-box;
    background: ${({ theme: { colors } }) => colors.EGLightestGrey};
    @media (max-width: 480px) {
        display: grid;
        grid-template-columns: 45% 45%;
        grid-template-rows: 52px 52px;
        gap: 20px 5%;
    }

    @media (min-width: 481px) and (max-width: 1029px) {
        display: grid;
        grid-template-columns: 45% 45%;
        grid-template-rows: 52px 52px;
        gap: 20px 5%;
    }

    @media (min-width: 1030px) and (max-width: 1450px) {
        display: flex;
        flex-direction: column;
    }
`

const PlaceholderNode = styled.div`
    width: 173px;
    height: 50px;
    border-radius: 8.381px;
    margin: 20px 10px;
    @media (max-width: 480px) {
        width: 125px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
    }
`

const QuestionImageContainer = styled.div`
    position: relative;
    min-width: 100%;
    display: flex;
`

const QuestionImage = styled.img`
    width: 100%;
`

const QuestionDropParentContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
`

const StyledDroppableTextAnswerSlot = styled(DroppableTextAnswerSlot)`
    position: absolute;
    ${({
        caretDirection,
        positionTop,
        positionLeft
    }) => {
        switch (caretDirection) {
            case "down":
                return `
                    top: ${positionTop}%;
                    left: ${positionLeft}%;
                    transform: translate(-50%, -50%) translate(0px, -40px);
                    svg {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%, 0%) rotate(90deg);
                    }
                `
            case "left":
                return `
                    top: ${positionTop}%;
                    left: ${positionLeft}%;
                    transform: translate(0%, -50%) translate(20px, -8px);
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 0%;
                        transform: translate(-100%, -50%) rotate(180deg);
                    }
                `
            case "right":
                return `
                    top: ${positionTop}%;
                    left: ${positionLeft}%;
                    transform: translate(-100%, -100%) translate(-20px, 20px);
                    svg {
                        position: absolute;
                        top: 50%;
                        right: 0%;
                        transform: translate(100%, -50%);
                    }
                `
            case "up":
                return `
                    top: ${positionTop}%;
                    left: ${positionLeft}%;
                    transform: translate(-50%, -50%) translate(0px, 34px);
                    svg {
                        position: absolute;
                        top: 0%;
                        left: 50%;
                        transform: translate(-50%, -100%) rotate(-90deg);
                    }
                `
        }
    }}
`


const QuizImageMatchup = forwardRef((
    {
        onChange,
        isError,
        createCodeButtonText,
        enterCodeButtonText,
        isDisabled,
        questionId,
        questionConfig: {
            image,
            answers
        },
        onReadyToSubmitChange,
        onQuestionStateChange,
        ...props 
    },
    ref
) => {
    const { assetServer } = useContext(AppSettingsContext);
    const [dropParent, setDropParent] = useState({});
    const isPhotosynthesisImageQuestion = questionId === "PlantsOfTheEvergladesQuizImageMatchup1";

    function handleDragEnd(event) {
        if (event.over && event.over.id) {
            const isCorrectAnswer = event.active.id == event.over.id
            if(!isCorrectAnswer)
            {
                return;
            }
            setDropParent((state) => {
                var stateWithDropParentRemoved = pickBy((k,v) => {
                    return k!==event.active.id
                },state);
                return {...stateWithDropParentRemoved, [`${event.over.id}`]: event.active.id}
            })
        }
    }

    useImperativeHandle(ref, () => ({
        validateQuestion: () => {
            var answerResponse = Object.entries(answers).map(([k,v]) =>{
                const dropParentInQuestion = Object.entries(dropParent).find(([dpk,dpv]) => dpv === `${k}+1`)
                const [dpk, dpv] = dropParentInQuestion;

                return {slotId: dpk, answerId: k, isCorrect: dpv === dpk};
            })

            if (answerResponse.every(r => r.isCorrect)) {
                onQuestionStateChange("Done");
            } else {
                onQuestionStateChange("Failed");
            }
            console.log(answerResponse)
          }
    }));

      const dropParentValues = useMemo(() => Object.values(dropParent), [dropParent])

      const answerMarkeup = answers.reduce(
        (accumulator, {text}, idx) => {
            accumulator[`${idx}+1`] = (
                <DraggableAnswerTextBlock key={questionId+`${idx}+1`} isDropped={dropParentValues.includes(`${idx}+1`)} id={`${idx}+1`} isPhotosynthesisImageQuestion={isPhotosynthesisImageQuestion} >{text}</DraggableAnswerTextBlock>
            )
            return accumulator;
        },
        {}
      )

    const submitEnabled = Object.keys(dropParent).length >= Object.keys(answerMarkeup).length
    useEffect(() => {
        if(onReadyToSubmitChange)
        {
          onReadyToSubmitChange(submitEnabled);
        }
    }, [submitEnabled])

    
    const questionMarkup = answers.map((answer, idx) => {
        return (
            <StyledDroppableTextAnswerSlot caretDirection={answer.caretDirection} positionTop={answer.top} positionLeft={answer.left} id={`${idx}+1`} key={questionId+`${idx}+1`} isPhotosynthesisImageQuestion={isPhotosynthesisImageQuestion} >
                {dropParent[`${idx}+1`] ? answerMarkeup[dropParent[`${idx}+1`]] : null}
                <DndDropCaretSVG />
            </StyledDroppableTextAnswerSlot>
        )
    })

    return (
        <DndContext onDragEnd={handleDragEnd} >
            <ColumnContainer>
                <LeftColumn>
                <QuestionImageContainer>
                        <QuestionImage src={`${assetServer}/${image}`} />
                        <QuestionDropParentContainer>
                        {questionMarkup}
                        </QuestionDropParentContainer>
                </QuestionImageContainer>
                </LeftColumn>
                <RightColumn>
                    <RightColumnAnswers>
                    {Object.entries(answerMarkeup).map(([key, value]) => {
                        return !Object.values(dropParent).includes(key) ?  value : < PlaceholderNode key={"placeholder"+key} />
                    } 
                    )}
                    </RightColumnAnswers>
                </RightColumn>
            </ColumnContainer>
        </DndContext> 
    );
})

export default QuizImageMatchup;