import courseOne from './courseOne.json'
import courseTwo from './courseTwo.json'
import courseThree from './courseThree.json'
import courseFour from './courseFour.json'

export default [
    ...courseOne,
    ...courseTwo,
    ...courseThree,
    ...courseFour
];