import React, { useCallback } from "react";
import styled from "styled-components/macro";
import LessonProgressIndicator from '../LessonProgressIndicator';
import {EGLogoWhite, AvatarDefaultSVG, WaterBottleSVG, BadgeSVG} from "../../assets/index";
import useStore from "../../shared/Store";
import UserConfigModal from "../UserConfigModal";
import BadgeModalPopup from "../BadgeModalPopup";
import Acheivements from "../../shared/Acheivements";
import { getAvatar, icons } from "../../assets/AvatarIcons";

const StyledHeaderNavBarContainer = styled.div`
    align-items: center;
    background: ${({ theme: { colors } }) => colors.ExtraDarkBlue};
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 20px;
    flex: 0 0 auto;

    @media (max-width: 480px) {
        height: 76px;
        padding: 10px;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 100px;
        padding: 12px;
    }
`;

const StyledEGLogoContainer = styled.div`
    height: auto;
    width: auto;

    svg {
        width: 184px;
        height: auto;
    }
    
    @media (max-width: 480px) {
        height: 54px;

        svg {
            width: 105px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        height: 75px;

        svg {
            width: 150px;
        }
    }

`;

const StyledLessonProgressIndicatorContainer = styled.div`
    height: 93px;
    width: 45%;

    @media (max-width: 768px) {
        display: none;
    }
`;

const StyledEGControlsContainer = styled.div`
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const StyledBadgeCounterButton = styled.button`
    height: auto;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 2px;
    position: relative;
    text-align: center;

    svg {
        width: 87px;
        height: auto;
    }

    @media (max-width: 480px) {
        svg {
            width: 48px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        svg {
            width: 68px;
        }
    }
`;

const StyledWaterCounterButton = styled.button`
    height: auto;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    user-select: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 2px;
    position: relative;
    text-align: center;

    svg {
        width: 66px;
        height: auto;
    }

    @media (max-width: 480px) {
        svg {
            width: 37px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        svg {
            width: 51px;
        }
    }
`;

const StyledWaterCounter = styled.p`
    position: absolute;
    top: 32%;
    left: 48%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.title.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
    text-transform: uppercase;
    color: ${({ theme: { colors } }) => colors.White};

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.buttonText.fontSize};
        line-height: ${({ theme: { typography } }) => typography.buttonText.lineHeight};
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.title.large.fontSize};
        line-height: ${({ theme: { typography } }) => typography.title.large.lineHeight};
    }
`;

const StyledBadgeCounter = styled.p`
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    font-size: ${({ theme: { typography } }) => typography.headline.medium.fontSize};
    font-weight: ${({ theme: { typography } }) => typography.title.large.fontWeight};
    line-height: ${({ theme: { typography } }) => typography.headline.small.lineHeight};
    text-transform: uppercase;
    color: ${({ theme: { colors } }) => colors.White};

    @media (max-width: 480px) {
        font-size: ${({ theme: { typography } }) => typography.buttonText.fontSize};
        line-height: ${({ theme: { typography } }) => typography.buttonText.lineHeight};
        top: 16%;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        font-size: ${({ theme: { typography } }) => typography.title.large.fontSize};
        line-height: ${({ theme: { typography } }) => typography.title.large.lineHeight};
    }
`;

const StyledAvatarButton = styled.button`
    height: auto;
    width: auto;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 2px;

    svg {
        width: 93px;
        height: 93px;
    }

    @media (max-width: 480px) {
        svg {
            width: 55px;
        }
    }

    @media (min-width: 481px) and (max-width: 768px) {
        svg {
            width: 75px;
        }
    }
`;

function HeaderNavBar(props) {
    const {progressIndicator, lessonProgressStatus} = props;
    const showModal = useStore().showModal;
    const waterDropCount = useStore(s => s.dropletCount);
    const badgesEarned = useStore(s => s.badgesEarned);

    const handleAvatarButtonClick = useCallback(() => {
        showModal({tag: "UserConfigModal", modal: <UserConfigModal tag={"UserConfigModal"}/>})
    }, []);
    const handleBadgeCounterButtonClick = useCallback(() => {
        showModal({tag: "BadgeModalPopup", modal: <BadgeModalPopup tag={"BadgeModalPopup"}/>})
    }, []);
    const currentAvatarSvg = useStore((state) => {
        return state.userData?.avatarSvg
    });

    const DisplayAvatarSVG = getAvatar(currentAvatarSvg)


   return (
    <StyledHeaderNavBarContainer>
        <StyledEGLogoContainer>
            <a href="/">
                <EGLogoWhite/>
            </a>
        </StyledEGLogoContainer>
        {progressIndicator ? (
            <StyledLessonProgressIndicatorContainer>
                <LessonProgressIndicator {...lessonProgressStatus} />
            </StyledLessonProgressIndicatorContainer>
        ):null}
        <StyledEGControlsContainer>
            <StyledBadgeCounterButton onClick={handleBadgeCounterButtonClick}><BadgeSVG/><StyledBadgeCounter>{badgesEarned.length > 0 ? badgesEarned.length : 0}</StyledBadgeCounter></StyledBadgeCounterButton>
            <StyledWaterCounterButton><WaterBottleSVG/><StyledWaterCounter>{waterDropCount}</StyledWaterCounter></StyledWaterCounterButton>
            <StyledAvatarButton onClick={handleAvatarButtonClick}><DisplayAvatarSVG/></StyledAvatarButton>
        </StyledEGControlsContainer>
    </StyledHeaderNavBarContainer>
  );
}

export default HeaderNavBar;